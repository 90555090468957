import React, { useState } from "react";
import {
	Button,
	Row,
	FormGroup,
	Label,
	Col,
	Modal,
	Form,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
} from "reactstrap";
import classNames from "classnames";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ReactComponent as LockIcon } from "assets/img/Login/password.svg";
import { ReactComponent as UserIcon } from "assets/img/Login/user.svg";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useMutation } from "@tanstack/react-query";
import { clearMessage } from "actions/message";
import {
	GTMCheckoutUserForgetPasswordButton,
	GTMCheckoutUserForgetPasswordSuccess,
	GTMCheckoutUserLoginSuccess,
	logGTMUser,
} from "utilities/googleTagManager";
import { login } from "actions/auth";
import { useCheckout } from "hooks/selectors/useCheckout";
import { toast } from "react-toastify";

import EmbeddedResetPasswordModal from "components/EmbeddedResetPasswordModal";
import HeroImage from "assets/img/Video/upskilling100/gifts-square.png";
import styles from "./LoginModal.module.scss";

const loginSchema = yup
	.object({
		email: yup.string().email().required(),
		password: yup.string().required(),
	})
	.required();

export function LoginModal(props) {
	const dispatch = useDispatch();
	const [passwordType, setPasswordType] = useState("password");
	const lg = useMediaQuery("(min-width: 992px)");
	const { message } = useSelector((state) => state.message);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(loginSchema),
	});
	const [showForgetPassword, setShowForgetPassword] = useState(false);
	const { checkoutAnalyticsData } = useCheckout();

	// const handleLogin = () => {};
	const handleLogin = useMutation(
		(data) => {
			dispatch(clearMessage());
			return dispatch(login(data.email, data.password)).then((data) => {
				return data;
			});
		},
		{
			onSuccess: (data) => {
				props.toggle();
				props.onSuccess(data);
				logGTMUser(
					data.user.learnermaster_id ? data.user.learnermaster_id.toString() : "",
					data.user.learnermaster_name ? data.user.learnermaster_name.toString() : "",
					data.user.learnermaster_dob ? data.user.learnermaster_dob : "",
					data.user.learnermaster_nationality ? data.user.learnermaster_nationality : "",
					data.user.learnermaster_gender ? data.user.learnermaster_gender : "",
				);
				GTMCheckoutUserLoginSuccess(checkoutAnalyticsData, data);
			},
		},
	);

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text");
			return;
		}
		setPasswordType("password");
	};

	return (
		<>
			<Modal size='xl' className='modal-dialog-centered' {...props}>
				<EmbeddedResetPasswordModal
					isOpen={showForgetPassword}
					onSuccess={(data) => {
						setTimeout(() => {
							toast.success("Password reset successfully 🎉");
						}, 500);
						GTMCheckoutUserForgetPasswordSuccess(checkoutAnalyticsData);
						setShowForgetPassword(false);
					}}
					toggle={() => {
						setShowForgetPassword(!showForgetPassword);
					}}
				/>

				<div
					style={{ position: "absolute", right: 10, top: 10, zIndex: 10000 }}
					onClick={props.toggle}>
					<i className='fa fa-times' aria-hidden='true'></i>
				</div>

				<Row className='mr-0 ml-0'>
					{lg && (
						<Col xs={12} lg={6} className='pl-0 pr-0'>
							<img src={HeroImage} className={styles.login_modal_img} />
						</Col>
					)}

					<Col xs={12} lg={6} className={classNames(styles.login_modal_col)}>
						<div>
							<h2 className={styles.login_modal_header}>
								<span className={styles["hightlight-text"]}>Sign in </span> or <span className={styles["hightlight-text"]}>Create an account</span>  for us to contact you if you win the prize. 
								You can choose to fill in your details or register using your Singpass to
								vote for your favourite learner.
							</h2>
							<Form id='login_form' onSubmit={handleSubmit(handleLogin.mutateAsync)}>
								<FormGroup>
									<Label>Email</Label>
									<InputGroup className={"input-group-merge " + (errors.email ? "is-invalid" : "")}>
										<InputGroupAddon addonType='prepend'>
											<InputGroupText
												className={"form-control " + (errors.email ? "is-invalid" : "")}>
												<UserIcon />
											</InputGroupText>
										</InputGroupAddon>
										<input
											placeholder='Email'
											type='email'
											className={"form-control " + (errors.email ? "is-invalid" : "")}
											{...register("email")}
										/>
									</InputGroup>
									<div className='invalid-feedback'>{errors.email?.message}</div>
								</FormGroup>

								<FormGroup>
									<Label>Password</Label>
									<InputGroup
										className={
											"input-group-merge " + (errors.password ? "needs-validation is-invalid" : "")
										}>
										<InputGroupAddon addonType='prepend'>
											<InputGroupText
												className={"form-control " + (errors.password ? "is-invalid" : "")}>
												{/* <i className='ni ni-lock-circle-open' /> */}
												<LockIcon />
											</InputGroupText>
										</InputGroupAddon>
										<input
											placeholder='Password'
											type={passwordType}
											className={"form-control " + (errors.password ? "is-invalid" : "")}
											{...register("password")}
										/>
										<InputGroupAddon addonType='append'>
											<InputGroupText onClick={togglePassword}>
												{passwordType === "password" ? (
													<i className='far fa-eye-slash'></i>
												) : (
													<i className='far fa-eye'></i>
												)}
											</InputGroupText>
										</InputGroupAddon>
									</InputGroup>
									<div className='invalid-feedback'>{errors.password?.message}</div>
								</FormGroup>

								<div className='custom-control custom-control-alternative custom-checkbox text-center d-none'>
									<input className='custom-control-input' id=' customCheckLogin' type='checkbox' />
									<label className='custom-control-label d-none' htmlFor='customCheckLogin'>
										<span className='text-muted'>Remember me</span>
									</label>
								</div>
								<div className='mb-4 text-center'>
									<a
										href='/login/reset-password'
										target='_blank'
										className='ml-2'
										onClick={(e) => {
											e.preventDefault();
											setShowForgetPassword(true);
											GTMCheckoutUserForgetPasswordButton(checkoutAnalyticsData);
										}}>
										Forgot Password?
									</a>
								</div>

								<div className='text-center'>
									<Button
										className={classNames("btn-gradient", styles.submit_button)}
										type='submit'
										disabled={handleLogin.isLoading}>
										{handleLogin.isLoading && (
											<span className='spinner-border spinner-border-sm mr-2'></span>
										)}
										<span>Sign in</span>
									</Button>
								</div>
								<div className='mt-4 text-center'>
									Don't have an account ?
									<a 
										href='/register?upskilling100=1' 
										target='_blank' 
										className='ml-2' 
										onClick={(event) => { 
											event.preventDefault();
											localStorage.setItem("upskilling100_register_return_url", window.location.href);
											window.location.href = event.target.href;	
										}}>
										Click Here
									</a>
								</div>
								{message && (
									<div className='form-group pt-3'>
										<div className='alert alert-danger' role='alert'>
											{message}
										</div>
									</div>
								)}
							</Form>
						</div>
					</Col>
				</Row>
			</Modal>
		</>
	);
}
