import React, { useEffect, useState } from "react";
import {
	Outlet,
	useLocation,
	useNavigate,
	Link,
	useSearchParams,
	createSearchParams,
	Navigate
} from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import styles from "./checkoutpage.module.scss";
import { Stepper, Step, StepLabel, StepConnector } from "@material-ui/core";
import classNames from "classnames";
import { ReactComponent as HelpIcon } from "assets/img/Checkout/icon_help.svg";
import { ReactComponent as EnquireIcon } from "assets/img/Checkout/icon_enquire.svg";
import { ReactComponent as EnveloIcon } from "assets/img/Checkout/icon_envelo.svg";
import { useCheckout } from "hooks/selectors/useCheckout";
import { useDispatch } from "react-redux";
import { fetchCheckoutData, fetchCourseDetailsForAnalytics, saveCheckoutAnalyticsCCC, saveCheckoutAnalyticsData, setCheckout } from "reducers/courseRegisterSlice";
import { getUser, resetUser } from "actions/auth";
import { useAuth } from "hooks/selectors/useAuth";
import ContactUs2 from "../learner/course/ContactUs2";
import generalApi from "services/general-api";
import { CONTACT_NO_CHECKOUT } from "utilities/constant";
import { GTMCheckoutEnquiryButton, GTMCheckoutViewMoreCoursesButton, removeLogGTMUser } from "utilities/googleTagManager";
import axios from "axios";
import { getErrorMessage } from "utilities/error";
import { toast } from "react-toastify";
// import { fetchCheckoutData, setCheckoutResponse } from "reducers/checkout";

const steps = [
	{
		label: "Account Registration",
		route: ["/checkout/account"],
	},
	{
		label: "Course Sign Up & Payment",
		route: ["/checkout/course", "/checkout/omise/paynow", "/checkout/omise/loading"],
	},
	{
		label: "Enrollment Confirmation",
		route: ["/checkout/success"],
	},
];

export const getUrlParams = (params, cachedCheckoutAnalyticsData, cachedCourserunId) => {
	let searchParam = {};
	params.map(([key, value]) => {
		if (key == "courserun_id") {
			searchParam[key] = value || cachedCourserunId;
		} else if (key == "course_id") {
			searchParam[key] = value || cachedCheckoutAnalyticsData.course_id;
		} else {
			searchParam[key] = value;
		}
	});
	
	if (cachedCheckoutAnalyticsData.referral !== null) {
		searchParam.referral = params["referral"] || cachedCheckoutAnalyticsData.referral;
	} else {
		delete searchParam.referral;
	}

	if (cachedCheckoutAnalyticsData.invite !== null) {
		delete searchParam.invite;
		// searchParam.invite = params["invite"] || cachedCheckoutAnalyticsData.invite;
	} else {
		delete searchParam.invite;
	}
	if (cachedCheckoutAnalyticsData.campaign_type !== null) {
		searchParam.campaign_type = params["campaign_type"] || cachedCheckoutAnalyticsData.campaign_type;
	} else {
		delete searchParam.campaign_type;
	}
	if (cachedCheckoutAnalyticsData.utm_campaign !== null) {
		searchParam.utm_campaign = params["utm_campaign"] || cachedCheckoutAnalyticsData.utm_campaign;
	} else {
		delete searchParam.utm_campaign;
	}
	if (cachedCheckoutAnalyticsData.utm_content !== null) {
		searchParam.utm_content = params["utm_content"] || cachedCheckoutAnalyticsData.utm_content;
	} else {
		delete searchParam.utm_content;
	}
	if (cachedCheckoutAnalyticsData.utm_medium !== null) {
		searchParam.utm_medium = params["utm_medium"] || cachedCheckoutAnalyticsData.utm_medium;
	} else {
		delete searchParam.utm_medium;
	}
	if (cachedCheckoutAnalyticsData.utm_term !== null) {
		searchParam.utm_term = params["utm_term"] || cachedCheckoutAnalyticsData.utm_term;
	} else {
		delete searchParam.utm_term;
	}
	if (cachedCheckoutAnalyticsData.utm_source !== null) {
		searchParam.utm_source = params["utm_source"] || cachedCheckoutAnalyticsData.utm_source;
	} else {
		delete searchParam.utm_source;
	}
	if (cachedCheckoutAnalyticsData.CCC !== null) {
		searchParam.ccc = params["ccc"] || cachedCheckoutAnalyticsData.CCC;
	} else {
		delete searchParam.ccc;
	}
	return searchParam;
};
  

export function CheckoutPage() {
	const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
	const [isSingPassUnderMaintenance, setIsSingPassUnderMaintenance] = useState(false);
	const [isSfcUnderMaintenance, setIsSfcUnderMaintenance] = useState(false);
	const [currentStep, setCurrentStep] = React.useState(0);
	const [openModal, setOpenModal] = React.useState(0);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const {
		registrationform_id: cachedRegistrationformId,
		courserun_id: cachedCourserunId,
		isLoading,
		courserun,
		hasLoaded,
		checkoutAnalyticsData: cachedCheckoutAnalyticsData,
	} = useCheckout();
	const [searchParams] = useSearchParams();
	const params = Array.from(searchParams.entries());
	const paramCourseId = searchParams.get("course_id");
	const paramCourserunId = searchParams.get("courserun_id");
	const paramCampaignType = searchParams.get("campaign_type");
	const paramReferral = searchParams.get("referral");
	const paramInvite = searchParams.get("invite");
	const paramUtmCampaign = searchParams.get("utm_campaign");
	const paramUtmContent = searchParams.get("utm_content");
	const paramUtmMedium = searchParams.get("utm_medium");
	const paramUtmSource = searchParams.get("utm_source");
	const paramUtmTerm = searchParams.get("utm_term");
	const paramUrl = searchParams.get("website_url");
	const paramCCC = searchParams.get("ccc");

	const [isReady, setIsReady] = useState(false);
	const [invalidCheckout, setInvalidCheckout] = useState(false);
	const { checkoutAnalyticsData } = useCheckout();
	const { userData } = useAuth();
	
	const NavigateFunctionComponent = () => {
		/** Intercept any unauthorized request.
		 * dispatch logout action accordingly **/
		const dispatch = useDispatch();
		let navigate = useNavigate();

		const UNAUTHORIZED = 401;
		axios.interceptors.response.use(
			(response) => response,
			(error) => {
				const { status } = error.response;
				let error_message = getErrorMessage(error);
				if (status === UNAUTHORIZED && error_message == "Unauthenticated.") {
					removeLogGTMUser();
					dispatch(resetUser());
					localStorage.removeItem("user");
				}
				throw error;
			},
		);

		return <></>;
	};

	const handleOpenModal = () => {
		setOpenModal(true);
		// GTM Analytics
		GTMCheckoutEnquiryButton(
			checkoutAnalyticsData,
			userData,
		);
	};

	const handleSuccess = () => {
		setOpenModal(false);
	};

	const invalidCheckoutInputs = !paramCourserunId && !cachedRegistrationformId && !cachedCourserunId;

	// check maintenance
	useEffect(() => {
		// Fetch maintenance mode status when the component mounts
		generalApi.checkMaintenanceMode().then((status) => {
		  	console.log("Maintenance mode status: ", status);
		  	setIsUnderMaintenance(status.site_maintenance);
		  	setIsSingPassUnderMaintenance(status.singpass_maintenance);
		  	setIsSfcUnderMaintenance(status.sfc_maintenance);
		});

		if (paramInvite !== null) {
			toast.info(
				"We have detected the use of an invite code during checkout. Currently, invite codes are not supported. Therefore, we will proceed by removing the invite code from the checkout process.",
				{
					autoClose: 8000,
				}
			);
		}
		
	  }, []);
	
	/**
	 * Check whcih step in stepper is active
	 * If no step is active, redirect to first step
	 */
	useEffect(() => {
		let matchStepIndex = steps.findIndex((x) => {
			return x.route.some((r) => r.match(location.pathname));
		});
		if (matchStepIndex >= 0) {
			setCurrentStep(matchStepIndex);
		} else {
			setCurrentStep(0);

			navigate(
				{
					pathname: "/checkout/account",
					search: location.search,
				},
				{ replace: true },
			);
		}
	}, [location]);

	useEffect(() => {
		if (userData) {
			dispatch(getUser());
		}
	}, []);

	// Fetch and refetch all the data used for this page
	// based on the following key
	useEffect(() => {
		if (location.pathname === "/checkout" || location.pathname === "/checkout/") {
			return navigate("/");
		}
		if (invalidCheckoutInputs) {
			console.warn("Invalid checkout inputs");
			return;
		}

		// Check if there is a regform cache in redux
		// if so we try to fetch the checkout data using the cached regform id

		// But before that we need to check if the cached regform id  + cache courserun match
		const hasValidRegformCache =
			(cachedRegistrationformId && !paramCourserunId) ||
			(cachedRegistrationformId &&
				paramCourserunId &&
				paramCourserunId.toString() === (cachedCourserunId || "")?.toString());

		if (hasValidRegformCache) {

			dispatch(saveCheckoutAnalyticsCCC(paramCCC || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.CCC || null));
			
			dispatch(
				fetchCheckoutData({
					registrationform_id: cachedRegistrationformId,
					courserun_id: paramCourserunId,
				}),
			).then((thunkResult) => {
				const reponseData = thunkResult.payload.data;
				if (!reponseData.registrationform) {

					return navigate(
						{
							pathname: "/checkout/account",
							search: createSearchParams(getUrlParams(params, cachedCheckoutAnalyticsData, cachedCourserunId)).toString(),
						},
						{ replace: true },
					);
				}

				const hasPaid =
					reponseData.reg_form_payments &&
					reponseData.reg_form_payments.length > 0 &&
					reponseData.reg_form_payments.filter((x) => x.status === "SUCCESSFUL").length > 0;

				// Navigate to Success page if the payment is complete
				if (hasPaid) {
					if (!location.pathname.includes("omise/paynow") && !location.pathname.includes("omise/loading")) {
						navigate(
							{
								pathname: "/checkout/success",
								search: location.search,
							},
							{ replace: true },
						);
					}
				}

				// prevent user manually going to success page
				if (location.pathname === "/checkout/success" && !hasPaid) {
					
					navigate(
						{
							pathname: "/checkout/account",
							search: createSearchParams(getUrlParams(params, cachedCheckoutAnalyticsData, cachedCourserunId)).toString(),
						},
						{ replace: true },
					);
				}

				setIsReady(true);
			});
		} else {
			if (!paramCourserunId && !cachedCourserunId) {
				setInvalidCheckout(true);
				return;
			}
			
			// Clear the previous checkout related state start with a fresh state
			dispatch(
				setCheckout({
					registrationform_id: null,
					courserun_id: paramCourserunId || cachedCourserunId,
				}),
			);
			// clear the previous checkout analytics data state start with a fresh state
			// get course data for analytics
			let checkoutData = {
				course_id: paramCourseId || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.course_id,
				course_title: null,
				courserun_id: paramCourserunId || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.courserun_id,
				
			};

			if (paramCourserunId && paramCourserunId !== cachedCourserunId) {
				// different courserun
				checkoutData.referral = paramReferral ||  null;
				// checkoutData.invite = paramInvite || null;
				checkoutData.invite = null;
				checkoutData.campaign_type = paramCampaignType || null;
				checkoutData.utm_campaign = paramUtmCampaign || null;
				checkoutData.utm_content = paramUtmContent || null;
				checkoutData.utm_medium = paramUtmMedium || null;
				checkoutData.utm_source = paramUtmSource || null;
				checkoutData.utm_term = paramUtmTerm || null;
				checkoutData.url = paramUrl || null;
				checkoutData.CCC = paramCCC || null;
			} else {
				// same courserun
				checkoutData.referral = paramReferral || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.referral ||  null;
				// checkoutData.invite = paramInvite || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.invite || null;
				checkoutData.invite = null;
				checkoutData.campaign_type = paramCampaignType || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.campaign_type || null;
				checkoutData.utm_campaign = paramUtmCampaign || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.utm_campaign || null;
				checkoutData.utm_content = paramUtmContent || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.utm_content || null;
				checkoutData.utm_medium = paramUtmMedium || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.utm_medium || null;
				checkoutData.utm_source = paramUtmSource || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.utm_source || null;
				checkoutData.utm_term = paramUtmTerm || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.utm_term || null;
				checkoutData.url = paramUrl || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.url || null;
				checkoutData.CCC = paramCCC || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.CCC || null;
			}
			if (paramReferral !== null && paramReferral === "") {
				checkoutData.referral = null;
			}
			if (paramInvite !== null && paramInvite === "") {
				checkoutData.invite = null;
			}
			if (paramCampaignType !== null && paramCampaignType === "") {
				checkoutData.campaign_type = null;
			}
			if (paramUtmCampaign !== null && paramUtmCampaign === "") {
				checkoutData.utm_campaign = null;
			}
			if (paramUtmContent !== null && paramUtmContent === "") {
				checkoutData.utm_content = null;
			}
			if (paramUtmMedium !== null && paramUtmMedium === "") {
				checkoutData.utm_medium = null;
			}
			if (paramUtmSource !== null && paramUtmSource === "") {
				checkoutData.utm_source = null;
			}
			if (paramUtmTerm !== null && paramUtmTerm === "") {
				checkoutData.utm_term = null;
			}
			if (paramUrl !== null && paramUrl === "") {
				checkoutData.url = null;
			}
			if (paramCCC !== null && paramCCC === "") {
				checkoutData.CCC = null;
			}
			checkoutData.invite = null;
			checkoutData.course_price_value = null;
			dispatch(saveCheckoutAnalyticsData(checkoutData));
			if (paramCourseId || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.course_id) {
				dispatch(fetchCourseDetailsForAnalytics({ course_id: paramCourseId || cachedCheckoutAnalyticsData && cachedCheckoutAnalyticsData.course_id }));
			}
			// since this is a checkout, we always redirect to account page if we are not already there
			navigate(
				{
					pathname: "/checkout/account",
					search: createSearchParams(getUrlParams(params, checkoutData, cachedCourserunId)).toString(),
				},
				{ replace: true },
			);

			setIsReady(true);
		}
	}, [cachedRegistrationformId, paramCourserunId, cachedCourserunId]);

	const isDataLoadSuccessful = cachedRegistrationformId && hasLoaded;
	if (isUnderMaintenance) {
		return <Navigate to='/maintenance' />;
	}
	return (
		<div className={styles.root}>
			<Container fluid className={`pt-0 ml-0 mr-0 ${styles.stepper_container}`}>
				<Stepper alternativeLabel activeStep={currentStep} connector={<ColorlibConnector />}>
					{steps.map((step) => {
						return (
							<Step key={step.label} onClick={() => {}}>
								<StepLabel StepIconComponent={ColorlibStepIcon}>{step.label}</StepLabel>
							</Step>
						);
					})}
				</Stepper>
			</Container>
			<Container fluid className={styles.main}>
				<Row>
					{location.pathname !== "/checkout/success" ? (
						<>
							<Col lg={8}>
								{cachedRegistrationformId && !hasLoaded ? (
									<div>Loading...</div>
								) : isReady ? (
									<Outlet
										context={{
											openEnquiryModal: handleOpenModal,
											isSingPassUnderMaintenance: isSingPassUnderMaintenance,
											isSfcUnderMaintenance: isSfcUnderMaintenance,
										}}
									/>
								) : null}
							</Col>
							<Col lg={4}>
								<div className={classNames(styles.callme, styles.callme_blue, "shadow-sm")}>
									<h4>Need help with your registration?</h4>
									<Row className='ml-0 mr-0 my-4'>
										<Col
											xs={2}
											style={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
												paddingLeft: 0,
												paddingRight: 0,
											}}>
											<HelpIcon width={"50"} height={"auto"} />
										</Col>
										<Col>
											<p>
												Reach us at
												<a
													href={`tel:${CONTACT_NO_CHECKOUT}`}
													style={{
														color: "#4BA58C",
														fontWeight: "600",
														margin: "0 5px",
														whiteSpace: "nowrap",
													}}>
													{CONTACT_NO_CHECKOUT}
												</a>
												Our Student Services team will provide you with assistance.
											</p>
										</Col>
									</Row>

									<a href={`tel:${CONTACT_NO_CHECKOUT}`}>
										<Button outline className={classNames(styles.call_us_btn, "mt-4")}>
											Call Us Now
										</Button>
									</a>
								</div>
								<div
									id='enquiry-form'
									className={classNames(styles.enquiry, styles.callme_green, "shadow-sm mt-4")}>
									<h4>Enquiries regarding our courses?</h4>
									<Row className='ml-0 mr-0 my-4'>
										<Col
											xs={2}
											style={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
												paddingLeft: 0,
												paddingRight: 0,
											}}>
											<EnveloIcon width={"50"} height={"auto"} />
										</Col>
										<Col>
											<p>
												Drop us a message via the enquiry form. Our team of Education Consultants
												will reach out to you within one working day.
											</p>
										</Col>
									</Row>
									<Button
										outline
										className={classNames(styles.call_us_btn, "mt-4")}
										onClick={handleOpenModal}>
										{"Enquire Now"}
									</Button>
									<ContactUs2 show={openModal} onSuccess={handleSuccess}></ContactUs2>
								</div>
								<div className={classNames(styles.enquiry, styles.callme_yellow, "shadow-sm mt-4")}>
									<h4>Still browsing around?</h4>
									<Row className='ml-0 mr-0 my-4'>
										<Col
											xs={2}
											style={{
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
												paddingLeft: 0,
												paddingRight: 0,
											}}>
											<EnquireIcon width={"50"} height={"auto"} />
										</Col>
										<Col>
											<p>Visit our course overview page to view all available courses.</p>
										</Col>
									</Row>
									<Button
										href='https://www.fca.edu.sg/courses/'
										target='_blank'
										rel='noreferrer'
										outline
										className={classNames(styles.call_us_btn, "mt-4")}
										onClick={() => GTMCheckoutViewMoreCoursesButton(checkoutAnalyticsData, userData)}
									>
										{"View more courses"}
									</Button>
								</div>
							</Col>
						</>
					) : isDataLoadSuccessful ? (
						<Outlet />
					) : (
						<div>Loading...</div>
					)}
				</Row>
			</Container>

			<Modal isOpen={invalidCheckoutInputs || invalidCheckout} centered>
				<ModalHeader>Something is wrong</ModalHeader>
				<ModalBody>Invalid Checkout</ModalBody>
				<ModalFooter>
					<a href="https://www.fca.edu.sg/">
						<Button color='primary'>Back to Home</Button>
					</a>
				</ModalFooter>
			</Modal>
			<NavigateFunctionComponent />
		</div>
	);
}

const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: "#EEEEEE",
		zIndex: 1,
		color: "gray",
		border: "3px solid #ddd",
		width: 40,
		height: 40,
		display: "flex",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: "bold",
	},
	active: {
		// backgroundImage: "linear-gradient( 90deg, #4BA58C 0%,  #3B85C4 100%)",
		backgroundColor: "white",
		boxShadow: "0 0 2px 2px rgba(0,0,0,.25)",
		color: "#4BA58C",
		border: "3px solid #54C49C",
	},
	completed: {
		backgroundImage: "linear-gradient( 90deg, #4BA58C 0%,  #3B85C4 100%)",
		border: "none",
		color: "white",
		boxShadow: "0 0 4px 4px rgba(0,0,0,.25)",
	},
});

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 18,
	},
	active: {
		"& $line": {
			backgroundImage: "linear-gradient( 95deg, #4BA58C 0%, #3B85C4 100%)",
		},
	},
	completed: {
		"& $line": {
			backgroundImage: "linear-gradient( 95deg, #4BA58C 0%, #3B85C4 100%)",
		},
	},
	line: {
		height: 5,
		border: 0,
		backgroundColor: "#eaeaf0",
		borderRadius: 1,
	},
})(StepConnector);

function ColorlibStepIcon(props) {
	const classes = useColorlibStepIconStyles();
	const { active, completed } = props;

	return (
		<div
			className={`${classNames(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
				[styles.glowing_circle]: completed,
			})}`}>
			{props.icon}
		</div>
	);
}
