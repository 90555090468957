import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	OTP_SUCCESS,
	UPDATE_PROFILE_SUCCESS,
	UPDATE_PROFILE_FAIL,
	GET_USER_SUCCESS,
	GET_USER_FAIL,
	LOGOUT,
	HYPERADMIN_OTP_SUCCESS,
	HYPERADMIN_OTP_FAIL,
	HYPERADMIN_LOGIN_SUCCESS,
	HYPERADMIN_LOGIN_FAIL,
	RESET_EMAIL_SUCCESS,
	RESET_EMAIL_FAIL,
	RESET_USER,
	GET_PROFILE_BUNDLE_SUCCESS,
	GET_PROFILE_BUNDLE_FAIL,
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));
const isHyperAdminLogin = JSON.parse(localStorage.getItem("isHyperAdminLogin"));

const initialState = user ? {
	isLoggedIn: true,
	user,
	isHyperAdminOTP: false,
	isHyperAdminLogin: isHyperAdminLogin ? true : false,
	profileBundle: null,
	is_completed_one_course: false,
	almost_completed_bundle_course_left: 0,
	almost_completed_bundle_title: "",
	total_badge: 0,
	total_completed_course: 0,
	total_upcoming_course: 0,
} : {
	isLoggedIn: false,
	user: null,
	isHyperAdminOTP: false,
	isHyperAdminLogin: isHyperAdminLogin ? true : false
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
	case REGISTER_SUCCESS:
		return {
			...state,
			isLoggedIn: true,
			user: payload.user,
		};
	case REGISTER_FAIL:
		return {
			...state,
			isLoggedIn: false,
		};
	case LOGIN_SUCCESS:
		return {
			...state,
			isLoggedIn: true,
			isHyperAdminLogin: false,
			user: payload.user,
		};
	case LOGIN_FAIL:
		return {
			...state,
			isLoggedIn: false,
			isHyperAdminLogin: false,
			user: null,
		};
	case GET_USER_SUCCESS:
		return {
			...state,
			isLoggedIn: true,
			user: payload.user,
		};
	case GET_USER_FAIL:
		return {
			...state,
			isLoggedIn: false,
			user: null,
		};
	case GET_PROFILE_BUNDLE_SUCCESS:
		return {
			...state,
			is_completed_one_course: payload.is_completed_one_course,
			almost_completed_bundle_course_left: payload.almost_completed_bundle_course_left,
			almost_completed_bundle_title: payload.almost_completed_bundle_title,
			total_badge: payload.total_badge,
			total_completed_course: payload.total_completed_course,
			total_upcoming_course: payload.total_upcoming_course,
			profileBundle: payload.profileBundle,
		};
	case GET_PROFILE_BUNDLE_FAIL:
		return {
			...state,
			is_completed_one_course: false,
			almost_completed_bundle_course_left: 0,
			almost_completed_bundle_title: "",
			total_badge: 0,
			total_completed_course: 0,
			total_upcoming_course: 0,
			profileBundle: null,
		};
	case OTP_SUCCESS: {
		const currentUser = {...state.user};
		currentUser.user = {
			...currentUser.user,
			email_verified_at: 1,
		};
		return {
			...state,
			user: currentUser,
		};
	}
	case UPDATE_PROFILE_SUCCESS:
		return {
			...state,
			user: payload.user,
		};
	case UPDATE_PROFILE_FAIL:
		return {
			...state,
		};
	case LOGOUT:
		return {
			...state,
			isLoggedIn: false,
			isHyperAdminLogin: false,
			isHyperAdminOTP: false,
			user: null,
		};
	case RESET_USER:
		return {
			...state,
			isLoggedIn: false,
			isHyperAdminLogin: false,
			isHyperAdminOTP: false,
			user: null,
		};
	case RESET_EMAIL_SUCCESS:
		return {
			...state,
			user: payload.user,
		};
	case RESET_EMAIL_FAIL:
		return {
			...state,
		};
	case HYPERADMIN_OTP_SUCCESS:
		return {
			...state,
			isHyperAdminOTP: true,
		};
	case HYPERADMIN_OTP_FAIL:
		return {
			...state,
			isHyperAdminOTP: false,
		};
	case HYPERADMIN_LOGIN_SUCCESS:
		return {
			...state,
			isLoggedIn: true,
			isHyperAdminLogin: true,
			user: payload.user,
		};
	case HYPERADMIN_LOGIN_FAIL:
		return {
			...state,
			isLoggedIn: false,
			isHyperAdminLogin: false,
			user: null,
		};
	default:
		return state;
	}
}
