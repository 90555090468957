import React, { createRef, useContext, useEffect, useMemo, useState } from "react";
import styles from "./checkoutregsiterpage.module.scss";
import {
	Button,
	Row,
	FormGroup,
	Label,
	Col,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	FormFeedback,
	Spinner,
	Alert,
	UncontrolledTooltip,
	UncontrolledPopover,
	PopoverHeader,
	PopoverBody,
} from "reactstrap";
import {
	CONTACT_NO_CHECKOUT,
	COUNTRIES_OPTIONS,
	GENDER_OPTIONS,
	NATIONALITY_OPTIONS,
} from "utilities/constant";
import { QUALIFICATOIN_LEVEL_OPTION } from "utilities/constant";
import { LOAN_OPTIONS } from "utilities/constant";
import classNames from "classnames";
import { useAuth } from "hooks/selectors/useAuth";
import { Divider } from "@material-ui/core";
import { yupHelper } from "utilities/yupHelpers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ReactComponent as LockIcon } from "assets/img/Login/password.svg";
import { ReactComponent as UserIcon } from "assets/img/Login/user.svg";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useMutation } from "@tanstack/react-query";
import { clearMessage } from "actions/message";
import { logGAUser } from "utilities/googleAnalytics";
import {
	GTMCheckoutSingpassRetrieveInfoButton,
	GTMCheckoutSingpassRetrieveInfoFailed,
	GTMCheckoutSingpassRetrieveInfoSuccess,
	GTMCheckoutStep1Account,
	GTMCheckoutStep1AccountExit,
	GTMCheckoutStep1GoEndorse,
	GTMCheckoutSubmitRegform,
	GTMCheckoutSubmitRegformSuccess,
	GTMCheckoutUserForgetPasswordButton,
	GTMCheckoutUserForgetPasswordSuccess,
	GTMCheckoutUserLoginButton,
	GTMCheckoutUserLoginOtpVerify,
	GTMCheckoutUserLoginOtpVerifySuccess,
	GTMCheckoutUserLoginSuccess,
	GTMCheckoutUserRegisterButton,
	GTMCheckoutUserRegisterSuccess,
	logGTMUser,
} from "utilities/googleTagManager";
import { login, logout } from "actions/auth";
import { logUser } from "utilities/firebase";
import {
	createSearchParams,
	Link,
	useNavigate,
	useOutletContext,
	useSearchParams,
} from "react-router-dom";
import generalApi from "services/general-api";
import { useCheckout } from "hooks/selectors/useCheckout";
import {
	cacheRegform,
	clearCheckout,
	fetchCheckoutData,
	setRegistrationId,
	saveCheckoutAnalyticsData,
} from "reducers/courseRegisterSlice";
import { REGISTER_SUCCESS } from "actions/types";
import OTPModalSignup from "components/Modals/OtpModalSignup";
import { getErrorMessage } from "utilities/error";
import { toast } from "react-toastify";
import { useSessionStorageState } from "ahooks";

import CheckoutLoginImage from "assets/img/Checkout/checkout_login.jpg";
import CongraCreateAccountImage from "assets/img/Checkout/congra-create-account.png";
import SingpassButton from "assets/img/Checkout/MyinfoButton/Neutral@4x.png";
import PropTypes from "prop-types";

import { isAxiosError } from "axios";
import { v4 as uuidv4 } from "uuid";
import EmbeddedResetPasswordModal from "components/EmbeddedResetPasswordModal";
import { CheckoutLogoutButton } from "views/pages/checkout/components/LogoutButton";
import CreateAccountFloating from "views/pages/checkout/components/CreateAccountFloating";
import { getUrlParams } from "views/pages/checkout/CheckoutPage";

const API_URL = process.env.REACT_APP_API_URL;

// Workaround:
// cache the created account state , or when the sub page refreshing due to reloading
// the successfully create modal will still show
const createdAccountRef = createRef(false);

export function CheckoutRegisterPage() {
	// const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
	// const [isSingPassUnderMaintenance, setIsSingPassUnderMaintenance] = useState(false);
	// const [isSfcUnderMaintenance, setIsSfcUnderMaintenance] = useState(false);
	const dispatch = useDispatch();
	const { userData } = useAuth();
	const [isTryLogout, setIsTryLogout] = useState(false);

	const schema = useMemo(() => {
		const baseSchema = {
			name: yupHelper.fullname(),
			nric: yupHelper.nric(),
			dob: yup.string().required(),
			nationality: yup.string().required(),
			gender: yup.string().required().nonNullable(),
			email: yup.string().email().required(),
			mobile: yupHelper.mobile(),
			education: yup.string().required(),
			designation: yup.string().required(),
			laptopLoan: yup.string().required().default("0"),
			postal_code: yup.string().notRequired(),
			street: yup.string().notRequired(),
			unit: yup.string().notRequired(),
			block: yup.string().notRequired(),
			floor: yup.string().notRequired(),
			building: yup.string().notRequired(),
			country: yup.string().notRequired(),
			fill_with_singpass: yup.bool().required().default(false),
		};

		// if user is not logged in, add password fields validation
		if (!userData) {
			baseSchema.password = yupHelper.password();
			baseSchema.password_confirmation = yup
				.string()
				.required("Please retype your password.")
				.oneOf([yup.ref("password")], "Your passwords do not match.");
		}

		return yup.object(baseSchema);
	}, [userData]);

	const { courserun_id, registrationform_id, registerFormCache, learners, registerForm, course, courserun } =
		useCheckout();
	const [loginModal, setLoginModal] = useState(false);

	// TODO: STORE the state locally , otherwise when they refresh the page , the state will be gone
	const [errorData, setErrorData] = useState(null);
	const [editMode, setEditMode] = useState(true);
	const [isSingpass, setisSingpass] = useState(false);
	const [lockedField, setLockedField] = useState({});
	const [createAccountSuccessModal, setcreateAccountSuccessModal] = useState(
		createdAccountRef.current,
	);
	const [searchParams] = useSearchParams();

	const [showPassword, setShowPassword] = useState(false);
	const toggleSuccessCreateAccountModal = () =>
		setcreateAccountSuccessModal(!createAccountSuccessModal);
	const [otpmodal, setOTPModal] = useState(false);
	const navigate = useNavigate();
	const { openEnquiryModal = () => {}, isSingPassUnderMaintenance } = useOutletContext();
	const { checkoutAnalyticsData } = useCheckout();
	const [isFirstTime, setIsFirstTime] = useState(true);
	console.log(checkoutAnalyticsData);
	const toggleLoginModal = () => {
		setLoginModal(!loginModal);
	};

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const populateRegformByUser = (data) => {
		reset({
			name: data.user.learnermaster_name,
			nric: data.user.learnermaster_nric,
			dob: data.user.learnermaster_dob,
			nationality: data.user.learnermaster_nationality,
			email: data.user.learnermaster_email,
			mobile: data.user.learnermaster_mobile,
			education: data.user.learnermaster_qualificationlevel,
			gender: data.user.learnermaster_gender,
			designation: data.user.learnermaster_designation,
			street: data.user.learnermaster_address_street,
			building: data.user.learnermaster_address_building,
			block: data.user.learnermaster_address_block,
			floor: data.user.learnermaster_address_floor,
			unit: data.user.learnermaster_address_unit,
			postal_code: data.user.learnermaster_address_postalcode,
			country: data.user.learnermaster_address_country,
		});
	};

	const handleContinue = useMutation(
		(data) => {
			setErrorData(null);
			dispatch(cacheRegform({ ...data }));

			if (userData && userData.user) {
				if (userData.user.email_verified_at) {
					setOTPModal(false);
				} else {
					// open OTP modal here if user has not verified email
					GTMCheckoutUserLoginOtpVerify(checkoutAnalyticsData, userData);
					setOTPModal(true);
					// stop here
					return;
				}
			}

			/**
			 * if user is not logged in, create an account for them
			 * else submit the regform
			 */
			if (!userData) {
				// end here
				GTMCheckoutUserRegisterButton(checkoutAnalyticsData, userData);
				return generalApi
					.createAccountForOnlineRegistration({
						name: data.name,
						email: data.email,
						nric: data.nric,
						dob: data.dob,
						nationality: data.nationality,
						mobile: data.mobile,
						qualificationlevel: data.education,
						designation: data.designation,
						gender: data.gender,
						password: data.password,
						password_confirmation: data.password_confirmation,
						address_postal: data.postal_code,
						address_street: data.street,
						address_block: data.block,
						address_floor: data.floor,
						address_unit: data.unit,
						address_building: data.building,
						address_country: data.country,
						fill_with_singpass: data.fill_with_singpass,
					})
					.then((response) => {
						createdAccountRef.current = true;
						dispatch({
							type: REGISTER_SUCCESS,
							payload: { user: response.data },
						});
						GTMCheckoutUserRegisterSuccess(checkoutAnalyticsData, { user: response.data });
						GTMCheckoutUserLoginOtpVerify(checkoutAnalyticsData, { user: response.data });
						setOTPModal(true);
					})
					.catch((error) => {
						console.warn("Create Account Error", error);
						if (isAxiosError(error)) {
							const responseData = error.response.data;

							if (responseData.status === "Error") {
								if (responseData.error_code === "DUPLICATED_ID") {
									const errorMessage = Object.values(responseData.data).map((item) => {
										return <li key={item}>{item}</li>;
									});
									setErrorData({
										element: (
											<ErrorDisplay
												title='Create account Failed'
												description={<ul>{errorMessage}</ul>}>
												<p>
													<Button onClick={toggleLoginModal}>
														Press here to login here if you have an existing account
													</Button>
												</p>
											</ErrorDisplay>
										),
									});
								} else {
									setErrorData({
										element: (
											<ErrorDisplay
												title='Create account Failed'
												description={getErrorMessage(error)}></ErrorDisplay>
										),
									});
								}
							} else {
								throw error;
							}
						} else {
							throw error;
						}
					});
			} else {
				GTMCheckoutSubmitRegform(checkoutAnalyticsData, userData);
				return generalApi
					.submitOnlineRegistration({
						registrationform_id: registrationform_id,
						registrationform_courserun: courserun_id,
						registrationform_sponsoredbycompany_yes_no: "No",
						learner_nric: data.nric,
						learner_name: data.name,
						learner_dob: data.dob,
						learner_nationality: data.nationality,
						learner_email: data.email,
						learner_mobile: data.mobile,
						learner_qualificationlevel: data.education,
						learner_designation: data.designation,
						learner_laptoploan: data.laptopLoan,
						learner_learnermaster_id: userData.user.learnermaster_id,
						learner_gender: data.gender,
						learner_postal: data.postal_code,
						learner_street: data.street,
						learner_block: data.block,
						learner_floor: data.floor,
						learner_unit: data.unit,
						learner_building: data.building,
						learner_country: data.country,
						fill_with_singpass: data.fill_with_singpass,
						referral: checkoutAnalyticsData.referral,
						// invite: checkoutAnalyticsData.invite,
					})
					.then(async (responseData) => {
						GTMCheckoutSubmitRegformSuccess * (checkoutAnalyticsData, userData);
						const registrationform_id = responseData.data.registrationform_id;
						dispatch(setRegistrationId({ registrationform_id: registrationform_id }));
						return dispatch(fetchCheckoutData({ registrationform_id: registrationform_id })).then(
							() => {
								if (createdAccountRef.current) {
									setcreateAccountSuccessModal(true);
								} else {
									// toast.success("Proceed to payment", {
									// 	icon: "🎉",
									// });

									const newSearchParam = [];
									for(let entry of searchParams.entries()) {
										newSearchParam.push(entry);
									}
									if (newSearchParam.uuidv4) {
										delete newSearchParam.uuidv4;
									}

									generalApi
										.checkGrant({
											learner_nric: data.nric,
											learner_dob: data.dob,
											learner_nationality: data.nationality,
											course_ref: course.course_ref,
											courserun_api_id: courserun.courserun_apicourserunid,
											courserun_course_from: courserun.courserun_course_from,
											trigger_from: "FALCOMM"
										})
										.then(async (responseData) => {
											if (responseData.status === "Success") {
												toast.success("Proceed to payment", {
													icon: "🎉",
												});

												window.scrollTo({ top: 0 });
												GTMCheckoutStep1GoEndorse(checkoutAnalyticsData, userData);
												navigate({
													pathname: "/checkout/course",
													search: createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString(),
												});
											}
										})
										.catch((error) => {
											if (isAxiosError(error)) {
												console.warn("Check Grant Error", error);
												const responseData = error.response.data;

												if (responseData.status === "Error") {
													setErrorData({
														element: (
															<Modal isOpen centered>
																<ModalHeader>Registration Failed</ModalHeader>
																<ModalBody>
																	<div dangerouslySetInnerHTML={{ __html: responseData.error_msg }}></div>
																</ModalBody>
																<ModalFooter>
																	<Link to={"https://www.fca.edu.sg/courses/"} replace>
																		<Button color='primary'>Return to course</Button>
																	</Link>
																</ModalFooter>
															</Modal>
														),
													});
													return;
												} else {
													throw error;
												}
											}
										});

									// window.scrollTo({ top: 0 });
									// GTMCheckoutStep1GoEndorse(checkoutAnalyticsData, userData);
									// navigate({
									// 	pathname: "/checkout/course",
									// 	search: createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString(),
									// });
								}
							},
						);
					})
					.catch((error) => {
						if (isAxiosError(error)) {
							console.warn("Resubmit Registration Error", error);
							const responseData = error.response.data;

							if (responseData.status === "Error") {
								if (responseData.error_code === "HAS_REGISTERED_SAME_COURSE") {
									setErrorData({
										element: (
											<Modal isOpen centered>
												<ModalHeader>Registration Failed</ModalHeader>
												<ModalBody>{responseData.message}</ModalBody>
												<ModalFooter>
													<Link to={"https://www.fca.edu.sg/courses/"} replace>
														<Button color='primary'>Return to course</Button>
													</Link>
												</ModalFooter>
											</Modal>
										),
									});
								} else if (responseData.error_code === "HAS_REGISTERED_SAME_DATE") {
									setErrorData({
										element: (
											<Modal isOpen centered>
												<ModalHeader>Registration Failed</ModalHeader>
												<ModalBody>{responseData.message}</ModalBody>
												<ModalFooter>
													<Link
														to={
															(process.env.REACT_APP_ENV ==="production" 
																? "https://www.fca.edu.sg/"  
																: "https://academyfirstcom2807.firstcomdemo.com/" ) + responseData.data.fca_seo_url
														}
														replace>
														<Button color='primary'>Select another course date</Button>
													</Link>
												</ModalFooter>
											</Modal>
										),
									});
								} else if (responseData.error_code === "FULLY_BOOKED") {
									setErrorData({
										element: (
											<Modal isOpen centered>
												<ModalHeader>Fully booked</ModalHeader>
												<ModalBody>{responseData.message}</ModalBody>
												<ModalFooter>
													<Link to={"/course/course-info/" + responseData.data.course_id} replace>
														<Button color='primary'>Select another course date</Button>
													</Link>
												</ModalFooter>
											</Modal>
										),
									});
								} else if (responseData.error_code === "HAS_TBA_NOSHOW_COURSE") {
									setErrorData({
										element: (
											<Modal isOpen centered>
												<ModalHeader>Pending Courses</ModalHeader>
												<ModalBody>{responseData.message}</ModalBody>
												<ModalFooter>
													<a href='https://www.fca.edu.sg/contact-us/'>
														Contact Form
													</a>
												</ModalFooter>
											</Modal>
										),
									});
								} else {
									setErrorData({
										element: (
											<ErrorDisplay
												title='Registration Failed'
												description={
													<>
														<div>{responseData.message}</div>
														<div>{getErrorMessage(error)}</div>
													</>
												}></ErrorDisplay>
										),
									});
								}
								return;
							} else {
								throw error;
							}
						}
					});
			}
		},
		{
			onError: (error) => {
				console.warn("Checkout -> handleContinue -> Error", error);
				toast.error("Error : ", getErrorMessage(error));
			},
		},
	);

	const handleClickEdit = (e) => {
		e.preventDefault();
		setEditMode(true);
	};

	const handleProceedToPaymentPage = (e) => {
		e.preventDefault();
		const newSearchParam = [];
		for(let entry of searchParams.entries()) {
			newSearchParam.push(entry);
		}
		if (newSearchParam.uuidv4) {
			delete newSearchParam.uuidv4;
		}

		// console.log(learners.learner_nric);
		// console.log(learners.learner_dob);
		// console.log(learners.learner_nationality);
		// console.log(course.course_ref);
		// console.log(courserun.courserun_course_from);

		generalApi
			.checkGrant({
				learner_nric: learners.learner_nric,
				learner_dob: learners.learner_dob,
				learner_nationality: learners.learner_nationality,
				courserun_api_id: courserun.courserun_apicourserunid,
				course_ref: course.course_ref,
				courserun_course_from: courserun.courserun_course_from,
				trigger_from: "FALCOMM"
			})
			.then(async (responseData) => {
				if (responseData.status === "Success") {
					toast.success("Proceed to payment", {
						icon: "🎉",
					});

					window.scrollTo({ top: 0 });
					GTMCheckoutStep1GoEndorse(checkoutAnalyticsData, userData);
					navigate({
						pathname: "/checkout/course",
						search: createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString(),
					});
				}
			})
			.catch((error) => {
				if (isAxiosError(error)) {
					console.warn("Check Grant Error", error);
					const responseData = error.response.data;

					if (responseData.status === "Error") {
						setErrorData({
							element: (
								<Modal isOpen centered>
									<ModalHeader>Registration Failed</ModalHeader>
									<ModalBody>
										<div dangerouslySetInnerHTML={{ __html: responseData.error_msg }}></div>
									</ModalBody>
									<ModalFooter>
										<Link to={"https://www.fca.edu.sg/courses/"} replace>
											<Button color='primary'>Return to course</Button>
										</Link>
									</ModalFooter>
								</Modal>
							),
						});
						return;
					} else {
						throw error;
					}
				}
			});
	};

	const handleOTPSuccess = () => {
		toast.success("OTP Verified 🎉. Continue your checkout.");
		GTMCheckoutUserLoginOtpVerifySuccess(checkoutAnalyticsData, userData);
		if (registerFormCache) {
			handleContinue.mutate(registerFormCache);
		}
	};

	const handleLoginSuccess = (data) => {
		toast.success("You're login successfully 🎉. Continue your checkout.");
		populateRegformByUser(data);

		const newSearchParam = [];
		for(let entry of searchParams.entries()) {
			newSearchParam.push(entry);
		}
		if (newSearchParam.uuidv4) {
			delete newSearchParam.uuidv4;
		}
		// replace current url to clear singpass query param if they is any
		window.scrollTo({ top: 0 });
		window.history.replaceState(null, null, createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString());
		navigate(
			{
				pathname: "/checkout/account",
				search: createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString(),
			},
			{
				replace: true,
			},
		);
	};

	const handleSingpassLogin = (data) => {
		GTMCheckoutSingpassRetrieveInfoButton(checkoutAnalyticsData, userData);
		window.location.href = API_URL + "/api/generateSingpassUrl?uuidv4=" + uuidv4();
	};

	const fetchSingpassData = () => {
		const searchParams = new URLSearchParams(window.location.search);
		
		const newSearchParam = [];
		for(let entry of searchParams.entries()) {
			newSearchParam.push(entry);
		}

		var uuidv4 = searchParams.get("uuidv4");
		if (searchParams.get("f") == 1) {
			generalApi
				.fetchSingpassData({
					uuidv4: uuidv4,
				})
				.then((singpassObj) => {
					const singpassUser = singpassObj.data[0];
					if (singpassUser) {
						console.warn("Singpass response is empty.");
					}

					const singpassAddress = JSON.parse(singpassUser.address);
					const newFormValue = {
						name: singpassUser.name,
						nric: singpassUser.nric,
						dob: singpassUser.dob,
						nationality: singpassUser.nationality,
						gender: singpassUser.gender,
						email: singpassUser.email,
						mobile: singpassUser.mobile,
						education: "",
						designation: singpassUser.designation,
						postal_code: singpassAddress?.postal?.value,
						street: singpassAddress?.street?.value,
						block: singpassAddress?.block?.value,
						floor: singpassAddress?.floor?.value,
						unit: singpassAddress?.unit?.value,
						building: singpassAddress?.building?.value,
						country: singpassAddress?.country?.desc,
						fill_with_singpass: true,
					};

					GTMCheckoutSingpassRetrieveInfoSuccess(checkoutAnalyticsData, {
						user: {
							learnermaster_id: null,
							learnermaster_name: newFormValue.name,
							learnermaster_dob: newFormValue.dob,
							learnermaster_nationality: newFormValue.nationality,
							learnermaster_gender: newFormValue.gender,
						},
					});

					// lock all the fields that are not empty from singpass response
					const fieldsToLock = Object.fromEntries(
						Object.entries(newFormValue).filter(([field_name, value]) => {
							// if it's a boolean value (true or false), then lock the field
							if (["email", "mobile"].includes(field_name)) {
								return false;
							}

							if (typeof value === "boolean" || typeof value === "number") {
								return true;
							}

							// else lock all the 'true' value (string) but not those 'falsy' value (empty string, null, undefined)
							else return Boolean(value);
						}),
					);

					reset(newFormValue);
					setLockedField(fieldsToLock);
					setisSingpass(true);
					window.history.replaceState(null, null, `/checkout/account?${createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString()}`);
				});
		} else if (searchParams.get("netrust_error") == 1) {
			// error
			GTMCheckoutSingpassRetrieveInfoFailed(checkoutAnalyticsData);
			toast.error("Singpass retrieve info failed, Please try again");
			window.history.replaceState(null, null, `/checkout/account?${createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString()}`);
		} else {
			window.history.replaceState(null, null, `/checkout/account?${createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString()}`);
			// toast.error("Singpass retrieve into failed, Please try again");
		}
	};

	/**
	 * auto populate the form if has regform cache or user data
	 */
	const [showRequireLoan, setRequireLoan] = useSessionStorageState(
		"checkout_required_laptop",
		false,
	);

	useEffect(() => {
		if (registerForm || learners) {
			setEditMode(false);
			reset({
				name: learners.learner_name,
				nric: learners.learner_nric,
				dob: learners.learner_dob,
				nationality: learners.learner_nationality,
				gender: learners.learner_gender,
				email: learners.learner_email,
				mobile: learners.learner_mobile,
				education: learners.learner_qualificationlevel,
				designation: learners.learner_designation,
				postal_code: learners.learner_postal,
				laptopLoan: learners.learner_laptoploan,
				street: learners.learner_street,
				unit: learners.learner_unit,
				block: learners.learner_block,
				floor: learners.learner_floor,
				building: learners.learner_building,
				country: learners.learner_country,
				fill_with_singpass: learners.fill_with_singpass,
			});
		} else if (registerFormCache) {
			reset({ ...registerFormCache });
		} else if (userData) {
			populateRegformByUser(userData);
		}
		fetchSingpassData();
	}, []);

	useEffect(() => {
		if (checkoutAnalyticsData && checkoutAnalyticsData.course_id !== null && isFirstTime) {
			GTMCheckoutStep1Account(checkoutAnalyticsData, userData);
			setIsFirstTime(false);
		}

		// analytics for close tab
		const handleTabClosing = () => {
			if (checkoutAnalyticsData && checkoutAnalyticsData.course_id !== null) {
				GTMCheckoutStep1AccountExit(checkoutAnalyticsData, userData);
			}
		};

		window.addEventListener("unload", handleTabClosing);
		return () => {
			window.removeEventListener("unload", handleTabClosing);
		};
	}, [checkoutAnalyticsData, userData]);

	return (
		<>
			{userData || isSingpass ? <CheckoutLogoutButton /> : null}
			<div className={classNames(styles.root, "shadow-sm")}>
				<SuccessCreateAccountModal
					onNext={() => {
						createdAccountRef.current = false;
						setcreateAccountSuccessModal(false);
						const newSearchParam = [];
						for(let entry of searchParams.entries()) {
							newSearchParam.push(entry);
						}
						if (newSearchParam.uuidv4) {
							delete newSearchParam.uuidv4;
						}

						generalApi
							.checkGrant({
								learner_nric: learners.learner_nric,
								learner_dob: learners.learner_dob,
								learner_nationality: learners.learner_nationality,
								courserun_api_id: courserun.courserun_apicourserunid,
								course_ref: course.course_ref,
								courserun_course_from: courserun.courserun_course_from,
								trigger_from: "FALCOMM"
							})
							.then(async (responseData) => {
								if (responseData.status === "Success") {
									toast.success("Proceed to payment", {
										icon: "🎉",
									});

									window.scrollTo({ top: 0 });

									GTMCheckoutStep1GoEndorse(checkoutAnalyticsData, userData);
									navigate({
										pathname: "/checkout/course",
										search: createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString(),
									});
								}
							})
							.catch((error) => {
								if (isAxiosError(error)) {
									console.warn("Check Grant Error", error);
									const responseData = error.response.data;

									if (responseData.status === "Error") {
										setErrorData({
											element: (
												<Modal isOpen centered>
													<ModalHeader>Registration Failed</ModalHeader>
													<ModalBody>
														<div dangerouslySetInnerHTML={{ __html: responseData.error_msg }}></div>
													</ModalBody>
													<ModalFooter>
														<Link to={"https://www.fca.edu.sg/courses/"} replace>
															<Button color='primary'>Return to course</Button>
														</Link>
													</ModalFooter>
												</Modal>
											),
										});
										return;
									} else {
										throw error;
									}
								}
							});

						// GTMCheckoutStep1GoEndorse(checkoutAnalyticsData, userData);
						// navigate({
						// 	pathname: "/checkout/course",
						// 	search: createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString(),
						// });
					}}
					isOpen={createAccountSuccessModal}
					toggle={toggleSuccessCreateAccountModal}
				/>
				<LoginModal isOpen={loginModal} toggle={toggleLoginModal} onSuccess={handleLoginSuccess} />
				{otpmodal ? <OTPModalSignup onSuccess={handleOTPSuccess} /> : null}

				{!userData && !isSingpass ? (
					<div className='mb-4'>
						<h2 className='fs30 text-color-dark mb-3'>
							Thank you for your interest in our courses. Account registration is required for you
							to complete your enrollment online.
						</h2>
						<p className='fs15 text-color-dark'>
							Course fee funding is available for self-sponsored individuals.
						</p>
						<p className='fs15 text-color-dark'>
							To accurately calculate your Course Fee Funding based on your eligiblity and complete
							the enrolment, please register for an account.
						</p>
						<p className='fs15 text-color-dark'>
							For <span className='text-fca-green font-weight-600'>new users</span>, please click on
							"Retrieve Myinfo with Singpass" to proceed with your account registration.
						</p>
						<p className='fs15 text-color-dark'>
							For <span className='text-fca-green font-weight-600'>existing users</span>, please
							click on the login button below to proceed.
						</p>
						<p className='fs15 text-color-dark'>
							For <span className='text-fca-blue font-weight-600'>users with no Singpass</span>, you
							may contact us via our hotline at{" "}
							<a href={`tel:${CONTACT_NO_CHECKOUT}`}>{CONTACT_NO_CHECKOUT}</a> or leave us a message
							via our{" "}
							<a
								href='#enquiry-form'
								onClick={(event) => {
									event.preventDefault();
									openEnquiryModal();
								}}>
								enquiry form
							</a>
							. Our education consultants will reach out to you within one working day.
						</p>

						<div className={`text-center text-md-left ${styles.login_button_container}`}>
							<div className={styles.singpass_login_container}>
								<div onClick={handleSingpassLogin} className={`${styles.button_wrap} ${isSingPassUnderMaintenance ? styles.disabled : ""}`}>
									<img
										src={SingpassButton}
										alt='Singpass Myinfo'
										className={styles.singpass_login_button}
									/>
								</div>
								{isSingPassUnderMaintenance && (
									<div className='invalid-feedback d-inline-block'>
										MyInfo is currently under maintenance, please check back again later
									</div>
								)}
							</div>
							{/* <div className={styles.self_login_container}> */}
							<div className={styles.button_wrap}>
								<Button
									className={styles.user_login_btn}
									onClick={() => {
										toggleLoginModal();
										GTMCheckoutUserLoginButton(checkoutAnalyticsData, userData);
									}}>
									Existing User Login
								</Button>
							</div>

							{/* </div> */}
						</div>
					</div>
				) : (
					<div>
						{userData && !userData.user.email_verified_at ? (
							<Alert>
								<p>
									Seems like your email is not verified yet. Please verify now to continue the
									checkout
								</p>
								<Button
									onClick={() => {
										GTMCheckoutUserLoginOtpVerify(checkoutAnalyticsData, userData);
										setOTPModal(true);
									}}>
									Verify Email
								</Button>
							</Alert>
						) : null}

						<h3 className={styles.section_header}>Personal Particulars</h3>
						<Form onSubmit={handleSubmit(handleContinue.mutate)}>
							<Row>
								<Col lg={6}>
									<FormGroup>
										<Label>NRIC or ID No.</Label>
										<input
											disabled={!editMode || lockedField.nric}
											className={"form-control " + (errors.nric ? "is-invalid" : "")}
											{...register("nric")}
											placeholder='NRIC'
										/>
										<FormFeedback>{errors.nric ? errors.nric.message : null}</FormFeedback>
									</FormGroup>
								</Col>

								<Col lg={6}>
									<FormGroup>
										<Label>Full Name (As Shown in NRIC)</Label>
										<input
											disabled={!editMode || lockedField.name}
											className={"form-control " + (errors.name ? "is-invalid" : "")}
											{...register("name")}
											placeholder='Full Name (As Shown in NRIC)'
										/>
										<FormFeedback>{errors.name ? errors.name.message : null}</FormFeedback>
									</FormGroup>
								</Col>

								<Col lg={6}>
									<FormGroup>
										<Label>Date of Birth</Label>
										<input
											disabled={!editMode || lockedField.dob}
											className={"form-control " + (errors.dob ? "is-invalid" : "")}
											type='date'
											placeholder='Date of Birth'
											{...register("dob")}
										/>
										<FormFeedback>{errors.dob ? errors.dob.message : null}</FormFeedback>
									</FormGroup>
								</Col>

								<Col lg={6}>
									<FormGroup>
										<Label>Nationality</Label>
										<select
											disabled={!editMode || lockedField.nationality}
											className={"form-control " + (errors.nationality ? "is-invalid" : "")}
											type='select'
											{...register("nationality")}>
											{NATIONALITY_OPTIONS.map((item) => {
												return (
													<option key={item.value} value={item.value}>
														{item.label}
													</option>
												);
											})}
										</select>
										<FormFeedback>
											{errors.nationality ? errors.nationality.message : null}
										</FormFeedback>
									</FormGroup>
								</Col>

								<Col lg={4}>
									<FormGroup>
										<Label>Email Addresss</Label>
										<input
											disabled={!editMode || lockedField.email}
											className={"form-control " + (errors.email ? "is-invalid" : "")}
											{...register("email")}
											placeholder='example@example.com'
										/>
										<FormFeedback>{errors.email ? errors.email.message : null}</FormFeedback>
									</FormGroup>
								</Col>

								<Col lg={4}>
									<FormGroup>
										<Label>Mobile No.</Label>
										<input
											disabled={!editMode || lockedField.mobile}
											className={"form-control " + (errors.mobile ? "is-invalid" : "")}
											{...register("mobile")}
											placeholder='Mobile Number'
										/>
										<FormFeedback>{errors.mobile ? errors.mobile.message : null}</FormFeedback>
									</FormGroup>
								</Col>

								<Col lg={4}>
									<FormGroup>
										<Label>Gender</Label>
										<select
											disabled={!editMode || lockedField.gender}
											className={"form-control " + (errors.gender ? "is-invalid" : "")}
											{...register("gender")}
											type='select'
											placeholder='Gender'>
											<option value=''>Select an option</option>
											{GENDER_OPTIONS.map((item) => {
												return (
													<option key={item.value} value={item.value}>
														{item.label}
													</option>
												);
											})}
										</select>
										<FormFeedback>{errors.gender ? errors.gender.message : null}</FormFeedback>
									</FormGroup>
								</Col>

								<Col lg={12}>
									<h3 className={classNames(styles.section_header, "mb-4")}>Address</h3>
								</Col>

								<Col lg={12}>
									<FormGroup>
										<Label>Street</Label>
										<input
											disabled={!editMode || lockedField.street}
											className={"form-control " + (errors.street ? "is-invalid" : "")}
											placeholder='Street'
											{...register("street")}
										/>
									</FormGroup>
									<FormFeedback>{errors.street ? errors.street.message : null}</FormFeedback>
								</Col>

								<Col lg={12}>
									<FormGroup>
										<Label>Building Name</Label>
										<input
											disabled={!editMode || lockedField.building}
											className={"form-control " + (errors.building ? "is-invalid" : "")}
											placeholder='building'
											{...register("building")}
										/>
									</FormGroup>
									<FormFeedback>{errors.building ? errors.building.message : null}</FormFeedback>
								</Col>

								<Col xs={12} md={4}>
									<FormGroup>
										<Label>Block</Label>
										<input
											disabled={!editMode || lockedField.block}
											className={"form-control " + (errors.block ? "is-invalid" : "")}
											placeholder='Block'
											{...register("block")}
										/>
									</FormGroup>
									<FormFeedback>{errors.block ? errors.block.message : null}</FormFeedback>
								</Col>

								<Col xs={6} md={4}>
									<FormGroup>
										<Label>Floor</Label>
										<input
											disabled={!editMode || lockedField.floor}
											className={"form-control " + (errors.floor ? "is-invalid" : "")}
											placeholder='floor'
											{...register("floor")}
										/>
									</FormGroup>
									<FormFeedback>{errors.floor ? errors.floor.message : null}</FormFeedback>
								</Col>

								<Col xs={6} md={4}>
									<FormGroup>
										<Label>Unit No</Label>
										<input
											disabled={!editMode || lockedField.unit}
											className={"form-control " + (errors.unit ? "is-invalid" : "")}
											placeholder='Unit No'
											{...register("unit")}
										/>
										<FormFeedback>{errors.unit ? errors.unit.message : null}</FormFeedback>
									</FormGroup>
								</Col>

								<Col lg={6}>
									<FormGroup>
										<Label>Postal Code</Label>
										<input
											disabled={!editMode || lockedField.postal_code}
											className={"form-control " + (errors.postal_code ? "is-invalid" : "")}
											placeholder='Postal Code'
											{...register("postal_code")}
										/>
										<FormFeedback>
											{errors.postal_code ? errors.postal_code.message : null}
										</FormFeedback>
									</FormGroup>
								</Col>

								<Col lg={6}>
									<FormGroup>
										<Label>Country</Label>
										<select
											disabled={!editMode || lockedField.country}
											className={"form-control " + (errors.country ? "is-invalid" : "")}
											type='select'
											{...register("country")}>
											{COUNTRIES_OPTIONS.map((item) => {
												return (
													<option key={item.value} value={item.value}>
														{item.label}
													</option>
												);
											})}
										</select>
										<FormFeedback>{errors.country ? errors.country.message : null}</FormFeedback>
									</FormGroup>
								</Col>

								<Col lg={12}>
									<FormGroup>
										<Label>Education/ Qualification Level</Label>
										<select
											disabled={!editMode || lockedField.education}
											className={"form-control " + (errors.education ? "is-invalid" : "")}
											type='select'
											placeholder='Select Your Education/ Qualifications'
											{...register("education")}>
											<select value=''>Select your qualification level</select>
											{QUALIFICATOIN_LEVEL_OPTION.map((item) => {
												return (
													<option key={item.value} value={item.value}>
														{item.label}
													</option>
												);
											})}
										</select>
										<FormFeedback>
											{errors.education ? errors.education.message : null}
										</FormFeedback>
									</FormGroup>
								</Col>

								<Col lg={12}>
									<FormGroup>
										<Label>Occupation</Label>
										<input
											disabled={!editMode || lockedField.designation}
											className={"form-control " + (errors.designation ? "is-invalid" : "")}
											placeholder='e.g. Outlet Manager'
											{...register("designation")}
										/>
										<FormFeedback>
											{errors.designation ? errors.designation.message : null}
										</FormFeedback>
									</FormGroup>
								</Col>

								<Col xs={12}>
									<Divider className='my-5' style={{ marginLeft: -20, marginRight: -20 }} />
								</Col>

								<Col lg={12}>
									<h3 className={classNames(styles.section_header, "mb-4")}>Laptop Loan</h3>
								</Col>
								<Col lg={12}>
									<FormGroup>
										<Label>
											As our courses required the usage of laptop. Do you have your own laptop for
											class?
										</Label>
										<select
											disabled={!editMode}
											defaultValue='1'
											className={"form-control " + (errors.laptopLoan ? "is-invalid" : "")}
											onChange={(requireLoan) => {
												if (requireLoan.target.value == "0") {
													setRequireLoan(true);
													setValue("laptopLoan", "0"); // make sure we clear the value
												} else {
													setRequireLoan(false);
													setValue("laptopLoan", "0"); // make sure we clear the value
												}
											}}>
											<option value='1'>Yes</option>
											<option value='0'>No</option>
										</select>
										<FormFeedback>
											{errors.laptopLoan ? errors.laptopLoan.message : null}
										</FormFeedback>
									</FormGroup>
								</Col>

								{showRequireLoan && (
									<Col lg={12}>
										<FormGroup>
											<Label>Do you require to loan a laptop?</Label>
											<select
												disabled={!editMode}
												className={"form-control"}
												{...register("laptopLoan")}>
												{LOAN_OPTIONS.map((item) => {
													return (
														<option key={item.value} value={item.value}>
															{item.label}
														</option>
													);
												})}
											</select>
											<FormFeedback>
												{errors.laptopLoan ? errors.laptopLoan.message : null}
											</FormFeedback>
										</FormGroup>
									</Col>
								)}

								{!userData ? (
									<>
										<Col xs={12}>
											<Divider className='my-5' style={{ marginLeft: -20, marginRight: -20 }} />
										</Col>

										<Col lg={6}>
											<FormGroup id='password_box'>
												<Label>Password</Label>
												<InputGroup
													className={
														"input-group-merge " +
														(errors.password ? "needs-validation is-invalid" : "")
													}>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText
															className={"form-control " + (errors.password ? "is-invalid" : "")}>
															{/* <i className='ni ni-lock-circle-open' /> */}
															<LockIcon />
														</InputGroupText>
													</InputGroupAddon>
													<input
														autoComplete='new-password'
														placeholder='Password'
														type={showPassword ? "text" : "password"}
														className={"form-control " + (errors.password ? "is-invalid" : "")}
														{...register("password")}
													/>
													<InputGroupAddon addonType='append'>
														<InputGroupText
															onClick={() => {
																setShowPassword(!showPassword);
															}}>
															{showPassword ? (
																<i className='far fa-eye-slash'></i>
															) : (
																<i className='far fa-eye'></i>
															)}
														</InputGroupText>
													</InputGroupAddon>
												</InputGroup>

												<div className='invalid-feedback'>{errors.password?.message}</div>
												<UncontrolledTooltip placement='right' target='password_box'>
													<div>- At least 1 Upper case character</div>
													<div>- At least 1 Lower case character</div>
													<div>- At least 1 Numeric character</div>
													<div>- At least 1 Special character</div>
												</UncontrolledTooltip>
											</FormGroup>
										</Col>

										<Col lg={6}>
											<FormGroup>
												<Label>Confirm Password</Label>
												<InputGroup
													className={
														"input-group-merge " +
														(errors.password_confirmation ? "needs-validation is-invalid" : "")
													}>
													<InputGroupAddon addonType='prepend'>
														<InputGroupText
															className={
																"form-control " + (errors.password_confirmation ? "is-invalid" : "")
															}>
															{/* <i className='ni ni-lock-circle-open' /> */}
															<LockIcon />
														</InputGroupText>
													</InputGroupAddon>
													<input
														autoComplete='new-password'
														placeholder='Password'
														type={showPassword ? "text" : "password"}
														className={
															"form-control " + (errors.password_confirmation ? "is-invalid" : "")
														}
														{...register("password_confirmation")}
													/>
													<InputGroupAddon addonType='append'>
														<InputGroupText
															onClick={() => {
																setShowPassword(!showPassword);
															}}>
															{showPassword ? (
																<i className='far fa-eye-slash'></i>
															) : (
																<i className='far fa-eye'></i>
															)}
														</InputGroupText>
													</InputGroupAddon>
												</InputGroup>
												<div className='invalid-feedback'>
													{errors.password_confirmation?.message}
												</div>
											</FormGroup>
										</Col>
									</>
								) : null}
								<Col>{errorData ? errorData.element : null}</Col>
								<Row className='w-100 justify-content-end ml-0'>
									{editMode ? (
										<>
											{handleContinue.isLoading ? (
												<Col className='mt-2' md={12} lg={"auto"}>
													<Button type='submit' className={classNames("btn-gradient")}>
														<Spinner />
													</Button>
												</Col>
											) : (
												<Col className='mt-2' md={12} lg={"auto"}>
													<Button type='submit' className={classNames("btn-gradient")}>
														{!userData ? "Create Account" : "Continue"}
													</Button>
												</Col>
											)}
										</>
									) : (
										<>
											<Col className='mt-2' md={12} lg={"auto"}>
												<Button className='w-100' outline color='primary' onClick={handleClickEdit}>
													I want to update my details
												</Button>
											</Col>
											<Col className='mt-2' md={12} lg={"auto"}>
												<Button
													className={classNames("btn-gradient", "w-100")}
													onClick={handleProceedToPaymentPage}>
													Proceed to Payment
												</Button>
											</Col>
										</>
									)}
								</Row>
							</Row>
						</Form>
					</div>
				)}
			</div>
		</>
	);
}

const loginSchema = yup
	.object({
		email: yup.string().email().required(),
		password: yup.string().required(),
	})
	.required();

function LoginModal(props) {
	const dispatch = useDispatch();
	const [passwordType, setPasswordType] = useState("password");
	const lg = useMediaQuery("(min-width: 992px)");
	const { message } = useSelector((state) => state.message);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(loginSchema),
	});
	const [showForgetPassword, setShowForgetPassword] = useState(false);
	const { checkoutAnalyticsData } = useCheckout();

	// const handleLogin = () => {};
	const handleLogin = useMutation(
		(data) => {
			dispatch(clearMessage());
			return dispatch(login(data.email, data.password)).then((data) => {
				return data;
			});
		},
		{
			onSuccess: (data) => {
				props.toggle();
				props.onSuccess(data);
				logGTMUser(
					data.user.learnermaster_id ? data.user.learnermaster_id.toString() : "",
					data.user.learnermaster_name ? data.user.learnermaster_name.toString() : "",
					data.user.learnermaster_dob ? data.user.learnermaster_dob : "",
					data.user.learnermaster_nationality ? data.user.learnermaster_nationality : "",
					data.user.learnermaster_gender ? data.user.learnermaster_gender : "",
				);
				GTMCheckoutUserLoginSuccess(checkoutAnalyticsData, data);
			},
		},
	);

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text");
			return;
		}
		setPasswordType("password");
	};

	return (
		<>
			<Modal size='xl' className='modal-dialog-centered' {...props}>
				<EmbeddedResetPasswordModal
					isOpen={showForgetPassword}
					onSuccess={(data) => {
						setTimeout(() => {
							toast.success("Password reset successfully 🎉");
						}, 500);
						GTMCheckoutUserForgetPasswordSuccess(checkoutAnalyticsData);
						setShowForgetPassword(false);
					}}
					toggle={() => {
						setShowForgetPassword(!showForgetPassword);
					}}
				/>

				<div
					style={{ position: "absolute", right: 10, top: 10, zIndex: 10000 }}
					onClick={props.toggle}>
					<i className='fa fa-times' aria-hidden='true'></i>
				</div>

				<Row className='mr-0 ml-0'>
					{lg && (
						<Col xs={12} lg={6} className='pl-0 pr-0'>
							<img src={CheckoutLoginImage} className={styles.login_modal_img} />
						</Col>
					)}

					<Col xs={12} lg={6} className={classNames(styles.login_modal_col)}>
						<div>
							<h2 className={styles.login_modal_header}>Welcome ! Please Sign In to Continue</h2>
							<Form id='login_form' onSubmit={handleSubmit(handleLogin.mutateAsync)}>
								<FormGroup>
									<Label>Email</Label>
									<InputGroup className={"input-group-merge " + (errors.email ? "is-invalid" : "")}>
										<InputGroupAddon addonType='prepend'>
											<InputGroupText
												className={"form-control " + (errors.email ? "is-invalid" : "")}>
												<UserIcon />
											</InputGroupText>
										</InputGroupAddon>
										<input
											placeholder='Email'
											type='email'
											className={"form-control " + (errors.email ? "is-invalid" : "")}
											{...register("email")}
										/>
									</InputGroup>
									<div className='invalid-feedback'>{errors.email?.message}</div>
								</FormGroup>

								<FormGroup>
									<Label>Password</Label>
									<InputGroup
										className={
											"input-group-merge " + (errors.password ? "needs-validation is-invalid" : "")
										}>
										<InputGroupAddon addonType='prepend'>
											<InputGroupText
												className={"form-control " + (errors.password ? "is-invalid" : "")}>
												{/* <i className='ni ni-lock-circle-open' /> */}
												<LockIcon />
											</InputGroupText>
										</InputGroupAddon>
										<input
											placeholder='Password'
											type={passwordType}
											className={"form-control " + (errors.password ? "is-invalid" : "")}
											{...register("password")}
										/>
										<InputGroupAddon addonType='append'>
											<InputGroupText onClick={togglePassword}>
												{passwordType === "password" ? (
													<i className='far fa-eye-slash'></i>
												) : (
													<i className='far fa-eye'></i>
												)}
											</InputGroupText>
										</InputGroupAddon>
									</InputGroup>
									<div className='invalid-feedback'>{errors.password?.message}</div>
								</FormGroup>

								<div className='custom-control custom-control-alternative custom-checkbox text-center d-none'>
									<input className='custom-control-input' id=' customCheckLogin' type='checkbox' />
									<label className='custom-control-label d-none' htmlFor='customCheckLogin'>
										<span className='text-muted'>Remember me</span>
									</label>
								</div>

								<div className='text-center'>
									<Button
										className={classNames("btn-gradient", styles.submit_button)}
										type='submit'
										disabled={handleLogin.isLoading}>
										{handleLogin.isLoading && (
											<span className='spinner-border spinner-border-sm mr-2'></span>
										)}
										<span>Sign in</span>
									</Button>
								</div>
								<div className='mt-4 text-center'>
									<a
										href='/login/reset-password'
										target='_blank'
										className='ml-2'
										onClick={(e) => {
											e.preventDefault();
											setShowForgetPassword(true);
											GTMCheckoutUserForgetPasswordButton(checkoutAnalyticsData);
										}}>
										Forgot Password?
									</a>
								</div>
								{message && (
									<div className='form-group pt-3'>
										<div className='alert alert-danger' role='alert'>
											{message}
										</div>
									</div>
								)}
							</Form>
						</div>
					</Col>
				</Row>
			</Modal>
		</>
	);
}

function SuccessCreateAccountModal(props) {
	return (
		<Modal size='xl' className='modal-dialog-centered' {...props}>
			<div className={styles.successModalContainer}>
				<CreateAccountFloating />
				<h2 className={classNames(styles.successHeading, "mt-5")}>
					<span className={styles.successCongrats}>Congratulations!</span>
					<span>Your account has been created successfully</span>
				</h2>
				<p className={styles.successMessage}>
					<span>
						Your account has been created and you are one step away from acquiring a new skill.
					</span>
				</p>
				<Button className={classNames("btn-gradient")} onClick={props.onNext}>
					Next
				</Button>
			</div>
		</Modal>
	);
}

function ErrorDisplay({ title, description, children, ...rest }) {
	return (
		<Alert color='danger' {...rest}>
			{title ? <h3 style={{ color: "white" }}>{title}</h3> : null}
			{description ? <p>{description}</p> : null}
			<div>{children}</div>
		</Alert>
	);
}

ErrorDisplay.propsTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	children: PropTypes.oneOfType([PropTypes.element]),
};
