/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// reactstrap components
import { Button, Card, CardBody, FormGroup, Form, Container } from "reactstrap";

import AuthService from "../../../services/auth-api";
import ModalChangePassword from "./ModalChangePassword";
import { getErrorMessage } from "utilities/error";

const schema = yup.object({
	otp: yupHelper.otp(),
});

function ResetPasswordOtpValidation() {
	const [modalOpen, setmodalOpen] = React.useState(false);
	let navigate = useNavigate();
	// const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
	const [loading, setLoading] = useState(false);
	const [otp, setotp] = useState("");
	const [message, setmessage] = useState("");
	const { state } = useLocation();
	const { email } = state;
	const [seconds, setSeconds] = useState(30);

	if (!email) {
		navigate("/login/reset-password");
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const handleOTP = (data) => {
		setLoading(true);

		data.email = email;
		let submit_otp = data.otp;

		AuthService.forgetPasswordVerifyOtp(data)
			.then(
				() => {
					setLoading(false);
					setotp(submit_otp);
					setmodalOpen(true);
				},
				(error) => {
					setLoading(false);
					setmessage(getErrorMessage(error));
				},
			)
			.catch(() => {
				setLoading(false);
			});
	};

	const handleSendOTP = (data) => {
		if (!loading && seconds === 0) {
			setLoading(true);
			AuthService.forgetPasswordSendEmail(data.email)
				.then(
					() => {
						setSeconds(30);
						setLoading(false);
					},
					() => {
						setSeconds(30);
						setLoading(false);
					},
				)
				.catch(() => {
					setLoading(false);
				});
		}
			
	};

	useEffect(() => {
		if (seconds !== 0) {
			const interval = setInterval(() => {
				setSeconds(seconds - 1);
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [seconds]);

	return (
		<>
			<ModalChangePassword
				modalChange={modalOpen}
				setModalChange={setmodalOpen}
				otp={otp}
				email={email}
			/>
			<Container className='mt-5' fluid>
				<div className='title-container text-center mb-3'>
					<h3>Change Password OTP Verification</h3>
				</div>
				<Card className='border-0 mx-auto' style={{ maxWidth: "600px" }}>
					<CardBody className='px-lg-5 py-lg-5 text-center'>
						<div className='pb-4'>
							<p className='mb-0'>Enter the OTP verification code that sent to</p>
							<p>
								<u className='text-lowercase'>{email}</u>
							</p>
						</div>

						<Form role='form' onSubmit={handleSubmit(handleOTP)}>
							<FormGroup>
								<input
									className={"form-control " + (errors.otp ? "is-invalid" : "")}
									defaultValue=''
									placeholder='OTP Verification Code'
									type='text'
									{...register("otp")}
								/>
								<div className='mt-2 invalid-feedback text-center'>{errors.otp?.message}</div>
							</FormGroup>
							<div className='mt-2 text-center'>
								Do not receive OTP Code?{" "}
								{seconds !== 0 && <span className='text-blue bold'>{seconds}</span>}
								{seconds === 0 && (
									<span disabled={loading} onClick={handleSendOTP} role='button' className='text-blue bold'>
										Resend
									</span>
								)}
							</div>
							<div className='justify-content-center mb-4 mt-2'>
								<Button className='mt-2 w-100 btn-gradient' type='submit' disabled={loading}>
									{loading && <span className='spinner-border spinner-border-sm'></span>}
									<span>Verify</span>
								</Button>
							</div>

							{message && (
								<div className='form-group'>
									<div
										className={classnames(
											"alert",
											message === "OTP Sent Success" ? "alert-success" : "alert-danger",
										)}
										role='alert'
									>
										{message}
									</div>
								</div>
							)}
						</Form>
					</CardBody>
				</Card>

				<div className='signup_wrap text-center pt-2'>
					{/* {process.env.REACT_APP_APP_VERSION != "0.0.0" && (
						<div className='pdt20'>
							<span>
                Don't have an account?
								<Link to='/register' className='text-blue bold'>
                  Create new account
								</Link>
							</span>
						</div>
					)} */}
					<div className='pdt10'>
						<span>
              I already have an account.
							<Link to='/login' className='text-blue bold ml-1'>
                Login
							</Link>
						</span>
					</div>
				</div>
			</Container>
		</>
	);
}

export default ResetPasswordOtpValidation;
