/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
import * as yup from "yup";
// eslint-disable-next-line
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form";
// reactstrap components
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from "reactstrap";
// core components
import styles from "assets/scss/module/auth/login.module.scss";

import AuthService from "../../../services/auth-api";
import { clearMessage } from "actions/message";

const schema = yup
	.object({
		email: yup.string().email().required(),
	})
	.required();

function Login() {
	let navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();
	const { message } = useSelector((state) => state.message);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const handleSendOTP = (data) => {
		let email = data.email;
		setLoading(true);
		AuthService.forgetPasswordSendEmail(data.email)
			.then(
				() => {
					navigate("/login/reset-password/otp-validation", { state: { email: email } });
				},
				() => {},
			)
			.catch(() => {
				setLoading(false);
			});
	};

	React.useEffect(() => {
		dispatch(clearMessage());
	}, []);

	return (
		<>
			<Container className=''>
				<Row className='justify-content-center'>
					<Col lg='8' md='12'>
						<Card className='bg-white border-0 mb-0'>
							<CardBody className='px-lg-4 py-lg-'>
								<div className='text-center text-muted mb-4'>
									<h5>Forget Password?</h5>
									<p>Enter your email address to retrive OTP.</p>
								</div>
								<Form id='login_form' onSubmit={handleSubmit(handleSendOTP)}>
									<FormGroup>
										<InputGroup
											className={"input-group-merge " + (errors.email ? "is-invalid" : "")}
										>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText
													className={"form-control " + (errors.email ? "is-invalid" : "")}
												>
													<i className='ni ni-email-83' />
												</InputGroupText>
											</InputGroupAddon>
											<input
												placeholder='Email'
												type='email'
												className={"form-control " + (errors.email ? "is-invalid" : "")}
												{...register("email")}
											/>
										</InputGroup>
										<div className='invalid-feedback'>{errors.email?.message}</div>
									</FormGroup>

									<div className='text-center'>
										<Button
											className={classnames("my-4 btn-gradient", styles.submit_button)}
											type='submit'
											disabled={loading}
										>
											{loading && <span className='spinner-border spinner-border-sm'></span>}
											<span>Send OTP</span>
										</Button>
									</div>

									{message && (
										<div className='form-group'>
											<div className='alert alert-danger' role='alert'>
												{message}
											</div>
										</div>
									)}
								</Form>
							</CardBody>
						</Card>
						<div className='signup_wrap text-center'>
							{/* {process.env.REACT_APP_APP_VERSION != "0.0.0" && (
								<div className='pdt20'>
									<span>
                    Don't have an account?
										<Link to='/register' className='text-blue bold ml-1'>
                      Create new account
										</Link>
									</span>
								</div>
							)} */}
							<div className='pdt10'>
								<span>
                  I already have an account.
									<Link to='/login' className='text-blue bold ml-1'>
                    Login
									</Link>
								</span>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Login;
