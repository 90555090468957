import axios from "axios";
import { ARTICLE_LENGTH } from "utilities/constant";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

// get Course
const getUpcomingCourse = () => {
	return axios
		.get(API_URL + "/api/mobile/learner/upcoming_course", { headers: authHeader() })
		.then((response) => {
			return response.data.data;
		});
};

const getUpcomingCourseById = (learnerId) => {
	return axios
		.post(API_URL + "/api/mobile/learner/course_detail", { learner_id : learnerId }, { headers: authHeader() })
		.then((response) => {
			return response.data.data;
		});
};

const getCourseCategory = () => {
	return axios
		.get(API_URL + "/api/mobile/course/category", { headers: authHeader() })
		.then((response) => {
			return response.data.data;
		});
};

const getCourses = (category_id = "", search_word = "") => {
	return axios
		.get(
			API_URL +
				`/api/mobile/course?category=${category_id ? category_id : ""}&q=${
					search_word ? search_word : ""
				}`,
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data.data;
		});
};

const getCourseDetail = (courseId = 0) => {
	return axios
		.get(API_URL + "/api/mobile/v2/course/" + courseId, { headers: authHeader() })
		.then((response) => {
			return response.data.data;
		});
};

const getBundle = () => {
	return axios.get(API_URL + "/api/mobile/bundle", { headers: authHeader() }).then((response) => {
		return response.data.data;
	});
};

const getBundleDetail = (bundleId = 0) => {
	return axios
		.get(API_URL + "/api/mobile/bundle/" + bundleId, { headers: authHeader() })
		.then((response) => {
			return response.data.data;
		});
};

const getBundleDetailV2 = (bundleId = 0) => {
	return axios
		.get(API_URL + "/api/mobile/v2/bundle/" + bundleId, { headers: authHeader() })
		.then((response) => {
			return response.data.data;
		});
};

const getLandingBundle = () => {
	return axios
		.get(API_URL + "/api/mobile/landing/bundle", { headers: authHeader() })
		.then((response) => {
			return response.data.data;
		});
};

// register Course
const checkCanRegister = (courserun_id) => {
	return axios
		.post(
			API_URL + "/api/mobile/registrationform/can_register",
			{ courserun_id },
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

const getCoursePrice = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/registrationform/course_price",
			{
				course_id: data.course_id,
				courserun_id: data.courserun_id,
				learner_dob: data.learner_dob,
				learner_nationality: data.learner_nationality,
				registrationform_sme: data.registrationform_sme,
				registrationform_sponsoredbycompany: data.registrationform_sponsoredbycompany,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

const registerCourse = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/registrationform/create",
			{
				learner_nric: data.learner_nric,
				learner_name: data.learner_name,
				learner_dob: data.learner_dob,
				learner_nationality: data.learner_nationality,
				learner_email: data.learner_email,
				learner_mobile: data.learner_mobile,
				learner_qualificationlevel: data.learner_qualificationlevel,
				learner_designation: data.learner_designation,
				learner_laptoploan: data.learner_laptoploan,
				registrationform_courserun: data.registrationform_courserun,
				registrationform_sponsoredbycompany_yes_no: data.registrationform_sponsoredbycompany_yes_no,
				registrationform_sme: data.registrationform_sme,
				registrationform_billing_email: data.registrationform_billing_email,
				registrationform_company_name: data.registrationform_company_name,
				registrationform_uen: data.registrationform_uen,
				registrationform_company_contact_person: data.registrationform_company_contact_person,
				registrationform_company_contact_email: data.registrationform_company_contact_email,
				registrationform_company_contact_mobile: data.registrationform_company_contact_mobile,
				registrationform_company_address: data.registrationform_company_address,
				registrationform_company_postal: data.registrationform_company_postal,
				registrationform_company_country: data.registrationform_company_country,
				registrationform_signature: data.registrationform_signature,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

/**
 *
 * @param {
 * } data
 * @returns
 */
const submitOnlineRegistration = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/submitOnlineRegistration",
			{
				registrationform_id: data.registrationform_id,
				registrationform_courserun: data.registrationform_courserun,
				registrationform_sponsoredbycompany_yes_no: data.registrationform_sponsoredbycompany_yes_no,
				learner_nric: data.learner_nric,
				learner_name: data.learner_name,
				learner_dob: data.learner_dob,
				learner_nationality: data.learner_nationality,
				learner_email: data.learner_email,
				learner_mobile: data.learner_mobile,
				learner_qualificationlevel: data.learner_qualificationlevel,
				learner_designation: data.learner_designation,
				learner_laptoploan: data.learner_laptoploan,
				learner_learnermaster_id: data.learner_learnermaster_id,
				learner_gender: data.learner_gender,
				learner_postal: data.learner_postal,
				learner_street: data.learner_street,
				learner_block: data.learner_block,
				learner_floor: data.learner_floor,
				learner_unit: data.learner_unit,
				learner_building: data.learner_building,
				learner_country: data.learner_country,
				referral: data.referral,
				// invite: data.invite,

			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};
// check subsidy
const checkMaintenanceMode = () => {
	return axios
		.get(API_URL + "/api/mobile/checkMaintenanceMode")
	  	.then((response) => {
			return response.data.status; // Assuming the API returns an object with a 'status' property
		})
	  	.catch((error) => {
			console.error("Error checking maintenance mode:", error);
			return {
		  		site_maintenance: false,
		  		sfc_maintenance: false,
		  		singpass_maintenance: false,
			}; // Default to false in case of an error
		});
};

// check subsidy
const checkSubsidy = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/registrationform/checkSubsidy",
			{
				course_id: data.course_id,
				courserun_id: data.courserun_id,
				learner_dob: data.learner_dob,
				learner_nationality: data.learner_nationality,
				registrationform_sme: data.registrationform_sme,
				registrationform_sponsoredbycompany: data.registrationform_sponsoredbycompany,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

// check coupon
const checkCoupon = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/registrationform/checkCoupon",
			{
				course_id: data.course_id,
				courserun_id: data.courserun_id,
				learner_id: data.learner_id,
				learner_dob: data.learner_dob,
				learner_nationality: data.learner_nationality,
				registrationform_sme: data.registrationform_sme,
				registrationform_sponsoredbycompany: data.registrationform_sponsoredbycompany,
				registrationform_id: data.registrationform_id,
				coupon_code: data.coupon_code,
				learner_nric: data.learner_nric,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response;
		});
};
// removeCoupon
const removeCoupon = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/registrationform/removeCoupon",
			{
				course_id: data.course_id,
				courserun_id: data.courserun_id,
				learner_id: data.learner_id,
				learner_dob: data.learner_dob,
				learner_nationality: data.learner_nationality,
				registrationform_sme: data.registrationform_sme,
				registrationform_sponsoredbycompany: data.registrationform_sponsoredbycompany,
				registrationform_id: data.registrationform_id,
				learner_nric: data.learner_nric,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response;
		});
};
const createAccountForOnlineRegistration = (data) => {
	console.log("createAccountForOnlineRegistration", data);
	return axios
		.post(
			API_URL + "/api/mobile/online_register_account",
			{
				name: data.name,
				email: data.email,
				nric: data.nric,
				dob: data.dob,
				nationality: data.nationality,
				mobile: data.mobile,
				qualificationlevel: data.qualificationlevel,
				designation: data.designation,
				gender: data.gender,
				password: data.password,
				password_confirmation: data.password_confirmation,
				address_postal: data.address_postal,
				address_street: data.address_street,
				address_block: data.address_block,
				address_floor: data.address_floor,
				address_unit: data.address_unit,
				address_building: data.address_building,
				address_country: data.address_country,
				fill_with_singpass: data.fill_with_singpass,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			if (response.data.status === "Success") {
				localStorage.setItem("user", JSON.stringify(response.data.data));
				localStorage.setItem("isHyperAdminLogin", false);
			}
			return response.data;
		});
};

// edit Course
const getRescheduleCourseRun = (courserun_id) => {
	return axios
		.post(
			API_URL + "/api/mobile/reschedule/get_available_courserun",
			{ courserun_id },
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data.data;
		});
};

const getRescheduleReason = () => {
	return axios
		.get(API_URL + "/api/mobile/reschedule/reason", { headers: authHeader() })
		.then((response) => {
			return response.data.data;
		});
};

const rescheduleCourse = (formData) => {
	let formHeader = {
		...authHeader(),
		"Content-Type": "multipart/form-data",
	};
	return axios
		.post(API_URL + "/api/mobile/reschedule/submit_reschedule_v2", formData, { headers: formHeader })
		.then((response) => {
			return response.data;
		});
};

const getWithdrawalReason = () => {
	return axios
		.get(API_URL + "/api/mobile/withdrawal/reason", { headers: authHeader() })
		.then((response) => {
			return response.data.data;
		});
};

const withdrawalCourse = (formData) => {
	let formHeader = {
		...authHeader(),
		"Content-Type": "multipart/form-data",
	};
	return axios
		.post(API_URL + "/api/mobile/withdrawal/submit", formData, { headers: formHeader })
		.then((response) => {
			return response.data;
		});
};

const getShutterBusDetails = (learner_id) => {
	return axios
		.post(API_URL + "/api/mobile/learner/bus_selection", { learner_id }, { headers: authHeader() })
		.then((response) => {
			return response.data;
		});
};

const saveShuttleBus = (learner_id, selections) => {
	return axios
		.post(
			API_URL + "/api/mobile/learner/save_bus_selection",
			{ learner_id, selections },
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

const getMealDetails = (learner_id) => {
	return axios
		.post(API_URL + "/api/mobile/learner/food_selection", { learner_id }, { headers: authHeader() })
		.then((response) => {
			return response.data;
		});
};

const saveMeal = (learner_id, selections, food_remark) => {
	return axios
		.post(
			API_URL + "/api/mobile/learner/save_food_selection",
			{ learner_id, selections, food_remark },
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

// General
const getTermsAndConditions = (sponsered_by_company) => {
	return axios
		.get(API_URL + "/api/mobile/registrationform/terms_and_conditions", {
			headers: authHeader(),
			params: {
				sponsered_by_company,
			},
		})
		.then((response) => {
			return response.data;
		});
};

const ContactUsSubmitForm = (data) => {
	let request_data = {
		contact_name: data.name,
		contact_mobile: data.mobileno,
		contact_email: data.email,
		contact_course_id: data.course,
		contact_message: data.message,
		send_from: "falcomm_web",
	};
	if (data.company) {
		request_data.contact_company = data.company;
	}
	return axios
		.post(API_URL + "/api/mobile/contact_us/submit_form", request_data, { headers: authHeader() })
		.then((response) => {
			return response.data;
		});
};

const ContactUsSubmitForm2 = (data) => {
	var remarks = "";
	if (data.learning_intention) {
		remarks += "Learning Intention: " + data.learning_intention + "\n";
	}
	remarks += "Remark: " + (data.remarks ? data.remarks : "");

	let request_data = {
		contact_name: data.name,
		contact_mobile: data.mobileno,
		contact_email: data.email,
		ts_ccc: data.ts_ccc,
		// contact_course_id: data.course,
		// contact_remarks: data.remarks, // after consumer module is done, open this up
		contact_remarks: remarks,
		form_type: data.form_type,
		designation: data.designation,
		company_name: data.company_name,
		send_from: "falcomm_web",
		lead_type: data.lead_type ? data.lead_type : null,
		lead_url: data.lead_url ? data.lead_url : null,
		referral: data.referral ? data.referral : null,
		// invite: data.invite ? data.invite : null,
		learning_intention: data.learning_intention ? data.learning_intention : null,
	};
	if (data.company) {
		request_data.contact_company = data.company;
	}
	return axios
		.post(API_URL + "/api/mobile/contact_us/submit_form_v2", request_data, {
			headers: authHeader(),
		})
		.then((response) => {
			return response.data;
		});
};
const createOmiseCharge = (data) => {
	return axios
		.post(
			API_URL + "/api/omiseCreateCharge",
			{
				omiseToken: data.omiseToken,
				omiseSource: data.omiseSource,
				payment_id: data.payment_id,
				payment_mode: data.payment_mode,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};
const checkpaymentStatus = (data) => {
	return axios
		.post(
			API_URL + "/api/paymentStatus",
			{
				payment_id: data.payment_id,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};
// news
const getArticles = (pageParam = 0, article_type = "article") => {
	let length = ARTICLE_LENGTH;
	return axios
		.get(
			API_URL +
				`/api/mobile/article?article_type=${article_type}&start=${pageParam}&length=${length}`,
			{ headers: authHeader() },
		)
		.then((response) => {
			return { ...response.data.data, nextPage: pageParam + length, previousPage: pageParam };
		});
};

// resources
const getResourcesDetails = (resource_id) => {
	return axios
		.get(API_URL + `/api/mobile/learner/resource_detail/${resource_id}`, { headers: authHeader() })
		.then((response) => {
			return response.data;
		});
};

// faq
const getFAQ = () => {
	return axios
		.get(API_URL + "/api/mobile/article/faq", { headers: authHeader() })
		.then((response) => {
			return response.data;
		});
};

// reviews
const getLandingReviews = () => {
	return axios
		.get(API_URL + "/api/mobile/landing/reviews", { headers: authHeader() })
		.then((response) => {
			return response.data.data;
		});
};

// newsletter
const subscribeNewsletter = (email) => {
	return axios.post(API_URL + "/api/mobile/newsletter/subscribe", { email }).then((response) => {
		return response.data;
	});
};

// checkout
const getCheckoutDetail = (registrationform_id = null, courserun_id) => {
	return axios
		.post(API_URL + "/api/mobile/getOnlineRegistration", {
			registrationform_id: registrationform_id,
			courserun_id: courserun_id,
		})
		.then((response) => {
			return response.data;
		});
};

const CreditCardForm = (data) => {
	return axios
		.post(
			API_URL + "/api/epayment",
			{
				regform_id: data.regform_id,
				payment_amount: data.payment_amount,
				payment_method: data.payment_method,
				signature: data.signature,
				course_consultant_code: data.course_consultant_code,
				// coupon
				coupon_id: data.coupon_id ? data.coupon_id : null,
				learnermaster_id: data.learnermaster_id ? data.learnermaster_id : null,
				learner_id: data.learner_id ? data.learner_id : null,
				learner_nricmask: data.learner_nricmask ? data.learner_nricmask : null,
				learner_dob: data.learner_dob ? data.learner_dob : null,
				amount_waived: data.amount_waived ? data.amount_waived : null,
				course_count: data.course_count ? data.course_count : null,
				course_waived: data.course_waived ? data.course_waived : null,
				current_coupon_settings: data.current_coupon_settings ? data.current_coupon_settings : null,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};
const PaynowForm = (data) => {
	return axios
		.post(
			API_URL + "/api/epayment",
			{
				regform_id: data.regform_id,
				payment_amount: data.payment_amount,
				payment_method: data.payment_method,
				signature: data.signature,
				course_consultant_code: data.course_consultant_code,
				// coupon
				coupon_id: data.coupon_id ? data.coupon_id : null,
				learnermaster_id: data.learnermaster_id ? data.learnermaster_id : null,
				learner_id: data.learner_id ? data.learner_id : null,
				learner_nricmask: data.learner_nricmask ? data.learner_nricmask : null,
				learner_dob: data.learner_dob ? data.learner_dob : null,
				amount_waived: data.amount_waived ? data.amount_waived : null,
				course_count: data.course_count ? data.course_count : null,
				course_waived: data.course_waived ? data.course_waived : null,
				current_coupon_settings: data.current_coupon_settings ? data.current_coupon_settings : null,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

const sfcPay = (data) => {
	return axios
		.post(
			API_URL + "/api/sfc_pay",
			{
				encoded: data.encoded,
				// callback_url: data.callback_url,
				// course_id: data.course_id,
				// course_fee: data.course_fee,
				// courserun_id: data.courserun_id,
				// courserun_start_date: data.courserun_start_date,
				// nric: data.nric,
				// email: data.email,
				// mobile: data.mobile,
				// tms_regform_id: data.tms_regform_id,
				// tms_course_id: data.tms_course_id,
				// tms_courserun_id: data.tms_courserun_id,
				// tms_learner_id: data.tms_learner_id,
				// tms_learnermaster_id: data.tms_learnermaster_id,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

const updateOnlineRegistrationPaymentSignature = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/updateOnlineRegistrationPaymentSignature",
			{
				registrationform_id: data.registrationform_id,
				signature: data.signature,
				course_consultant_code: data.course_consultant_code,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

const updateOnlineRegistrationProgress = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/updateOnlineRegistrationProgress",
			{
				registrationform_id: data.registrationform_id,
				registrationform_progress: data.registrationform_progress,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

const registerSuccess = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/registration_success",
			{
				registrationform_id: data.registrationform_id,
				lead_type: data.lead_type ? data.lead_type : null,
				// coupon
				coupon_id: data.coupon_id ? data.coupon_id : null,
				learnermaster_id: data.learnermaster_id ? data.learnermaster_id : null,
				learner_id: data.learner_id ? data.learner_id : null,
				learner_nricmask: data.learner_nricmask ? data.learner_nricmask : null,
				learner_dob: data.learner_dob ? data.learner_dob : null,
				amount_waived: data.amount_waived ? data.amount_waived : null,
				course_count: data.course_count ? data.course_count : null,
				course_waived: data.course_waived ? data.course_waived : null,
				current_coupon_settings: data.current_coupon_settings ? data.current_coupon_settings : null,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

const updateOrderSubmissionLeadSource = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/updateOrderSubmissionLeadSource",
			{
				registrationform_id: data.registrationform_id,
				lead_type: data.lead_type ? data.lead_type : null,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

const fetchSingpassData = (data) => {
	return axios
		.post(
			API_URL + "/api/fetchSingpassData",
			{
				uuidv4: data.uuidv4,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

const checkCCC = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/registrationform/autoCompleteCCC",
			{
				ccc: data.ccc,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

// Bigbang Conversion Log
const SubmitBigBangLeadConversionLog = (data) => {
	let request_data = {
		contact_name: data.name,
		contact_mobile: data.mobileno,
		contact_email: data.email,
		contact_remarks: data.remarks,
		form_type: data.form_type,
		designation: data.designation,
		company_name: data.company_name,
		lead_type: data.lead_type ? data.lead_type : null,
		lead_url: data.lead_url ? data.lead_url : null,
		course_id: data.course_id ? data.course_id : null,
		courserun_id: data.courserun_id ? data.courserun_id : null,
		campaign_type: data.campaign_type ? data.campaign_type : null,
		referral: data.referral ? data.referral : null,
		// invite: data.invite ? data.invite : null,
		utm_campaign: data.utm_campaign ? data.utm_campaign : null,
		utm_content: data.utm_content ? data.utm_content : null,
		utm_medium: data.utm_medium ? data.utm_medium : null,
		utm_source: data.utm_source ? data.utm_source : null,
		utm_term: data.utm_term ? data.utm_term : null,
		ccc: data.ccc ? data.ccc : null,
		lead_id: data.lead_id ? data.lead_id : null,
	};
	if (data.company) {
		request_data.contact_company = data.company;
	}
	return axios
		.post(API_URL + "/api/mobile/conversion/submitBigBangLeadConversionLog", request_data, {
			headers: authHeader(),
		})
		.then((response) => {
			return response.data;
		});
};

const SubmitBigBangPurchasedConversionLog = (data) => {
	let request_data = {
		registrationform_id: data.registrationform_id,
		course_id: data.course_id ? data.course_id : null,
		courserun_id: data.courserun_id ? data.courserun_id : null,
		campaign_type: data.campaign_type ? data.campaign_type : null,
		referral: data.referral ? data.referral : null,
		// invite: data.invite ? data.invite : null,
		utm_campaign: data.utm_campaign ? data.utm_campaign : null,
		utm_content: data.utm_content ? data.utm_content : null,
		utm_medium: data.utm_medium ? data.utm_medium : null,
		utm_source: data.utm_source ? data.utm_source : null,
		utm_term: data.utm_term ? data.utm_term : null,
		url: data.url ? data.url : null,
		ccc: data.ccc ? data.ccc : null,
	};
	return axios
		.post(API_URL + "/api/mobile/conversion/submitBigBangPurchasedConversionLog", request_data, {
			headers: authHeader(),
		})
		.then((response) => {
			return response.data;
		});
};

const checkGrant = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/checkGrantCalculatorPersonalised",
			{
				learner_nric: data.learner_nric,
				learner_dob: data.learner_dob,
				learner_nationality: data.learner_nationality,
				course_ref: data.course_ref,
				courserun_api_id: data.courserun_api_id,
				courserun_course_from: data.courserun_course_from,
				trigger_from: data.trigger_from
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};


/**
 * Event Registration
 */
const createEventRegistrationForm = (payload) => {
	const { event_id , ticket_number=null } = payload;
	return axios
		.post(
			API_URL + "/api/mobile/event/regform/create",
			{
				event_id,
				ticket_number,
				register_from :"FALCOMM_WEB"
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};


const getEventCheckoutDetail = (eventregform_id) => {
	return axios
		.post(API_URL + "/api/mobile/event/regform/getCheckoutInfo", {
			eventregform_id,
		},{
			headers: authHeader(),
		})
		.then((response) => {
			return response.data;
		});
};



const getEventConfirmationInfo = (event_registration_id) => {
	return axios
		.post(API_URL + "/api/mobile/event/getConfrimation", {
			event_registration_id,
		},{
			headers: authHeader(),
		})
		.then((response) => {
			return response.data;
		});
};


const updateEventConfirmation = (payload) => {
	const { event_registration_id , attendance_confirmation  } = payload;
	return axios
		.post(API_URL + `/api/mobile/event_attendance/${event_registration_id}`, {
			event_registration_id,
			attendance_confirmation,
		},{
			headers: authHeader(),
		})
		.then((response) => {
			return response.data;
		});
};




const epaymentForEvent = (payload)=>{
	return axios
		.post(
			API_URL + "/api/mobile/event/epayment",
			payload,
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};



const epaymentChargeCreditCardForEvent = (payload)=>{
	return axios
		.post(
			API_URL + "/api/mobile/event/epayment_charge",
			payload,
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};



export default {
	getCourses,
	getCourseDetail,
	getBundle,
	getBundleDetail,
	getBundleDetailV2,
	getLandingBundle,
	getUpcomingCourse,
	getCourseCategory,
	getRescheduleCourseRun,
	getRescheduleReason,
	rescheduleCourse,
	getWithdrawalReason,
	withdrawalCourse,
	getUpcomingCourseById,
	checkCanRegister,
	registerCourse,
	checkSubsidy,
	checkCoupon,
	removeCoupon,
	getCoursePrice,
	getTermsAndConditions,
	getShutterBusDetails,
	saveShuttleBus,
	getMealDetails,
	saveMeal,
	ContactUsSubmitForm,
	ContactUsSubmitForm2,
	createOmiseCharge,
	checkpaymentStatus,
	CreditCardForm,
	PaynowForm,
	getArticles,
	getResourcesDetails,
	getFAQ,
	getLandingReviews,
	subscribeNewsletter,
	sfcPay,
	getCheckoutDetail,
	submitOnlineRegistration,
	createAccountForOnlineRegistration,
	updateOnlineRegistrationPaymentSignature,
	updateOnlineRegistrationProgress,
	registerSuccess,
	fetchSingpassData,
	checkCCC,
	updateOrderSubmissionLeadSource,
	SubmitBigBangLeadConversionLog,
	SubmitBigBangPurchasedConversionLog,
	checkMaintenanceMode,
	createEventRegistrationForm,
	getEventCheckoutDetail,
	epaymentForEvent,
	epaymentChargeCreditCardForEvent,
	getEventConfirmationInfo,
	updateEventConfirmation,
	checkGrant,
};
