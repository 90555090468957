import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
import authHeader from "./auth-header";

const register = (data) => {
	return axios
		.post(API_URL + "/api/mobile/register", {
			nric: data.nric,
			name: data.name,
			nationality: data.nationality,
			email: data.email,
			dob: data.dob,
			mobile: data.mobileno,
			password: data.password,
			password_confirmation: data.confirmPassword,
			upskilling100 : data.upskilling100,
			registeration_channel: "web"
		})
		.then((response) => {
			if (response.data.data) {
				localStorage.setItem("user", JSON.stringify(response.data.data));
				localStorage.setItem("isHyperAdminLogin", false);
				return response.data.data;
			}
		});
};

const login = (email, password) => {
	return axios
		.post(API_URL + "/api/mobile/login", {
			email,
			password,
		})
		.then((response) => {
			if (response.data.data) {
				if (!response.data.data.isHyperAdminLogin) {
					localStorage.setItem("user", JSON.stringify(response.data.data));
					localStorage.setItem("isHyperAdminLogin", false);
				}
				return response.data.data;
			}
		});
};

const verifyOtp = (otp) => {
	return axios
		.post(
			API_URL + "/api/mobile/verify_email_otp",
			{
				otp,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			const user = JSON.parse(localStorage.getItem("user"));
			user.user.email_verified_at = 1;

			localStorage.setItem("user", JSON.stringify(user));
			localStorage.setItem("isHyperAdminLogin", false);

			return response.data;
		});
};

const resendOtp = () => {
	return axios
		.post(API_URL + "/api/mobile/request_email_otp", {}, { headers: authHeader() })
		.then((response) => {
			return response.data;
		});
};

const resendMobileOtp = ({
	change_mobile = null,
}) => {
	return axios
		.post(API_URL + "/api/mobile/request_mobile_otp", {
			change_mobile
		}, { headers: authHeader() })
		.then((response) => {
			return response.data;
		});
};


const forgetPasswordSendEmail = (email) => {
	return axios
		.post(API_URL + "/api/mobile/request_forgetpassword_otp", { email })
		.then((response) => {
			return response.data;
		});
};

const forgetPasswordVerifyOtp = (data) => {
	return axios
		.post(API_URL + "/api/mobile/verify_forgetpassword_otp", {
			email: data.email,
			otp: data.otp,
		})
		.then((response) => {
			return response.data;
		});
};

// change email
const forgetEmailSendOtp = (email) => {
	return axios
		.post(API_URL + "/api/mobile/request_changeemail_otp", { email })
		.then((response) => {
			return response.data;
		});
};

const forgetEmailVerifyOtp = (data) => {
	return axios
		.post(API_URL + "/api/mobile/verify_changeemail_otp", {
			email: data.email,
			otp: data.otp,
		})
		.then((response) => {
			return response.data;
		});
};

// delete account
const deleteAccountSendOtp = (email) => {
	return axios
		.post(API_URL + "/api/mobile/request_delete_account", {}, { headers: authHeader() })
		.then((response) => {
			return response.data;
		});
};

const deleteAccountVerifyOtp = (data) => {
	return axios
		.post(API_URL + "/api/mobile/verify_delete_account", {
			otp: data.otp,
		}, { headers: authHeader() })
		.then((response) => {
			return response.data;
		});
};


const resetEmail = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/reset_email",
			{
				otp: data.otp,
				email: data.email,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

const forgetPasswordResetPassword = (data) => {
	return axios
		.post(API_URL + "/api/mobile/reset_password", {
			email: data.email,
			otp: data.otp,
			password: data.password,
			password_confirmation: data.confirmPassword,
		})
		.then((response) => {
			return response.data;
		});
};

const ResetPassword = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/reset_password",
			{
				otp: data.otp,
				password: data.password,
				password_confirmation: data.confirmPassword,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

const firstTimeResetPassword = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/reset_password_firsttime",
			{
				password: data.password,
				password_confirmation: data.confirmPassword,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

const getUser = () => {
	return axios
		.post(API_URL + "/api/mobile/getUser", {}, { headers: authHeader() })
		.then((response) => {
			if (response.data.data) {

				const user = JSON.parse(localStorage.getItem("user"));

				let return_data = {
					user: response.data.data,
					token: user.token,
				};
				localStorage.setItem("user", JSON.stringify(return_data));
				return return_data;
			}
		});
};

const getProfileBundle = () => {
	return axios
		.post(API_URL + "/api/mobile/getProfileBundle", {}, { headers: authHeader() })
		.then((response) => {
			if (response.data.data) {
				let return_data = {
					profileBundle: response.data.data.bundles,
					is_completed_one_course: response.data.data.is_completed_one_course,
					almost_completed_bundle_course_left: response.data.data.almost_completed_bundle_course_left,
					almost_completed_bundle_title: response.data.data.almost_completed_bundle_title,
					total_badge: response.data.data.total_badge,
					total_completed_course: response.data.data.total_completed_course,
					total_upcoming_course: response.data.data.total_upcoming_course,
				};
				return return_data;
			}
		});
};


// use for falcomm event app checkout 
const getUserByToken = (bearerToken) => {
	return axios
		.post(API_URL + "/api/mobile/getUser", {}, { headers: {Authorization: "Bearer " +bearerToken} })
		.then((response) => {
			if (response.data.data) {
				let return_data = {
					user: response.data.data,
					token: bearerToken,
				};
				localStorage.setItem("user", JSON.stringify(return_data));
				return return_data;
			}
		});
};


const updateProfile = (data) => {
	return axios
		.post(
			API_URL + "/api/mobile/update_user",
			{
				name: data.name,
				nationality: data.nationality,
				dob: data.dob,
				mobile: data.mobileno,
				qualificationlevel: data.education,
				designation: data.designation,
			},
			{ headers: authHeader() },
		)
		.then((response) => {
			if (response.data.data) {
				const user = JSON.parse(localStorage.getItem("user"));

				let return_data = {
					user: response.data.data,
					token: user.token,
				};
				localStorage.setItem("user", JSON.stringify(return_data));
				return return_data;
			}
		});
};

const logout = () => {
	return axios
		.post(API_URL + "/api/mobile/logout", {}, { headers: authHeader() })
		.then((response) => {
			localStorage.removeItem("user");
			return response.data;
		});
};

// hyperadmin
const hyperAdminResendOtp = () => {
	return axios
		.post(API_URL + "/api/mobile/request_hyperadmin_otp")
		.then((response) => {
			return response.data;
		});
};

const hyperAdminVerifyOtp = (otp) => {
	return axios
		.post(
			API_URL + "/api/mobile/verify_hyperadmin_otp",
			{
				otp,
			},
		)
		.then((response) => {
			return response.data;
		});
};

const hyperAdminSearchUser = (email) => {
	return axios
		.post(
			API_URL + "/api/mobile/search_hyperadmin_learnermaster",
			{
				email,
			},
		)
		.then((response) => {
			return response.data;
		});
};

const hyperAdminLogin = (selectedId) => {
	return axios
		.post(API_URL + "/api/mobile/hyperadmin_login", {
			learnermaster_id: selectedId,
		})
		.then((response) => {
			if (response.data.data) {
				localStorage.setItem("user", JSON.stringify(response.data.data));
				localStorage.setItem("isHyperAdminLogin", true);
				return response.data.data;
			}
		});
};

const hyperAdminLogout = (learnermaster_id) => {
	return axios
		.post(API_URL + "/api/mobile/hyperadmin_logout", {learnermaster_id}, { headers: authHeader() })
		.then((response) => {
			localStorage.removeItem("user");
			localStorage.removeItem("isHyperAdminLogin");
			return response.data;
		});
};


export default {
	register,
	login,
	verifyOtp,
	resendOtp,
	resendMobileOtp,
	forgetPasswordSendEmail,
	forgetPasswordVerifyOtp,
	forgetPasswordResetPassword,
	ResetPassword,
	updateProfile,
	firstTimeResetPassword,
	getUser,
	logout,
	hyperAdminResendOtp,
	hyperAdminVerifyOtp,
	hyperAdminSearchUser,
	hyperAdminLogin,
	hyperAdminLogout,
	forgetEmailSendOtp,
	forgetEmailVerifyOtp,
	resetEmail,
	getUserByToken,
	getProfileBundle,
	deleteAccountSendOtp,
	deleteAccountVerifyOtp
};
