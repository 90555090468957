import React, { useEffect, useRef, useState } from "react";

import styles from "./checkoutcoursesummarypage.module.scss";
import {
	Link,
	useNavigate,
	useParams,
	useLocation,
	useSearchParams,
	createSearchParams,
	useOutletContext,
} from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import defaultLogo from "assets/img/FALCOMM_LOGO.png";

import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Container,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Row,
	Col,
	Modal,
	Table,
	CardHeader,
	Collapse,
	Tooltip,
	Input,
	Label,
	Spinner,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { createFilter, components } from "react-select";

import { useDispatch, useSelector } from "react-redux";
import generalApi from "services/general-api";
import courseRegisterSlice, {
	saveCourse,
	resetcourseRegister,
	saveCourseRun,
	saveCheckoutAnalyticsData,
	saveSFCEncryptedPayload,
} from "reducers/courseRegisterSlice";
import moment from "moment";
import { isTrainerTBA } from "common_function/function.js";

import { ReactComponent as TrainerIcon } from "assets/img/Course/trainer.svg";
import { getErrorMessage } from "utilities/error";

import Coursedetail from "../../../components/Coursedetail";
import CourseDetails from "../../../components/Course/CourseDetails";

import { ReactComponent as CourseSvg } from "assets/img/endorse/icon_course.svg";
import { ReactComponent as SFCSvg } from "assets/img/endorse/icon_SFC.svg";
import { ReactComponent as LearnerSvg } from "assets/img/endorse/icon_learner.svg";
import { ReactComponent as TncSvg } from "assets/img/endorse/icon_T&C.svg";
import { ReactComponent as ThumbsSvg } from "assets/img/Checkout/icon_thumb.svg";
import { ReactComponent as InfoSvg } from "assets/img/Checkout/icon_info.svg";
import { ReactComponent as Percent50Svg } from "assets/img/Checkout/icon_50_percent.svg";
import { ReactComponent as Percent70Svg } from "assets/img/Checkout/icon_70_percent.svg";
import { ReactComponent as SFCESvg } from "assets/img/Checkout/icon_SFCE.svg";
import { ReactComponent as McsSvg } from "assets/img/Checkout/icon_mcs.svg";
import { ReactComponent as TickSvg } from "assets/img/Checkout/icon_tick.svg";

import TooltipRight from "../../../components/LearnerPortal/TooltipRight";
import CourseConsultantCode from "../../../components/CourseConsultantCode";

import SignatureCanvas from "react-signature-canvas";

import { toast } from "react-toastify";
import { useSessionStorageState } from "ahooks";
import { useCheckout } from "hooks/selectors/useCheckout";
import {
	GTMCheckoutCompleted,
	GTMCheckoutCourseEndorseSuccess,
	GTMCheckoutMICFFButton,
	GTMCheckoutMISFCButton,
	GTMCheckoutNoSFCButton,
	GTMCheckoutPaynowPaymentFailed,
	GTMCheckoutPaynowPaymentSuccess,
	GTMCheckoutSFCPaymentFailed,
	GTMCheckoutSFCPaymentSuccess,
	GTMCheckoutStep2Endorse,
	GTMCheckoutStep2EndorseExit,
	GTMCheckoutStep2GoPaynow,
	GTMCheckoutStep2GoSuccess,
	GTMCheckoutUpdateOnlineRegPaymentSignature,
	GTMCheckoutUpdateOnlineRegPaymentSignatureFailed,
	GTMCheckoutUpdateOnlineRegPaymentSignatureSuccess,
	GTMCheckoutUpdateOnlineRegProgress,
	GTMCheckoutUpdateOnlineRegProgressFailed,
	GTMCheckoutUpdateOnlineRegProgressSuccess,
	GTMCheckoutYesSFCButton,
	GTMCheckoutFacebookCheckoutCompleted,
	GTMCheckoutGoogleAdsCheckoutCompleted,
} from "utilities/googleTagManager";
import { useAuth } from "hooks/selectors/useAuth";
import { getUrlParams } from "views/pages/checkout/CheckoutPage";

function maskNRIC(w) {
	return w.substring(0, 1) + "*".repeat(4) + w.substring(5, 9);
}

function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (pair[0] == variable) {
			return pair[1];
		}
	}
	return false;
}

export function CheckoutCourseSummaryPage() {
	const WEB_URL = process.env.REACT_APP_WEB_URL;
	const location_state = useLocation();
	const dispatch = useDispatch();
	const [message, setMessage] = useState("");
	const params = useParams();
	const [loading, setLoading] = useState(false);
	const MySwal = withReactContent(Swal);
	const [accordionOpen, setAccordionOpen] = useState(false);
	const [isSFCUtilised, setisSFCUtilised] = useState(0);
	const [disabled, setDisabled] = useState(false);
	const { checkoutAnalyticsData, courserun_id } = useCheckout();
	const [ccCode, setccCode] = useState(
		checkoutAnalyticsData ? (checkoutAnalyticsData.CCC ? checkoutAnalyticsData.CCC : "") : "",
	);
	const [hideSFC, setHideSFC] = useState(false);
	const [isFirstTime, setIsFirstTime] = useState(true);
	const { userData } = useAuth();
	const [searchParams] = useSearchParams();

	// coupon
	const [coupon, setCoupon] = React.useState("");
	const [isCouponLoading, setIsCouponLoading] = React.useState(false);
	const [coupon_amount, setCouponAmount] = React.useState("$0.00");
	const [coupon_id, setCouponID] = React.useState(null);
	const [coupon_data, setCouponData] = React.useState(null);
	const handleCouponChange = (e) => {
		setCoupon(e.target.value);
	};
	
	const state = location_state.state || { payment_status: true };

	const { isSfcUnderMaintenance } = useOutletContext();

	const updateStateInLocation = () => {
		// setPaymentStatus(true);
		navigate(".", { state: { payment_status: true }, replace: true });
		console.log("changestatus : " + state.payment_status);
	};

	const handleNoSFC = () => {
		setisSFCUtilised(0);
		setHideSFC(true);
		GTMCheckoutNoSFCButton(checkoutAnalyticsData, userData);
	};

	const toggleAccordion = () => {
		setAccordionOpen(!accordionOpen);
	};

	let logos = [defaultLogo];

	const [paymentmethod, setPaymentmethod] = useState("paynow");

	const checkboxTNC = useRef(null);
	const checkbox1 = useRef(null);
	const checkbox2 = useRef(null);
	const checkbox3 = useRef(null);
	const checkbox4 = useRef(null);
	const checkbox5 = useRef(null);
	const checkbox6 = useRef(null);

	function selectPaymentmethod(event) {
		const selectedMethod = event.currentTarget.dataset.method;
		setPaymentmethod(selectedMethod);
		console.log(window.OmiseCard);
	}
	const canvasRef = useRef(null);

	const handleSFCClick = () => {
		const data = {};
		// data.callback_url = "http://localhost:3000/checkout/course";
		data.callback_url = process.env.REACT_APP_WEB_URL + "/checkout/course";
		// data.course_id = "TGS-2020002140";
		data.course_id = courseData.course_ref;
		// data.course_fee = courseSubsidyData ? courseSubsidyData.price : 0;
		data.course_fee = sfcEligibleAmount?sfcEligibleAmount.toFixed(2):(courseSubsidyData ? courseSubsidyData.price : 0);
		// data.courserun_id = "230041";
		data.courserun_id = courseRegisterData.courserun.courserun_apicourserunid;
		data.courserun_start_date = courseRegisterData.courserun.courserun_course_from;
		// data.nric = "S6005040F";
		data.nric = courseRegisterData.learners.learner_nric;
		data.email = courseRegisterData.learners.learner_email;
		data.mobile = courseRegisterData.learners.learner_mobile;
		data.tms_regform_id = courseRegisterData.registrationform_id;
		data.tms_course_id = courseRegisterData.course.course_id;
		data.tms_courserun_id = courseRegisterData.courserun_id;
		data.tms_learner_id = courseRegisterData.learners.id;
		data.tms_learnermaster_id = courseRegisterData.learners.learner_learnermaster_id;
		// console.log(data);
		const json_str = btoa(JSON.stringify(data));

		// console.log(json_str);

		setLoading(true);
		GTMCheckoutYesSFCButton(checkoutAnalyticsData, userData);
		window.location.href = process.env.REACT_APP_API_URL + "/api/sfc_pay?encoded=" + json_str;
		// window.location.href = "http://fca-tms-2.test/api/sfc_pay?encoded=" + json_str;

		// generalApi.sfcPay({encoded: json_str})
		// 	.then((d) => {
		// 		setLoading(false);

		// 	})
		// 	.catch((error) => {
		// 		// console.log(error);
		// 		setLoading(false);
		// 	});
	};

	const handleConfirmCourse = () => {
		if (!checkboxTNC.current.checked || !checkbox1.current.checked || !checkbox2.current.checked || !checkbox3.current.checked || !checkbox4.current.checked || !checkbox5.current.checked || ((courseRegisterData.learners.learner_qualificationlevel == "Primary Qualification (e.g PSLE) or Below" || courseRegisterData.learners.learner_qualificationlevel == "Lower Secondary (Secondary Education without O level/N level pass)") && !checkbox6.current.checked)) {
			MySwal.fire({
				title: "",
				html: "Please take a moment to read and accept the terms and conditions to proceed.",
				icon: "warning",
				// icon: "warning",
				// didOpen: () => {
				// 	// `MySwal` is a subclass of `Swal` with all the same instance & static methods
				// 	MySwal.showLoading();
				// },
			});
			// return setMessage("Please agree to the terms & conditions");
		} else {
			if (canvasRef.current.isEmpty()) {
				// setMessage("Please Sign to Endorse");
				MySwal.fire({
					title: "",
					html: "Please sign before continue",
					icon: "error",
					// icon: "warning",
					// didOpen: () => {
					// 	// `MySwal` is a subclass of `Swal` with all the same instance & static methods
					// 	MySwal.showLoading();
					// },
				});
			} else {

				if(!checkSubsidyDone){
					MySwal.fire({
						title: "",
						html: "Still loading the subsidy information. Please wait a moment.",
						icon: "warning",
					});
					return;
				}

				if (Number(nettFeePayableAmount - sfcClaimedAmount) <= 0) {
					updateOnlineRegistrationPaymentSignature();
				} else {
					const dataUrl = canvasRef.current.toDataURL("image/png");
					const data_course_consultant_code = ccCode;

					dispatch(
						saveCheckoutAnalyticsData({
							course_id: checkoutAnalyticsData.course_id,
							course_title: checkoutAnalyticsData.course_title,
							courserun_id: checkoutAnalyticsData.courserun_id,
							referral: checkoutAnalyticsData.referral,
							// invite: checkoutAnalyticsData.invite,
							campaign_type: checkoutAnalyticsData.campaign_type,
							utm_campaign: checkoutAnalyticsData.utm_campaign,
							utm_content: checkoutAnalyticsData.utm_content,
							utm_medium: checkoutAnalyticsData.utm_medium,
							utm_source: checkoutAnalyticsData.utm_source,
							utm_term: checkoutAnalyticsData.utm_term,
							url: checkoutAnalyticsData.url,
							CCC:
								data_course_consultant_code !== null || data_course_consultant_code !== ""
									? data_course_consultant_code
									: null,
							course_price_value:	Number(nettFeePayableAmount - sfcClaimedAmount)

						}),
					);

					if (paymentmethod == "creditcard") {
						const omisePublicKeyUAT = process.env.REACT_APP_OMISE_PKEY_UAT;
						const omisePublicKeyPROD = process.env.REACT_APP_OMISE_PKEY_PROD;

						const currentUrl = window.location.href;

						if (currentUrl.includes("learn.fca.edu.sg")) {
							// production to load production pkey
							window.OmiseCard.configure({
								publicKey: omisePublicKeyPROD,
							});
						} else {
							// uat / development to load uat pkey
							window.OmiseCard.configure({
								publicKey: omisePublicKeyUAT,
							});
						}

						const data = [];
						data.omiseToken = "";
						data.omiseSource = "";
						data.regform_id = courseRegisterData.registrationform_id;
						// data.payment_amount = "50";
						data.payment_amount = nettFeePayableAmount - sfcClaimedAmount;
						data.payment_method = "omise_credit_card";
						data.signature = dataUrl;
						data.course_consultant_code = data_course_consultant_code;

						if (coupon_id) {
							const courseArray = Object.keys(coupon_data.eligibleCourses);
							const concatenatedCourseString = courseArray.join(",");
							data.coupon_id = coupon_id;
							data.learnermaster_id = userData.user.learnermaster_id;
							data.learner_id = coupon_data.learner_id;
							data.learner_nricmask = coupon_data.learner_nricmask;
							data.learner_dob = coupon_data.learner_dob;
							data.amount_waived = coupon_data.total_waived_reg_fee;
							data.course_count = 1;
							data.course_waived = concatenatedCourseString;
							data.current_coupon_settings = JSON.stringify(coupon_data.coupon_info);
						}

						setLoading(true);
						generalApi
							.CreditCardForm(data)
							.then((d) => {
								setLoading(false);
								if (d.status == 1) {
									window.OmiseCard.open({
										amount: d.data.payment_amount,
										currency: "SGD",
										defaultPaymentMethod: "credit_card",
										onCreateTokenSuccess: (nonce) => {
											const data = [];
											data.omiseToken = "";
											data.omiseSource = "";
											data.payment_id = d.data.payment_id;
											data.payment_mode = "omise_credit_card";
											data.payment_amount = d.data.payment_amount;

											if (nonce.startsWith("tokn_")) {
												data.omiseToken = nonce;
											} else {
												data.omiseSource = nonce;
											}
											console.log("createOmiseCharge", data);
											generalApi
												.createOmiseCharge(data)
												.then((d) => {
													if (d.status == 2) {
														const newSearchParam = [];
														for(let entry of searchParams.entries()) {
															newSearchParam.push(entry);
														}
														if (d.url.includes("?")) {
															window.location.href = d.url + "&" + createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString();
														} else {
															window.location.href = d.url + "?" + createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString();
														}
													}
												})
												.catch((error) => {
													// console.log(error);
													setLoading(false);
												});
										},
									});
								}
							})
							.catch((error) => {
								// console.log(error);
								setLoading(false);
							});
					} else if (paymentmethod == "paynow") {
						const data = [];
						data.omiseToken = "";
						data.omiseSource = "";
						data.regform_id = courseRegisterData.registrationform_id;
						// data.payment_amount = "50";
						data.payment_amount = nettFeePayableAmount - sfcClaimedAmount;
						data.payment_method = "omise_paynow";
						data.signature = dataUrl;
						data.course_consultant_code = data_course_consultant_code;

						if (coupon_id) {
							const courseArray = Object.keys(coupon_data.eligibleCourses);
							const concatenatedCourseString = courseArray.join(",");
							data.coupon_id = coupon_id;
							data.learnermaster_id = userData.user.learnermaster_id;
							data.learner_id = coupon_data.learner_id;
							data.learner_nricmask = coupon_data.learner_nricmask;
							data.learner_dob = coupon_data.learner_dob;
							data.amount_waived = coupon_data.total_waived_reg_fee;
							data.course_count = 1;
							data.course_waived = concatenatedCourseString;
							data.current_coupon_settings = JSON.stringify(coupon_data.coupon_info);
						}
						generalApi
							.PaynowForm(data)
							.then((d) => {
								const newSearchParam = [];
								for(let entry of searchParams.entries()) {
									newSearchParam.push(entry);
								}
								GTMCheckoutStep2GoPaynow(checkoutAnalyticsData, userData);
								navigate({
									pathname: "/checkout/omise/paynow",
									search: createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString(),
								},{
									state: {
										payment_id: d.data.payment_id,
										payment_paynow_qr: d.data.payment_paynow_qr,
									}
								});
							})
							.catch((error) => {
								// console.log(error);
								setLoading(false);
							});
					}

					// dispatch(
					// 	saveRegisterForm({
					// 		signature: dataUrl,
					// 	}),
					// );
					// navigate("/course/confirm");
				}
			}
		}
	};

	const courseRegisterData = useSelector((state) => state.courseRegisterSlice);
	const [courseSubsidyData, setCourseSubsidyData] = useState([]);

	const courseData = courseRegisterData.course;
	const courserunData = courseRegisterData.courserun;
	let navigate = useNavigate();

	courseData.logos ? courseData.logos.map((logo, i) => (logos[i] = logo.course_logo_image)) : [];
	let courseTitle = courseData.course_title;
	let courseId = courseData.course_ref;
	let location = courserunData.venue
		? courserunData.venue.venue_room + " (" + courserunData.venue.venue_address + ")"
		: "";
	let courseDate =
		moment(courserunData.courserun_course_from).format("DD MMM YYYY, ddd") +
		"- " +
		moment(courserunData.courserun_course_to).format("DD MMM YYYY, ddd");
	let courseTimings = courserunData.csessions_string;
	let learningMode = courserunData.courserun_modeoftraining;
	let studentSupportName = courserunData.training_support
		? courserunData.training_support.user_name
		: "-";
	let studentSupportContact = courserunData.training_support
		? courserunData.training_support.user_mobile
		: "-";
	let trainerImage = courserunData.trainer ? courserunData.trainer.trainer_image_url : "";
	let trainerName =
		courserunData.trainer && courserunData.trainer.trainer_name == "TBA"
			? "To Be Confirmed"
			: courserunData.trainer
				? courserunData.trainer.trainer_name
				: "";
	let trainerDesc = "ACTA/ACLP Certified";

	let encryptedPayload;
	let decyptedPayload, decyptedPayload_data;
	const [checkSubsidyDone, setCheckSubsidyDone] = useState(false);
	const [sfcClaimedAmount, setSfcClaimedAmount] = useState(0);
	const [nettFeePayableAmount, setNettFeePayableAmount] = useState(0);
	const [sfcEligibleAmount, setSfcEligibleAmount] = useState(0);
	let isSingpass = courseRegisterData.learners.fill_with_singpass;
	let age =
		Number(new Date().getFullYear()) -
		Number(courseRegisterData.learners.learner_dob.substring(0, 4));

	useEffect(() => {
		if (
			Object.keys(courseRegisterData).length !== 0 &&
			Object.keys(courseData).length !== 0 &&
			Object.keys(courserunData).length !== 0
		) {
			let requestData = {
				course_id: courseData.course_id,
				courserun_id: courserunData.courserun_id,
				learner_dob: courseRegisterData.learners.learner_dob,
				learner_nationality: courseRegisterData.learners.learner_nationality,
				registrationform_sme: courseRegisterData.registerForm.registrationform_sme,
				registrationform_sponsoredbycompany:
					courseRegisterData.registerForm.registrationform_sponsoredbycompany,
			};

			setCheckSubsidyDone(false);
			generalApi.checkSubsidy(requestData).then(
				(response) => {
					console.log(response);
					if (response.status == "Success") {
						setCheckSubsidyDone(true);
						setCourseSubsidyData(response.data);
						setNettFeePayableAmount(response.data.price);
						setSfcEligibleAmount(response.data.sfc_eligible_amount);
					}
				},
				(error) => {
					setMessage(getErrorMessage(error));
				},
			);


			// console.log(getQueryVariable("encryptedPayload"));
			encryptedPayload = getQueryVariable("encryptedPayload") || courseRegisterData.sfcEncryptedPayload;
			console.log(encryptedPayload);

			if (encryptedPayload) {
				decyptedPayload = atob(decodeURIComponent(encryptedPayload));
				decyptedPayload_data = JSON.parse(decyptedPayload);
				console.log(decyptedPayload_data);
				if (
					decyptedPayload_data.data.claim_id &&
					decyptedPayload_data.data.claim_amount &&
					decyptedPayload_data.data.tms_claim_id
				) {
					setisSFCUtilised(1);
					setSfcClaimedAmount(decyptedPayload_data.data.claim_amount);

					const element = document.getElementById("course_fees_breakdown");
					if (element) {
						element.scrollIntoView({ behavior: "smooth" });
					}
					GTMCheckoutSFCPaymentSuccess(checkoutAnalyticsData, userData);
					dispatch(saveSFCEncryptedPayload(encryptedPayload));
					toast.success("You SkillsFuture Credits has been utilised successfully!");
				}
			}

			let msg = getQueryVariable("msg");
			if (msg) {
				GTMCheckoutSFCPaymentFailed(checkoutAnalyticsData, userData);
				toast.error(decodeURI(msg));
			}
		}
		
	}, []);

	useEffect(() => {
		if (checkoutAnalyticsData && checkoutAnalyticsData.course_id !== null && isFirstTime) {
			GTMCheckoutStep2Endorse(checkoutAnalyticsData, userData);
			setIsFirstTime(false);
		}
		if (checkoutAnalyticsData && checkoutAnalyticsData.CCC) {
			setccCode(checkoutAnalyticsData.CCC);
		} else {
			setccCode("");
		}
		// analytics for close tab
		const handleTabClosing = () => {
			if (checkoutAnalyticsData && checkoutAnalyticsData.course_id !== null) {
				GTMCheckoutStep2EndorseExit(checkoutAnalyticsData, userData);
			}
		};

		window.addEventListener("unload", handleTabClosing);
		return () => {
			window.removeEventListener("unload", handleTabClosing);
		};
	}, [checkoutAnalyticsData, userData]);

	const handleCouponApplyClick = () => {
		if (coupon !== null && coupon !== "") {
			setIsCouponLoading(true);
			let requestData = {
				course_id: courseData.course_id,
				courserun_id: courserunData.courserun_id,
				learner_id: courseRegisterData.learners.learner_id,
				learner_dob: courseRegisterData.learners.learner_dob,
				learner_nationality: courseRegisterData.learners.learner_nationality,
				registrationform_sme: courseRegisterData.registerForm.registrationform_sme,
				registrationform_sponsoredbycompany:
					courseRegisterData.registerForm.registrationform_sponsoredbycompany,
				registrationform_id: courseRegisterData.registrationform_id,
				coupon_code: coupon,
				learner_nric: courseRegisterData.learners.learner_nric,
			};
			generalApi.checkCoupon(requestData).then(
				(response) => {
					if (response.data.status == "Success") {
						console.log(response.data);
						const waived_amount = "-$" + response.data.data.coupon_data.total_waived_reg_fee_wo_gst;
						setIsCouponLoading(false);
						setCouponAmount(waived_amount);
						setCouponID(response.data.data.coupon_data.coupon_info.id);
						setCouponData(response.data.data.coupon_data);
						setCourseSubsidyData(response.data.data);
						setNettFeePayableAmount(response.data.data.price);
						setSfcEligibleAmount(response.data.data.sfc_eligible_amount);
						toast.success("Coupon applied successfully");
						// set button .coupon_button_remove to display flex if it exists
						document.querySelector(".coupon_button_remove").style.display = "flex";
						document.querySelector(".coupon_button_apply").style.display = "none";
						document.querySelector(".coupon_status_text").innerHTML = "Waived Administrative Fees";
						document.querySelector("#coupon-input").disabled = true;
					}
				},
				(error) => {
					setIsCouponLoading(false);
					// reset
					setCouponAmount("$"+0);
					setCouponID(null);
					setCouponData(null);
					setCoupon("");
					setCourseSubsidyData(error.response.data.coupon_data);
					setNettFeePayableAmount(error.response.data.coupon_data.price);
					setSfcEligibleAmount(error.response.data.coupon_data.sfc_eligible_amount);
					toast.error(getErrorMessage(error));
					document.querySelector(".coupon_button_remove").style.display = "none";
					document.querySelector(".coupon_button_apply").style.display = "flex";
					document.querySelector(".coupon_status_text").innerHTML = "";
					document.querySelector("#coupon-input").disabled = false;
				},
			);
		}
	};

	
	const handleCouponRemoveClick = () => {
		setIsCouponLoading(true);
		let requestData = {
			course_id: courseData.course_id,
			courserun_id: courserunData.courserun_id,
			learner_id: courseRegisterData.learners.learner_id,
			learner_dob: courseRegisterData.learners.learner_dob,
			learner_nationality: courseRegisterData.learners.learner_nationality,
			registrationform_sme: courseRegisterData.registerForm.registrationform_sme,
			registrationform_sponsoredbycompany:
				courseRegisterData.registerForm.registrationform_sponsoredbycompany,
			registrationform_id: courseRegisterData.registrationform_id,
			learner_nric: courseRegisterData.learners.learner_nric,
		};
		generalApi.removeCoupon(requestData).then(
			(response) => {
				if (response.data.status == "Success") {
					console.log(response.data);
					setIsCouponLoading(false);
					setCouponAmount("$"+0);
					setCouponID("");
					setCouponData("");
					setCourseSubsidyData(response.data.data);
					setNettFeePayableAmount(response.data.data.price);
					setSfcEligibleAmount(response.data.data.sfc_eligible_amount);
					toast.success("Coupon removed");
					document.querySelector(".coupon_button_remove").style.display = "none";
					document.querySelector(".coupon_button_apply").style.display = "flex";
					document.querySelector(".coupon_status_text").innerHTML = "";
					document.querySelector("#coupon-input").disabled = false;
					
				}
			},
			(error) => {
				setIsCouponLoading(false);
				// reset
				setCouponAmount("$"+0);
				setCouponID(null);
				setCouponData(null);
				setCoupon("");
				setCourseSubsidyData(error.response.data.coupon_data);
				setNettFeePayableAmount(error.response.data.coupon_data.price);
				setSfcEligibleAmount(error.response.data.coupon_data.sfc_eligible_amount);
				toast.error(getErrorMessage(error));
				document.querySelector(".coupon_button_remove").style.display = "none";
				document.querySelector(".coupon_button_apply").style.display = "flex";
				document.querySelector(".coupon_status_text").innerHTML = "";
				document.querySelector("#coupon-input").disabled = false;
			},
		);
	};

	const updateOnlineRegistrationPaymentSignature = () => {
		setDisabled(true);
		setLoading(true);
		let requestData = {
			registrationform_id: courseRegisterData.registrationform_id,
			signature: canvasRef.current.toDataURL("image/png"),
			course_consultant_code: ccCode,
		};
		GTMCheckoutUpdateOnlineRegPaymentSignature(checkoutAnalyticsData, userData);
		generalApi.updateOnlineRegistrationPaymentSignature(requestData).then(
			(response) => {
				console.log(response);
				if (response.status == "Success") {
					GTMCheckoutUpdateOnlineRegPaymentSignatureSuccess(checkoutAnalyticsData, userData);
					updateOnlineRegistrationProgress();
				}
			},
			(error) => {
				GTMCheckoutUpdateOnlineRegPaymentSignatureFailed(checkoutAnalyticsData, userData);
				setMessage(getErrorMessage(error));
			},
		);
	};

	const updateOnlineRegistrationProgress = () => {
		let requestData = {
			registrationform_id: courseRegisterData.registrationform_id,
			registrationform_progress: "ENDORSED",
		};
		GTMCheckoutUpdateOnlineRegProgress(checkoutAnalyticsData, userData);
		generalApi.updateOnlineRegistrationProgress(requestData).then(
			(response) => {
				console.log(response);
				if (response.status == "Success") {
					GTMCheckoutUpdateOnlineRegProgressSuccess(checkoutAnalyticsData, userData);
					registerSuccess();
				}
			},
			(error) => {
				GTMCheckoutUpdateOnlineRegProgressFailed(checkoutAnalyticsData, userData);
				setMessage(getErrorMessage(error));
			},
		);
		registerSuccess();

	};

	const registerSuccess = () => {
		let requestData = {
			registrationform_id: courseRegisterData.registrationform_id,
		};

		if (checkoutAnalyticsData && checkoutAnalyticsData.campaign_type) {
			requestData.lead_type = checkoutAnalyticsData.campaign_type;
		}
		
		if (coupon_id) {
			const courseArray = Object.keys(coupon_data.eligibleCourses);
			const concatenatedCourseString = courseArray.join(",");
			requestData.coupon_id = coupon_id;
			requestData.learnermaster_id = userData.user.learnermaster_id;
			requestData.learner_id = coupon_data.learner_id;
			requestData.learner_nricmask = coupon_data.learner_nricmask;
			requestData.learner_dob = coupon_data.learner_dob;
			requestData.amount_waived = coupon_data.total_waived_reg_fee;
			requestData.course_count = 1;
			requestData.course_waived = concatenatedCourseString;
			requestData.current_coupon_settings = JSON.stringify(coupon_data.coupon_info);
		}
		
		generalApi.registerSuccess(requestData).then(
			(response) => {
				console.log(response);
				if (response.status == "Success") {
					setLoading(false);
					requestData.course_id = checkoutAnalyticsData && checkoutAnalyticsData.course_id;
					requestData.courserun_id = checkoutAnalyticsData && checkoutAnalyticsData.courserun_id;
					requestData.campaign_type = checkoutAnalyticsData && checkoutAnalyticsData.campaign_type;
					requestData.referral = checkoutAnalyticsData && checkoutAnalyticsData.referral;
					// requestData.invite = checkoutAnalyticsData && checkoutAnalyticsData.invite;
					requestData.utm_campaign = checkoutAnalyticsData && checkoutAnalyticsData.utm_campaign;
					requestData.utm_content = checkoutAnalyticsData && checkoutAnalyticsData.utm_content;
					requestData.utm_medium = checkoutAnalyticsData && checkoutAnalyticsData.utm_medium;
					requestData.utm_source = checkoutAnalyticsData && checkoutAnalyticsData.utm_source;
					requestData.utm_term = checkoutAnalyticsData && checkoutAnalyticsData.utm_term;
					requestData.url = checkoutAnalyticsData && checkoutAnalyticsData.url;

					requestData.ccc =
						checkoutAnalyticsData && checkoutAnalyticsData.CCC ? checkoutAnalyticsData.CCC : null;

					generalApi
						.SubmitBigBangPurchasedConversionLog(requestData)
						.then(
							(response) => {},
							(error) => {},
						)
						.catch(() => {});
					// GTMCheckoutCourseEndorseSuccess(checkoutAnalyticsData, userData);
					GTMCheckoutCompleted(checkoutAnalyticsData, userData);
					if (checkoutAnalyticsData && checkoutAnalyticsData.campaign_type && checkoutAnalyticsData.campaign_type.toUpperCase() === "STELLAR") {
						GTMCheckoutFacebookCheckoutCompleted(checkoutAnalyticsData, userData);
					}
					if (checkoutAnalyticsData && checkoutAnalyticsData.campaign_type && checkoutAnalyticsData.campaign_type.toUpperCase() === "GRAVITY") {
						GTMCheckoutGoogleAdsCheckoutCompleted(checkoutAnalyticsData, userData);
					}
					GTMCheckoutStep2GoSuccess(checkoutAnalyticsData, userData);
					setTimeout(() => {
						navigate("/checkout/success");
					}, 500);
				}
			},
			(error) => {
				setMessage(getErrorMessage(error));
			},
		);
	};

	return (
		<>
			<div className={styles.root}>
				{message && (
					<div className='form-group'>
						<div className='alert alert-danger' role='alert'>
							{message}
						</div>
					</div>
				)}

				<Row>
					<Col>
						<div className={"fs40 font-weight-bold text-color-dark"}>
							Welcome,{" "}
							<span className={styles.text_color_green}>
								{courseRegisterData.learners.learner_name}
							</span>
						</div>
					</Col>
				</Row>

				<Row className='pt-4 flex align-items-center mb-3'>
					<div>
						<LearnerSvg className='ml-2' style={{ width: "60px" }} />
					</div>
					<div className={"font-weight-bold fs22 ml-2 text-color-dark"}>Learner Information</div>
				</Row>

				<Row>
					<Col>
						<div
							className='pt-3 pb-3 learner-detail-div'
							style={{
								borderTopColor: "rgb(255, 189, 67)",
								paddingLeft: "2rem",
								paddingRight: "2rem",
							}}>
							<Row className='pt-2  '>
								<Col lg='4' xl='3'>
									<div className='font-weight-bold text-color-dark $fs15'>
										Full Name (Shown in NRIC)
									</div>
									<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
										{courseRegisterData.learners.learner_name}
									</div>
								</Col>
								<Col lg='4' xl='3'>
									<div className='font-weight-bold text-color-dark $fs15'>NRIC or ID No.</div>
									<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
										{maskNRIC(courseRegisterData.learners.learner_nricmask)}
									</div>
								</Col>
								<Col lg='4' xl='3'>
									<div className='font-weight-bold text-color-dark $fs15'>Date of Birth</div>
									<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
										{moment(courseRegisterData.learners.learner_dob).format("DD/MM/YYYY")}
									</div>
								</Col>
								<Col lg='4' xl='3'>
									<div className='font-weight-bold text-color-dark $fs15'>Nationality</div>
									<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
										{courseRegisterData.learners.learner_nationality}
									</div>
								</Col>
							</Row>

							<Row>
								<Col lg='4' xl='3'>
									<div className='font-weight-bold text-color-dark $fs15'>Email Address</div>
									<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
										{courseRegisterData.learners.learner_email}
									</div>
								</Col>
								<Col lg='4' xl='3'>
									<div className='font-weight-bold text-color-dark $fs15'>Mobile No.</div>
									<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
										{courseRegisterData.learners.learner_mobile}
									</div>
								</Col>
								<Col lg='4' xl='3'>
									<div className='font-weight-bold text-color-dark $fs15'>Gender</div>
									<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
										{courseRegisterData.learners.learner_gender}
									</div>
								</Col>
								<Col lg='4' xl='3'>
									<div className='font-weight-bold text-color-dark $fs15'>Address 1</div>
									<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
										{courseRegisterData.learners.learner_block +
											" " +
											courseRegisterData.learners.learner_street}
									</div>
								</Col>
							</Row>

							<Row>
								<Col lg='4' xl='3'>
									<div className='font-weight-bold text-color-dark $fs15'>Address 2</div>
									<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
										{courseRegisterData.learners.learner_building}
									</div>
								</Col>
								<Col lg='4' xl='3'>
									<div className='font-weight-bold text-color-dark $fs15'>Unit No.</div>
									<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
										{courseRegisterData.learners.learner_floor +
											"-" +
											courseRegisterData.learners.learner_unit}
									</div>
								</Col>
								<Col lg='4' xl='3'>
									<div className='font-weight-bold text-color-dark $fs15'>Postal Code</div>
									<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
										{courseRegisterData.learners.learner_postal}
									</div>
								</Col>
								<Col lg='4' xl='3'>
									<div className='font-weight-bold text-color-dark $fs15'>Country</div>
									<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
										{courseRegisterData.learners.learner_country}
									</div>
								</Col>
							</Row>

							<Row>
								<Col lg='4' xl='3'>
									<div className='font-weight-bold text-color-dark $fs15'>
										Educational Qualifications
									</div>
									<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
										{courseRegisterData.learners.learner_qualificationlevel}
									</div>
								</Col>
								<Col lg='4' xl='3'>
									<div className='font-weight-bold text-color-dark $fs15'>Occupation</div>
									<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
										{courseRegisterData.learners.learner_designation}
									</div>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>

				<Row className='pt-4 flex align-items-center mb-3'>
					<div>
						<CourseSvg className='ml-2' style={{ width: "60px" }} />
					</div>
					<div className={"font-weight-bold fs22 ml-2 text-color-dark"}>Course Details</div>
				</Row>

				<Row>
					<Col>
						<div
							className='pt-3 pb-3 learner-detail-div'
							style={{
								borderTopColor: "rgb(0, 133, 202)",
								paddingLeft: "2rem",
								paddingRight: "2rem",
							}}>
							<CourseDetails
								logos={logos}
								courseTitle={courseTitle}
								courseId={courseId}
								location={location}
								courseDate={courseDate}
								courseTimings={courseTimings}
								learningMode={learningMode}
								studentSupportName={studentSupportName}
								studentSupportContact={studentSupportContact}
								trainerImage={trainerImage}
								trainerName={trainerName}
								trainerDesc={trainerDesc}
							/>
						</div>
					</Col>
				</Row>
			</div>


			<div className={styles.root}>
				{courseSubsidyData && courseSubsidyData.skillfeature_percent > 0 ? (
					<div className='fs30 font-weight-bold'>
						Based on your {isSingpass ? "Singpass" : "information"}, you are{" "}
						<span className={styles.text_color_green}>{age} years of age</span>, and eligible for
						the subsidies shown below.
					</div>
				) : (
					""
				)}

				<Row className='pt-4 flex align-items-center mb-3'>
					<div>
						<SFCSvg className='ml-2' style={{ width: "60px" }} />
					</div>
					<div className={"font-weight-bold fs22 ml-2 text-color-dark"}>SkillsFuture Subsidy</div>
				</Row>

				{
					!checkSubsidyDone ? (
						<div className='d-flex justify-content-center align-items-center'>
							<Spinner animation='border' role='status'>
							</Spinner>
							<span className="ml-2"> Loading Subsidy...</span>
						</div>
					) : (
						<Row>
							<Col className='col-12'>
								<div
									className='p-3 learner-detail-div'
									style={{
										borderTopWidth: "2px",
										borderTopStyle: "solid",
										borderTopColor: "rgb(0, 171, 132)",
									}}>
									<Row className='mt-2 mb-3'>
										<Col className='col-12 fs18'>Here are the subsidies you are eligible for:</Col>
									</Row>
									<Row className='mb-4'>
										<Col className='col-6 col-sm-6 col-md-3 col-lg-3 mb-2'>
											<div
												className={
													courseSubsidyData && courseSubsidyData.skillfeature_percent == 50
														? styles.box_active1
														: styles.box_inactive
												}>
												<Percent50Svg className={"w-100 h-100"} />
											</div>
											<div className={`${styles.info_icon_container}`}>
												<svg
													id='outlineTooltip'
													xmlns='http://www.w3.org/2000/svg'
													width='16'
													height='16'
													viewBox='0 0 16 16'
													style={{
														height: "100%",
														width: "100%",
													}}>
													<path
														id='noun-information-1307781'
														d='M13,5a8,8,0,1,0,8,8A7.989,7.989,0,0,0,13,5Zm-.96,2.684a1.208,1.208,0,0,1,.871-.356A1.214,1.214,0,0,1,14.12,8.538a1.238,1.238,0,0,1-.356.871,1.168,1.168,0,0,1-.853.356,1.238,1.238,0,0,1-.871-.356,1.223,1.223,0,0,1,0-1.724ZM15.169,18.3H10.991V17.6h.462a.6.6,0,0,0,.516-.178.858.858,0,0,0,.16-.587V12.8a.739.739,0,0,0-.231-.587.836.836,0,0,0-.6-.213h-.3v-.676l3.093-.142v5.9a.509.509,0,0,0,.142.373.715.715,0,0,0,.462.124h.462l.018.711Z'
														transform='translate(-5 -5)'
														fill={
															courseSubsidyData && courseSubsidyData.skillfeature_percent == 50
																? "rgb(0, 133, 202)"
																: "#EEEEEE"
														}
													/>
												</svg>
												{courseSubsidyData && courseSubsidyData.skillfeature_percent == 50 ? (
													<TooltipRight
														placement='right'
														target='outlineTooltip'
														delay={{ hide: 2000 }}>
												Singaporeans and Permanent Residents aged 21 to 39 are eligible for 50% of
												Course Fee Funding for SSG-approved courses
													</TooltipRight>
												) : (
													""
												)}
											</div>
											{/* <div className={courseSubsidyData && courseSubsidyData.skillfeature_percent == 50 ? styles.gradient_box_active : styles.gradient_box_inactive}>
												<div>
													<div className="font-weight-bold text-uppercase">
														<div className={courseSubsidyData && courseSubsidyData.skillfeature_percent == 50 ? "" : styles.color_light_grey}>Singaporean / PR<br></br>21-39 years old</div>
													</div>
													<div className={courseSubsidyData && courseSubsidyData.skillfeature_percent == 50 ? styles.font1_active2 : styles.font1_inactive}>50%</div>
													<div className={courseSubsidyData && courseSubsidyData.skillfeature_percent == 50 ? styles.font2_active : styles.font2_inactive}>
														<span className={courseSubsidyData && courseSubsidyData.skillfeature_percent == 50 ? styles.color_light_green : styles.color_light_grey}>Skillsfuture</span><br></br> Subsidy
													</div>
												</div>
											</div> */}
										</Col>
										<Col className='col-6 col-sm-6 col-md-3 col-lg-3 mb-2'>
											<div
												className={
													courseSubsidyData && courseSubsidyData.skillfeature_percent == 70
														? styles.box_active2
														: styles.box_inactive
												}>
												<Percent70Svg className='w-100 h-100' />
											</div>
											<div className={`${styles.info_icon_container}`}>
												<svg
													id='outlineTooltip2'
													xmlns='http://www.w3.org/2000/svg'
													width='16'
													height='16'
													viewBox='0 0 16 16'
													style={{
														height: "100%",
														width: "100%",
													}}>
													<path
														id='noun-information-1307781'
														d='M13,5a8,8,0,1,0,8,8A7.989,7.989,0,0,0,13,5Zm-.96,2.684a1.208,1.208,0,0,1,.871-.356A1.214,1.214,0,0,1,14.12,8.538a1.238,1.238,0,0,1-.356.871,1.168,1.168,0,0,1-.853.356,1.238,1.238,0,0,1-.871-.356,1.223,1.223,0,0,1,0-1.724ZM15.169,18.3H10.991V17.6h.462a.6.6,0,0,0,.516-.178.858.858,0,0,0,.16-.587V12.8a.739.739,0,0,0-.231-.587.836.836,0,0,0-.6-.213h-.3v-.676l3.093-.142v5.9a.509.509,0,0,0,.142.373.715.715,0,0,0,.462.124h.462l.018.711Z'
														transform='translate(-5 -5)'
														fill={
															courseSubsidyData && courseSubsidyData.skillfeature_percent == 70
																? "rgb(0, 133, 202)"
																: "#EEEEEE"
														}
													/>
												</svg>
												{courseSubsidyData && courseSubsidyData.skillfeature_percent == 70 ? (
													<TooltipRight
														placement='right'
														target='outlineTooltip2'
														delay={{ hide: 2000 }}>
												Singaporeans aged 40 and above are eligible for 70% of Course Fee Funding
												for SSG-approved courses
													</TooltipRight>
												) : (
													""
												)}
											</div>
											{/* <div className={courseSubsidyData && courseSubsidyData.skillfeature_percent == 70 ? styles.gradient_box_active2 : styles.gradient_box_inactive}>
												<div>
													<div className="font-weight-bold text-uppercase">
														<div className={courseSubsidyData && courseSubsidyData.skillfeature_percent == 70 ? "" : styles.color_light_grey}>Singaporean<br></br>above 40 years old</div>
													</div>
													<div className={courseSubsidyData && courseSubsidyData.skillfeature_percent == 70 ? styles.font1_active : styles.font1_inactive}>70%</div>
													<div className={courseSubsidyData && courseSubsidyData.skillfeature_percent == 70 ? styles.font2_active : styles.font2_inactive}>
														<span className={courseSubsidyData && courseSubsidyData.skillfeature_percent == 70 ? styles.color_light_green : styles.color_light_grey}>Skillsfuture</span><br></br> Subsidy
													</div>
												</div>
											</div> */}
										</Col>
										<Col className='col-6 col-sm-6 col-md-3 col-lg-3 mb-2'>
											<div
												className={
													courseSubsidyData && courseSubsidyData.sfc_eligible_amount > 0
														? styles.box_active3
														: styles.box_inactive
												}>
												<SFCESvg className='w-100 h-100' />
											</div>
											<div className={`${styles.info_icon_container}`}>
												<svg
													id='outlineTooltip3'
													xmlns='http://www.w3.org/2000/svg'
													width='16'
													height='16'
													viewBox='0 0 16 16'
													style={{
														height: "100%",
														width: "100%",
													}}>
													<path
														id='noun-information-1307781'
														d='M13,5a8,8,0,1,0,8,8A7.989,7.989,0,0,0,13,5Zm-.96,2.684a1.208,1.208,0,0,1,.871-.356A1.214,1.214,0,0,1,14.12,8.538a1.238,1.238,0,0,1-.356.871,1.168,1.168,0,0,1-.853.356,1.238,1.238,0,0,1-.871-.356,1.223,1.223,0,0,1,0-1.724ZM15.169,18.3H10.991V17.6h.462a.6.6,0,0,0,.516-.178.858.858,0,0,0,.16-.587V12.8a.739.739,0,0,0-.231-.587.836.836,0,0,0-.6-.213h-.3v-.676l3.093-.142v5.9a.509.509,0,0,0,.142.373.715.715,0,0,0,.462.124h.462l.018.711Z'
														transform='translate(-5 -5)'
														fill={
															courseSubsidyData && courseSubsidyData.sfc_eligible_amount > 0
																? "rgb(0, 133, 202)"
																: "#EEEEEE"
														}
													/>
												</svg>
											</div>
											{courseSubsidyData && courseSubsidyData.sfc_eligible_amount > 0 ? (
												<TooltipRight placement='right' target='outlineTooltip3' delay={{ hide: 2000 }}>
											Singaporean aged 25 and above are provided with a credit of $500 or more, that
											can be utilised on SkillsFuture Credit eligible courses.
												</TooltipRight>
											) : (
												""
											)}
											{/* <div className={courseSubsidyData && courseSubsidyData.skillfeature_percent > 0 ? styles.gradient_box_active2 : styles.gradient_box_inactive}>
												<div>
													<div className={courseSubsidyData && courseSubsidyData.skillfeature_percent > 0 ? styles.font3_active : styles.font3_inactive}>
														<span className={courseSubsidyData && courseSubsidyData.skillfeature_percent > 0 ? styles.color_light_green : styles.color_light_grey}>Skillsfuture</span><br></br>Credits Eligible
													</div>
												</div>
											</div> */}
										</Col>
										<Col className='col-6 col-sm-6 col-md-3 col-lg-3 mb-2'>
											<div
												className={
													courseSubsidyData && courseSubsidyData.skillfeature_percent == 70
														? styles.box_active4
														: styles.box_inactive
												}>
												<McsSvg className='w-100 h-100' />
											</div>
											<div className={`${styles.info_icon_container}`}>
												<svg
													id='outlineTooltip4'
													xmlns='http://www.w3.org/2000/svg'
													width='16'
													height='16'
													viewBox='0 0 16 16'
													style={{
														height: "100%",
														width: "100%",
													}}>
													<path
														id='noun-information-1307781'
														d='M13,5a8,8,0,1,0,8,8A7.989,7.989,0,0,0,13,5Zm-.96,2.684a1.208,1.208,0,0,1,.871-.356A1.214,1.214,0,0,1,14.12,8.538a1.238,1.238,0,0,1-.356.871,1.168,1.168,0,0,1-.853.356,1.238,1.238,0,0,1-.871-.356,1.223,1.223,0,0,1,0-1.724ZM15.169,18.3H10.991V17.6h.462a.6.6,0,0,0,.516-.178.858.858,0,0,0,.16-.587V12.8a.739.739,0,0,0-.231-.587.836.836,0,0,0-.6-.213h-.3v-.676l3.093-.142v5.9a.509.509,0,0,0,.142.373.715.715,0,0,0,.462.124h.462l.018.711Z'
														transform='translate(-5 -5)'
														fill={
															courseSubsidyData && courseSubsidyData.skillfeature_percent == 70
																? "rgb(0, 133, 202)"
																: "#EEEEEE"
														}
													/>
												</svg>
											</div>
											{courseSubsidyData && courseSubsidyData.skillfeature_percent == 70 ? (
												<TooltipRight placement='right' target='outlineTooltip4' delay={{ hide: 2000 }}>
											Singaporeans who are aged 40 to 60 before 31 Dec 2020 are provided a one-off,
											time limited additional SkillsFuture Credit of $500 that can be used on
											eligible courses.
												</TooltipRight>
											) : (
												""
											)}
											{/* <div className={courseSubsidyData && courseSubsidyData.skillfeature_percent == 70 ? styles.gradient_box_active2 : styles.gradient_box_inactive}>
												<div>
													<div className="font-weight-bold text-uppercase">
														<div className={courseSubsidyData && courseSubsidyData.skillfeature_percent == 70 ? "" : styles.color_light_grey}>Additional<br></br>Skillsfuture Credit</div>
													</div>
													<div className={courseSubsidyData && courseSubsidyData.skillfeature_percent == 70 ? styles.font3_active : styles.font3_inactive}>
														<span className={courseSubsidyData && courseSubsidyData.skillfeature_percent == 70 ? styles.color_light_green : styles.color_light_grey}>Mid-Career<br></br>Support</span>
													</div>
												</div>
											</div> */}
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					)
				}

				<div className='pt-4 fs30 text-color-dark font-weight-bold mb-4'>Course Fees</div>
				<Row id='course_fees_breakdown' className='mt-2 mb-4'>
					<Col className='col-12'>
						<div
							className='p-3 pt-4'
							style={{ borderWidth: 1, borderStyle: "solid", borderColor: "rgb(0, 133, 202)" }}>
							<div className='font-weight-bold fs18 text-color-dark'>Courses Fees Breakdown</div>

							<Row className='pt-4'>
								<Col className='d-flex align-items-center fs15 text-color-dark'>
									Full Course Fees (Before GST)
								</Col>
								<Col className='col-auto text-right fs20 text-color-dark'>
									{courseSubsidyData && courseSubsidyData.full_course_price_txt}
								</Col>
							</Row>
							{courseSubsidyData && courseSubsidyData.regform_fee_txt ? (
								<>
									<Row className='pt-3'>
										<Col xs={12} md={10}>
											Registration Fee{" "}
											<span className={styles.info_txt}>
												<InfoSvg style={{ width: "16px", marginRight: "2px" }} /> Inclusive of
												course materials and meals.
											</span>
										</Col>
										<Col className='text-right '>
											{courseSubsidyData && courseSubsidyData.regform_fee_txt}
										</Col>
									</Row>
									<Row className='pt-3'>
										<Col>
											<div className='background-color1'>
												<span className='fs12 d-inline-block  small-desc'>
													An registration fee of $10 is not payable using SkillsFuture Credits.
												</span>
											</div>
										</Col>
									</Row>
								</>
							) : (
								""
							)}
							<div className='pb-1 pt-3 pl-3 pr-3'>
								<Row
									className={styles.coupon_row_1}>
									<Col>
										<div className='font-weight-bold mb-1 fs15'>
											{"Coupon Code"}
										</div>
										<div className={styles.coupon_input_button_container}>
											<input
												className={styles.coupon_input}
												id="coupon-input"
												placeholder="Enter Coupon Code"
												value={coupon}
												onChange={handleCouponChange}
											/>
											<button
												className={`${styles.coupon_button} coupon_button_apply`}
												onClick={handleCouponApplyClick}
												disabled={isCouponLoading}
											>
												{isCouponLoading && <span className='spinner-border spinner-border-sm'></span>}
												{!isCouponLoading && <span>{"Apply"}</span>}
											</button>
											<button
												className={`${styles.coupon_button_remove} coupon_button_remove`}
												onClick={handleCouponRemoveClick}
												disabled={isCouponLoading}
											>
												{isCouponLoading && <span className='spinner-border spinner-border-sm'></span>}
												{!isCouponLoading && <span>{"Remove"}</span>}
											</button>
										</div>
										<div className='fs12 text-color-dark coupon_status_text'>
										</div>
									</Col>
									<Col className={`col-auto pl-0 text-right fs20 font-weight-bold d-flex align-items-center ${styles.coupon_number_text}`}>
										{`${coupon_amount}`}
									</Col>
								</Row>
							</div>
							<Row className='pt-3'>
								<Col className='d-flex align-items-center fs15 text-color-dark'>
									{courseSubsidyData && courseSubsidyData.gst_percent_txt} GST (On Full Course Fees
									{courseSubsidyData.regform_fee_txt != "" ? " & Registration Fee)" : ")"}
								</Col>
								{/* <Col className='d-flex align-items-center fs15 text-color-dark'>
									{courseSubsidyData && courseSubsidyData.gst_percent_txt} GST (On Full Course Fees)
								</Col> */}
								<Col className='col-auto text-right fs20 text-color-dark'>
									{courseSubsidyData && courseSubsidyData.gst_txt}
								</Col>
							</Row>

							{courseSubsidyData && courseSubsidyData.skillfeature_percent > 0 ? (
								<div className='pb-2 pl-3 pr-3'>
									<Row
										className={
											courseSubsidyData && courseSubsidyData.skillfeature_percent == 70
												? styles.sf_subsidy_row_1
												: styles.sf_subsidy_row_2
										}>
										<Col>
											<div className='font-weight-bold mb-1 fs15'>
												SkillsFuture subsidy: {courseSubsidyData.skillfeature_percent}% on Full
												Course Fees
											</div>
											<div className='fs12 text-color-dark'>
												{courseSubsidyData && courseSubsidyData.skillfeature_percent == 50
													? "Singaporean & PR (Aged 21 to 39 years old)"
													: "Singaporean above 40 years old"}
											</div>
										</Col>
										<Col className='col-auto text-right fs20 font-weight-bold'>
											- {courseSubsidyData && courseSubsidyData.skillfeature_txt}
										</Col>
									</Row>
									{courseRegisterData.learners.learner_nationality == "Singapore Citizen" ? (
										!hideSFC ? (
											<>
												<Row className={styles.sfc_eligible_row}>
													<Col>
														<>
															<Row style={{ color: "#00AB84" }}>
																<Col>
																	<div className='font-weight-bold mb-1 fs15'>
																		{isSFCUtilised
																			? "Net Fees Payable after utilizing SkillsFuture credits:"
																			: "Net Fees Payable after subsidy:"}
																	</div>
																	<div className='fs12 text-color-dark'>
																		Subjected to availability of SkillsFuture Credits
																	</div>
																</Col>
																<Col className='col-auto text-right fs20 font-weight-bold'>
																	{isSFCUtilised ? (
																		<div
																			className={"text-right fs20 font-weight-bold mb-2"}
																			style={{ color: "#D7634A" }}>
																			-{"$" + Number(sfcClaimedAmount).toFixed(2)}
																		</div>
																	) : (
																		<div className={"text-right fs20 font-weight-bold mb-2"}>
																			{"$" + Number(sfcEligibleAmount).toFixed(2)}
																		</div>
																	)}
																	<div className='d-flex flex-row'>
																		<span className='ml-2'>
																			<InfoSvg style={{ width: "23px" }} />
																		</span>
																		{isSFCUtilised ? (
																			<span className={`${styles.sfc_eligible_utilised_txt} fs12 `}>
																				SkillsFuture Credits Utilised{" "}
																				<TickSvg className='ml-1' style={{ width: "16px" }} />
																			</span>
																		) : (
																			<span className={`${styles.sfc_eligible_txt} fs12`}>
																				SkillsFuture Credits Eligible{" "}
																				<ThumbsSvg className='ml-1' style={{ width: "23px" }} />
																			</span>
																		)}
																	</div>
																</Col>
															</Row>
															{!isSFCUtilised ? (
																<>
																	<hr style={{ margin: "1.5rem 0" }}></hr>
																	<Row>
																		<Col>
																			<div className='font-weight-bold fs15 text-color-dark'>
																				Do you want to use your SkillsFuture Credits as part of the
																				payment?
																			</div>
																			<div className='fs12 text-color-dark'>
																				You will be redirected to SkillsFuture and required to scan
																				your singpass
																			</div>
																		</Col>
																		<Col className={`text-right col-auto text-center ${styles.sfc_btn_wrap}`}>
																			<div className='mb-3'>
																				<button
																					className={`btn-gradient pt-3 pb-3 pl-4 pr-4 btn btn-secondary ${
																						isSfcUnderMaintenance ? styles.btn_disabled : ""
																					}`}
																					onClick={handleSFCClick}>
																					Yes
																				</button>
																				{isSfcUnderMaintenance && (
																					<div className='invalid-feedback d-inline-block'>
																						SkillsFuture site is currently under maintenance, please check back again later
																					</div>
																				)}
																			</div>
																			<div className={styles.no_thanks_txt} onClick={handleNoSFC}>
																				No, Thanks
																			</div>
																		</Col>
																	</Row>
																</>
															) : (
																""
															)}
														</>
													</Col>
												</Row>
											</>
										) : null
									) : (
										""
									)}
								</div>
							) : (
								""
							)}

							<hr className='hr-border2' style={{ margin: "1.5rem 0" }}></hr>

							<Row>
								<Col>
									<div className='font-weight-bold fs22 text-fca-blue'>
										Total Nett Course Fee Payable
									</div>
									<div className='fs12 text-color-dark'>
										(After SkillsFuture subsidy & credits deduction. Includes{" "}
										{courseSubsidyData && courseSubsidyData.gst_percent_txt} GST.)
									</div>
								</Col>
								<Col className='col-auto font-weight-bold text-right text-color2'>
									<div className='font-weight-bold text-color2 fs20'>
										{"$" + Number(nettFeePayableAmount - sfcClaimedAmount).toFixed(2)}
									</div>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</div>

			<div className={styles.root}>
				<div className='fs30 font-weight-bold mb-4 text-color-dark'>
					Endorsement {Number(nettFeePayableAmount - sfcClaimedAmount) > 0 ? "& Payment" : ""}
				</div>

				<div
					id='terms-and-conditions'
					onClick={toggleAccordion}
					className='cursor-pointer'
					style={{
						borderBottomWidth: "3px",
						borderBottomStyle: "solid",
						borderBottomColor: "rgb(207, 77, 134)",
					}}>
					<Row className='mb-3'>
						<Col className='col col-auto'>
							<TncSvg style={{ width: "60px" }} />
						</Col>
						<Col className='col d-flex align-items-center pl-0 font-weight-bold fs22 text-color-dark'>
							Terms & Conditions
						</Col>
						<Col className='col-auto ml-auto d-flex align-items-center'>
							{accordionOpen ? (
								<i className='fa fa-chevron-up'></i>
							) : (
								<i className='fa fa-chevron-down'></i>
							)}
						</Col>
					</Row>
				</div>

				<Collapse isOpen={accordionOpen}>
					<Row>
						<Col>
							<div
								className='p-3'
								style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#ddd" }}>
								<div>
									Thank you for choosing FirstCom Academy as your preferred training provider.
									<br></br>
									<br></br>
									Upon successful registration, our Training Support Executives will secure a seat
									for the class you have enrolled in. Arrangements will also be made to book our
									trainer, assessor, training room, course materials, food catering, and shuttle bus
									to prepare and welcome you for the class.
									<br></br>
									<br></br>
									Please refer to the important terms and conditions below.
									<br></br>
									<br></br>
								</div>
								<h4>Important Terms & Conditions</h4>
								<div className='font-weight-bold'>1. Course Requirements</div>
								<div className=''>
									Trainee(s) will need to fulfill at least 75% attendance and be marked competent
									during assessment to enjoy the Skillsfuture subsidized rates. In the event that
									either criteria is not met, trainee(s) are liable to pay for the full course and
									no certification and Statement of Attainment will be issued.
								</div>

								<div className='pt-4 font-weight-bold'>2. Rescheduling</div>
								<div className=''>
									Rescheduling for confirmed enrolments must be submitted via our online reschedule
									form (
									<a
										href={"https://falcomm.fca.edu.sg/"}
										target='_blank'
										rel='noreferrer'
										className={styles.green_hyperlink}>
										https://falcomm.fca.edu.sg/
									</a>
									) or email us at (
									<a
										href='mailto:help@fca.edu.sg'
										target='_blank'
										rel='noreferrer'
										className={styles.green_hyperlink}>
										help@fca.edu.sg
									</a>
									) at least seven (7) days prior to course date with supporting documents. Fees
									will be waived for all requests with supporting documents (ie. Medical Reasons,
									Covid, Work Commitment etc).
								</div>
								<div className='pt-3'>Reschedule Request Rescheduling Fees</div>
								<div className='pt-3'>
									<Table striped bordered hover>
										<thead>
											<tr>
												<th
													className='font-weight-bold text-color-dark $fs15'
													style={{ fontSize: "13px" }}>
													Reschedule Request
												</th>
												<th
													className='font-weight-bold text-color-dark $fs15'
													style={{ fontSize: "13px" }}>
													Rescheduling Fees
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>1st Reschedule</td>
												<td>No Charge</td>
											</tr>
											<tr>
												<td>2nd and Subsequent Reschedules</td>
												<td>10% of full course fees (Waivable with valid reasons)</td>
											</tr>
										</tbody>
									</Table>
								</div>
								<div className='pt-2'>
									A maximum of three (3) reschedule requests is allowed. There will be no
									rescheduling allowed after the 3rd reschedule and the enrolment will be considered
									as a no-show.
								</div>

								<div className='pt-4 font-weight-bold'>3. Withdrawal/Cancellation/No-show</div>
								<div className=''>
									Any withdrawal/cancellation/no-show for confirmed enrolments must be submitted via
									our online withdrawal form (
									<a
										href='https://falcomm.fca.edu.sg/'
										target='_blank'
										rel='noreferrer'
										className={styles.green_hyperlink}>
										https://falcomm.fca.edu.sg/
									</a>
									) or email us at (
									<a
										href='mailto:help@fca.edu.sg'
										target='_blank'
										rel='noreferrer'
										className={styles.green_hyperlink}>
										help@fca.edu.sg
									</a>
									) at least seven (7) days prior to course date with supporting documents. In the
									event of withdrawal/cancellation/no-show without submission of a withdrawal form
									containing supporting documents, trainee(s) will be liable to pay the full course
									fees.
								</div>
								<div className='pt-2'>
									Please note that government funding is not applicable for
									withdrawal/cancellation/no-show and the penalty fees may need to be paid via
									cash/bank transfer.
								</div>

								<div className='pt-4 font-weight-bold'>4.. Intellectual Property Agreement</div>
								<div>By registering for our courses, you acknowledge and agree that all course materials, content, and intellectual property, including our company logo, are owned exclusively by FirstCom Academy Pte Ltd. You are not permitted to use, reproduce, or distribute any of our content or intellectual property for any purpose without prior written consent from us. Unauthorized use of our intellectual property may result in appropriate legal action. In the event of legal action resulting from unauthorized use of our intellectual property, you agree to cover all associated costs. 
								</div>

								<div className='pt-4 font-weight-bold'>5. Electronic Payment</div>
								<div>By registering for our courses, you confirm that all electronic payments for the registered courses are made directly by you. You acknowledge that you are the authorized account holder or have obtained explicit permission from the account holder to make such payments. You agree to be responsible for any fees or charges incurred in connection with your electronic payments.</div>


								<div className='pt-4 font-weight-bold'>6. Other Terms</div>
								<div className=''>
									In view of the current Covid situation, FirstCom Academy reserves the right to
									cancel, postpone and change the venue or mode of the course(s) in the event of
									unforeseen circumstances. Notification will be provided to trainee(s) within a
									reasonable timeframe.
									<br></br>
									<br></br>
									For learners using your
									<a
										href='https://www.moe.gov.sg/financial-matters/psea/overview'
										target='_blank'
										rel='noreferrer'
										className={styles.green_hyperlink}>
										{" Post Secondary Education Account (PSEA) "}
									</a>
									as mode of payment, kindly note that your PSEA will be closed around the middle of
									the year in which you turn 31. Any unused funds will be transferred to your CPF-OA
									and subjected to CPF-OA usage rules. Once transferred, the PSEA will be
									closed.Should this situation arise resulting in a shortfall of payment, please
									note that you will need to arrange for other forms of payment to cover the
									shortfall.
								</div>

								<div className='pt-4 font-weight-bold'>7. Privacy Policy</div>
								<div className=''>
									FirstCom Academy Pte Ltd has the right to use the data provided for the purpose of
									processing Trainee(s)’ course registration, subject to the provisions of the
									Personal Data Protection Act 2012 (PDPA). Photographs / video may be taken during
									the course for marketing purposes.
								</div>

								<div className='pt-4 font-weight-bold'>8. Refund Policy</div>
								<div className=''>
									If the Trainee(s) does not participate in the Programme / Course after having paid
									all or any portion of the Programme / Course fees, or if the Trainee(s) drop out
									before completion of the Programme / Course without valid reasons and supporting
									documents, no deposits or money paid towards the Program / Course fees shall be
									refunded. Appeals with valid reasons and supporting documents will be processed on
									a case by case basis, subjected to management approval.
								</div>

								<div className='pt-4 font-weight-bold'>9. Recording and Photography Policy</div>
								<div className=''>
									To protect the privacy of our customers, employees, and safeguard our intellectual 
									property, we strictly prohibit any form of audio or video recording, photography,
									or similar activities within our premises without prior written consent from the 
									management.
									<br></br>
									<br></br>
									By entering our premises, you agree to comply with this policy. We reserve the right 
									to request the cessation of such activities, and failure to comply may result in the 
									refusal of service or removal from the premises. We also reserve the right to take 
									appropriate legal action, where necessary.
								</div>
							</div>
						</Col>
					</Row>
				</Collapse>
				<Row className='pt-3 pb-3'>
					<Col>
						<div className='pt-2'>
							<div className='pt-4 font-weight-bold'>I declare that *</div>
							<div className='pt-4'>
								<p>
									<div style={{ display: "flex", alignItems: "flex-start" }}>
										<input
											type="checkbox"
											id="checkbox1"
											style={{ display: "inline-block", marginRight: "5px" , marginTop: "5px" }}
											ref={checkbox1}
										/>
										<label 
											htmlFor="checkbox1"
											className={styles.declaration_labels}
											style={{ display: "inline-block" }}
										>
											I/the trainee acknowledge that in the event I have insufficient Skillsfuture Credit available, the balance course fees will be paid in cash.
											<br />
											我确认，如果我的未来技能培训补助（SkillsFuture Credit）额度不足，余下的课程费用将以现金支付。
										</label>
									</div>
								</p>
								<p>
									<div style={{ display: "flex", alignItems: "flex-start" }}>
										<input
											type="checkbox"
											id="checkbox2"
											style={{ display: "inline-block", marginRight: "5px" , marginTop: "5px" }}
											ref={checkbox2}
										/>
										<label 
											htmlFor="checkbox2"
											className={styles.declaration_labels}
											style={{ display: "inline-block" }}
										>
											I/the trainee have/has basic computer skills such as basic emailing, using of Google Documents, Microsoft Word and Microsoft Powerpoint, copying and pasting, opening and closing of files.
											<br />
											我具备基本的电脑技能，如基本电邮使用、使用谷歌文档、微软Word和微软Powerpoint、复制和粘贴、打开和关闭文件等。
										</label>
									</div>
								</p>
								<p>
									<div style={{ display: "flex", alignItems: "flex-start" }}>
										<input
											type="checkbox"
											id="checkbox3"
											style={{ display: "inline-block", marginRight: "5px" , marginTop: "5px" }}
											ref={checkbox3}
										/>
										<label 
											htmlFor="checkbox3"
											className={styles.declaration_labels}
											style={{ display: "inline-block" }}
										>
											I/the trainee have/has thoroughly read and understand the course brochure and determined this course to be relevant to my current/future work.
											<br />
											我确认我已经彻底阅读并理解了课程手册，并确定这个课程与我目前/未来的工作相关。
										</label>
									</div>
								</p>
								<p>
									<div style={{ display: "flex", alignItems: "flex-start" }}>
										<input
											type="checkbox"
											id="checkbox4"
											style={{ display: "inline-block", marginRight: "5px" , marginTop: "5px" }}
											ref={checkbox4}
										/>
										<label 
											htmlFor="checkbox4"
											className={styles.declaration_labels}
											style={{ display: "inline-block" }}
										>
											I/the trainee acknowledge that I am aware that the courses are conducted in English and there is an assessment at the end of the course.
											<br />
											我确认我知道课程是用英语进行的，并且在课程结束时有一个评估。
										</label>
									</div>
								</p>
								<p>
									<div style={{ display: "flex", alignItems: "flex-start" }}>
										<input
											type="checkbox"
											id="checkbox5"
											style={{ display: "inline-block", marginRight: "5px" , marginTop: "5px" }}
											ref={checkbox5}
										/>
										<label 
											htmlFor="checkbox5"
											className={styles.declaration_labels}
											style={{ display: "inline-block" }}
										>
											I/the trainee acknowledge that a SingPass account is required for the application of SkillsFuture Credits (if applicable).
											<br />
											我确认如适用，申请技能创新信用额度需要使用SingPass账户。
										</label>
									</div>
								</p>
								{courseRegisterData.learners.learner_qualificationlevel == "Primary Qualification (e.g PSLE) or Below" || courseRegisterData.learners.learner_qualificationlevel == "Lower Secondary (Secondary Education without O level/N level pass)" ? (
									<p>
										<div style={{ display: "flex", alignItems: "flex-start" }}>
											<input
												type="checkbox"
												id="checkbox6"
												style={{ display: "inline-block", marginRight: "5px" , marginTop: "5px" }}
												ref={checkbox6}
											/>
											<label 
												htmlFor="checkbox6"
												className={styles.declaration_labels}
												style={{ display: "inline-block" }}
											>
												I/the trainee understand that the minimum entry requirement for the above courses is N-Level qualification. However, I acknowledge this and wish to proceed with my application for the course.
												<br />
												我确认上述课程的最低入学要求是N水准学历，但我仍希望继续申请该课程。
											</label>
										</div>
									</p>
								) : (
									""
								)}
							</div>
						</div>
					</Col>
				</Row>

				<Row className='pt-3  pb-3'>
					<Col>
						<div className='pt-3'>
							<div className='pb-2'>
								<b className='text-color-dark'>Your Signature</b>
								<a
									href='#'
									className='ml-2'
									onClick={(e) => {
										e.preventDefault();
										canvasRef.current.clear();
									}}>
									Clear
								</a>
							</div>
							<SignatureCanvas
								ref={canvasRef}
								clearOnResize={false}
								canvasProps={{height: 200, className: "sigCanvas border", style: {width: "100%", maxWidth: "500px"}}}
							/>
						</div>

						<div className='pt-2'>
							<label htmlFor='tnc-checkbox'>
								<input type='checkbox' id='tnc-checkbox' ref={checkboxTNC}></input>
								<span>
									&nbsp; I acknowledge that I have read and agree to the above
									<a
										href='#terms-and-conditions'
										className='ml-2 text-fca-blue text-underline cursor-pointer'
										onClick={(e) => {
											setAccordionOpen(true);
										}}>
										terms and conditions.
									</a><br />
									我确认我已阅读并同意上述
									<a
										href='#terms-and-conditions'
										className='ml-2 text-fca-blue text-underline cursor-pointer'
										onClick={(e) => {
											setAccordionOpen(true);
										}}>
										条款和条件 
									</a>
								</span>
							</label>
						</div>
					</Col>
				</Row>

				<Row className='p-3'>
					<Col className='border pb-3'>
						{Number(nettFeePayableAmount - sfcClaimedAmount) > 0 ? (
							<Row>
								<Col>
									<div className='mt-4 select-payment-method'>
										<span className='fs18 text-color-dark'>Select One Payment Method</span>
									</div>
									<div className='mt-3 ml-4'>
										<div className='payment-method-div flex flex-row justify-content-center'>
											<div
												className={`payment-method-div-paynow text-center ${
													paymentmethod === "paynow" ? "active" : ""
												}`}
												onClick={selectPaymentmethod}
												data-method='paynow'>
												<img
													className='payment-logo-paynow'
													src={require("assets/img/endorse/paynow-logo.png")}
												/>
												<span>Paynow</span>
												<span>
													Pay the balance amount using<br></br>Paynow
												</span>
											</div>
											<div
												className={`payment-method-div-paynow text-center ${
													paymentmethod === "creditcard" ? "active" : ""
												}`}
												onClick={selectPaymentmethod}
												data-method='creditcard'>
												<img
													className='payment-logo-cc'
													src={require("assets/img/endorse/cc-logo.png")}
												/>
												<span>Credit Card</span>
												<span>
													Pay the balance amount using<br></br>credit card
												</span>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						) : (
							""
						)}

						<Row className='pt-5 align-items-end'>
							<Col />
							<Col sm={12} md={5} className='mb-2'>
								<CourseConsultantCode onChange={(ccCode) => setccCode(ccCode)} />
							</Col>
						</Row>

						<Row className='pt-2 align-items-end'>
							<Col className=''>
								<div
									style={{
										display: "flex",
										alignItems: "flex-end",
										width: "100%",
									}}>
									<Button
										type='button'
										className='btn shadow-none'
										style={{ background: "#eeeeee" }}
										onClick={() => {
											const newSearchParam = Object.fromEntries(searchParams.entries());
											if (newSearchParam.uuidv4) {
												delete newSearchParam.uuidv4;
											}
											window.scrollTo({ top: 0 });
											navigate({
												pathname: "/checkout/account",
												search: decodeURIComponent(createSearchParams(newSearchParam).toString()),
											});
										}}>
										Back
									</Button>
								</div>
							</Col>

							<Col className=''>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
										gap: "10px",
										width: "100%",
									}}>
									{(loading)  && (
										<div className='d-flex justify-content-center'>
											<div className='spinner-border' role='status'>
												<span className='sr-only'>Loading...</span>
											</div>
										</div>
									)}
									<Button
										type='button'
										className='btn-gradient'
										disabled={disabled || !checkSubsidyDone}
										onClick={handleConfirmCourse}>
										Continue
									</Button>
								</div>
							</Col>
						</Row>

						{
							!checkSubsidyDone ? (
								<div className="d-flex justify-content-end">
									<div className='text-danger'>
										<Spinner color='primary' size={"sm"}className="mr-2"/>
											Please wait while we check the subsidy eligibility for the course.
									</div>
								</div>
								
							):null
						}

						<Row className='pt-5 pb-3'>
							<Col>
								<div>
									For more information on Course Fee Funding,{" "}
									<a
										href='https://www.skillsfuture.gov.sg/funding-individuals'
										target='_blank'
										rel='noreferrer'
										onClick={() => GTMCheckoutMICFFButton(checkoutAnalyticsData, userData)}
										className={styles.green_hyperlink}>
										please click here
									</a>
								</div>
								<div>
									For more information on SkillsFuture Credits,{" "}
									<a
										href='https://www.skillsfuture.gov.sg/initiatives/mid-career/credit'
										target='_blank'
										rel='noreferrer'
										onClick={() => GTMCheckoutMISFCButton(checkoutAnalyticsData, userData)}
										className={styles.green_hyperlink}>
										please click here
									</a>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>

			<Modal isOpen={!state.payment_status && !state.countdown} centered>
				<ModalHeader>Payment Failed</ModalHeader>
				<ModalBody>
					<p>Your payment was unsuccessful. </p>

					<p>
						Kindly proceed with the payment again. Should you require any assistance or
						clairification, please contact us.
					</p>

					<p>Thank you.</p>
				</ModalBody>
				<ModalFooter>
					<a href='#' onClick={updateStateInLocation}>
						<Button color='primary'>Close</Button>
					</a>
				</ModalFooter>
			</Modal>

			<Modal isOpen={!state.payment_status && state.countdown == true} centered>
				<ModalHeader>Payment Pending</ModalHeader>
				<ModalBody>
					<p>Your payment is currently pending</p>

					<p>
						We are currently verifying you payment! Upon confirmation, you will receive an email
						confirmation
					</p>

					<p>Thank you.</p>
				</ModalBody>
				<ModalFooter>
					<a href='#' onClick={updateStateInLocation}>
						<Button color='primary'>Close</Button>
					</a>
				</ModalFooter>
			</Modal>
		</>
	);
}
