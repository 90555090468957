import {
	osName,
	isBrowser,
	isMobile,
	browserName,
	mobileVendor,
	mobileModel,
} from "react-device-detect";
import TagManager from "react-gtm-module";

const CallToGTM = async (eventName, checkoutAnalyticsData, userData) => {
	TagManager.dataLayer({
		dataLayer: {
			event: eventName,
			course_id: checkoutAnalyticsData ? checkoutAnalyticsData.course_id : null,
			course_title: checkoutAnalyticsData ? checkoutAnalyticsData.course_title : null,
			courserun_id: checkoutAnalyticsData ? checkoutAnalyticsData.courserun_id : null,
			referral:  checkoutAnalyticsData ? checkoutAnalyticsData.referral ? checkoutAnalyticsData.referral : null : null,
			// invite:  checkoutAnalyticsData ? checkoutAnalyticsData.invite ? checkoutAnalyticsData.invite : null : null,
			campaign_type: checkoutAnalyticsData ? checkoutAnalyticsData.campaign_type ? checkoutAnalyticsData.campaign_type : null : null,
			utm_campaign: checkoutAnalyticsData ? checkoutAnalyticsData.utm_campaign ? checkoutAnalyticsData.utm_campaign : null : null,
			utm_content: checkoutAnalyticsData ? checkoutAnalyticsData.utm_content ? checkoutAnalyticsData.utm_content : null : null,
			utm_medium: checkoutAnalyticsData ? checkoutAnalyticsData.utm_medium ? checkoutAnalyticsData.utm_medium : null : null,
			utm_source: checkoutAnalyticsData ? checkoutAnalyticsData.utm_source ? checkoutAnalyticsData.utm_source : null : null,
			utm_term: checkoutAnalyticsData ? checkoutAnalyticsData.utm_term ? checkoutAnalyticsData.utm_term : null : null,

			user_id: userData && userData.user ? userData.user.learnermaster_id : null,
			user_name: userData && userData.user ? userData.user.learnermaster_name : null,
			user_age: userData && userData.user ? getAge( userData.user.learnermaster_dob) : null,
			user_nationality: userData && userData.user ? userData.user.learnermaster_nationality : null,
			user_gender: userData && userData.user ? userData.user.learnermaster_gender : null,
			user_os_name: osName,
			user_browser_name: browserName,
			user_mobile_vendor: mobileVendor,
			user_mobile_model: mobileModel,
			user_is_browser: isBrowser,
			user_is_mobile: isMobile,
			user_type: "web",
		},
	});
};

const CallToGTMPurchase = async (eventName, checkoutAnalyticsData, userData, transaction_id) => {
	TagManager.dataLayer({
		dataLayer: {
			event: eventName,
			ecommerce: {
				currency: "SGD",
				transaction_id: transaction_id,
				value: checkoutAnalyticsData ? checkoutAnalyticsData.course_price_value ? checkoutAnalyticsData.course_price_value : null : null,
				items:[
					{
						item_name: checkoutAnalyticsData ? checkoutAnalyticsData.course_title ? checkoutAnalyticsData.course_title : null : null,
						price:checkoutAnalyticsData ? checkoutAnalyticsData.course_price_value ? checkoutAnalyticsData.course_price_value : null : null,
					}
				]
			}
		},
	});


	window.uetq = window.uetq || [];
	window.uetq.push(
		"event",
		"purchase",
		{ 
			"revenue_value": checkoutAnalyticsData ? checkoutAnalyticsData.course_price_value ? checkoutAnalyticsData.course_price_value : null : null,
			"currency": "SGD"
		}
	);
};

const CallToGTMFacebook = async (eventName, checkoutAnalyticsData) => {
	TagManager.dataLayer({
		dataLayer: {
			event: eventName,
			content_name: checkoutAnalyticsData ? checkoutAnalyticsData.utm_campaign ? checkoutAnalyticsData.utm_campaign : "" : "",
			content_category: checkoutAnalyticsData ? checkoutAnalyticsData.utm_source ? checkoutAnalyticsData.utm_source : "" : "",
			content_type: checkoutAnalyticsData ? checkoutAnalyticsData.campaign_type ? checkoutAnalyticsData.campaign_type : "BIG BANG" : "BIG BANG",
		},
	});
};

const CallToGTMGoogleAds = async (eventName, checkoutAnalyticsData) => {
	TagManager.dataLayer({
		dataLayer: {
			event: eventName,
		},
	});
};

// GENERAL GTM
export const logGTMScreen = async (location, isLoggedIn, currentUser) => {
	let routeName = "";
	if (location.pathname === "/") {
		routeName = "web_visit";
	} else {
		if (location.pathname.includes("/login")) {
			if (location.pathname.includes("/login/otp-validation")) {
				routeName = "web_login_otp_modal";
			} else if (location.pathname.includes("/login/hyperadmin-otp-validation")) {
				routeName = "web_hyperadmin_otp_modal";
			} else if (location.pathname.includes("/login/hyperadmin-user-selection")) {
				routeName = "web_hyperadmin_user_selection";
			} else if (location.pathname.includes("/login/reset-password")) {
				routeName = "web_forget_password";
			} else if (location.pathname.includes("/login/reset-password/otp-validation")) {
				routeName = "web_register_otp_modal";
			} else if (location.pathname.includes("/login/first-time-reset-password")) {
				routeName = "web_firsttime_reset_password";
			} else {
				routeName = "web_sign_in";
			}
		} else if (location.pathname.includes("/register")) {
			routeName = "web_register";
		} else if (location.pathname.includes("/course")) {
			if (location.pathname.includes("/course/course-details")) {
				routeName = "web_upcoming_course_detail";
			} else if (location.pathname.includes("/course/reschedule")) {
				routeName = "web_reschedule_course";
			} else if (location.pathname.includes("/course/withdrawal")) {
				routeName = "web_withdraw_course";
			} else if (location.pathname.includes("/course/course-info")) {
				routeName = "web_course_detail";
			} else if (location.pathname.includes("/course/shuttle-bus")) {
				routeName = "web_shuttle_bus_selection";
			} else if (location.pathname.includes("/course/bundle")) {
				routeName = "web_bundle_details";
			} else if (location.pathname.includes("/course/bundle-list")) {
				routeName = "web_completed_bundle_cert";
			} else if (location.pathname.includes("/course/download-resources")) {
				routeName = "web_download_resources";
			} else if (location.pathname.includes("/course/resource-details")) {
				routeName = "web_resources_details";
			} else if (location.pathname.includes("/course/register")) {
				routeName = "web_course_reg_form";
			} else if (location.pathname.includes("/course/payment")) {
				routeName = "web_course_reg_paymentmethod";
			} else if (location.pathname.includes("/course/endorse")) {
				routeName = "web_course_reg_termcondition";
			} else if (location.pathname.includes("/course/confirm")) {
				routeName = "web_course_reg_confirmation";
			} else if (location.pathname.includes("/course/success")) {
				routeName = "web_course_reg_success";
			} else if (location.pathname.includes("/course/meal-selection")) {
				routeName = "web_meal_selection";
			} else if (location.pathname.includes("/course/success-page")) {
				routeName = "web_reschedule_withdrawal_success";
			} else if (location.pathname.includes("/course/meal-success-page")) {
				routeName = "web_meal_selection_Success";
			} else if (location.pathname.includes("/course/shuttle-bus-success-page")) {
				routeName = "web_shuttle_bus_success";
			} else {
				routeName = "web_course_home";
			}
		} else if (location.pathname.includes("/contact-us")) {
			routeName = "web_course_contactus";
		} else if (location.pathname.includes("/news")) {
			if (location.pathname.includes("/news/article")) {
				routeName = "web_article_details";
			} else if (location.pathname.includes("/event")) {
				routeName = "web_event_details";
			} else if (location.pathname.includes("/news/teaser")) {
				routeName = "web_teaser_details";
			} else {
				routeName = "web_news_home";
			}
		} else if (location.pathname.includes("/notification")) {
			if (location.pathname.includes("/notification/article")) {
				routeName = "web_article_reply";
			} else if (location.pathname.includes("/notification/")) {
				routeName = "web_notifications_details";
			} else {
				routeName = "web_notification_home";
			}
		} else if (location.pathname.includes("/faq")) {
			routeName = "web_faq_home";
		} else if (location.pathname.includes("/profile")) {
			if (location.pathname.includes("/profile/edit")) {
				routeName = "web_update_profile";
			} else if (location.pathname.includes("/profile/change-password")) {
				routeName = "web_change_password";
			} else if (location.pathname.includes("/profile/change-password/otp-validation")) {
				routeName = "web_verify_email";
			} else if (location.pathname.includes("/profile/change-email")) {
				routeName = "web_change_email";
			} else if (location.pathname.includes("/profile/change-email/otp-validation")) {
				routeName = "web_change_email_verify";
			} else {
				routeName = "web_profile";
			}
		} else if (location.pathname.includes("/home")) {
			routeName = "web_home";
		}
	}

	window.dataLayer = window.dataLayer || [];
	if (isLoggedIn) {
		TagManager.dataLayer({
			dataLayer: {
				event: "manualPageView",
				pageUrl: location.pathname,
				pageTitle: routeName,
				user_id: currentUser.user.learnermaster_id ? currentUser.user.learnermaster_id.toString() : null,
				user_name: currentUser.user.learnermaster_name ? currentUser.user.learnermaster_name.toString() : null,
				user_age: getAge(currentUser.user.learnermaster_dob ? currentUser.user.learnermaster_dob : null),
				user_nationality: currentUser.user.learnermaster_nationality ? currentUser.user.learnermaster_nationality : null,
				user_gender: currentUser.user.learnermaster_gender ? currentUser.user.learnermaster_gender : null,
				user_os_name: osName,
				user_browser_name: browserName,
				user_mobile_vendor: mobileVendor,
				user_mobile_model: mobileModel,
				user_is_browser: isBrowser,
				user_is_mobile: isMobile,
				user_type: "web",
			},
		});
	} else {
		TagManager.dataLayer({
			dataLayer: {
				event: "manualPageView",
				pageUrl: location.pathname,
				pageTitle: routeName,
				user_id: null,
				user_name: null,
				user_age: null,
				user_nationality: null,
				user_gender: null,
				user_os_name: osName,
				user_browser_name: browserName,
				user_mobile_vendor: mobileVendor,
				user_mobile_model: mobileModel,
				user_is_browser: isBrowser,
				user_is_mobile: isMobile,
				user_type: "web",
			},
		});
	}
};

export const logGTMUser = async (id, name, dob, nationality, gender) => {
	logGTMUserID(id, name, dob, nationality, gender);
};

export const logGTMUserID = async (id, name, dob, nationality, gender) => {
	TagManager.dataLayer({
		dataLayer: {
			event: "setUserID",
			user_id: id,
			user_name: name,
			user_age: getAge(dob),
			user_nationality: nationality,
			user_gender: gender,
			user_os_name: osName,
			user_browser_name: browserName,
			user_mobile_vendor: mobileVendor,
			user_mobile_model: mobileModel,
			user_is_browser: isBrowser,
			user_is_mobile: isMobile,
			user_type: "web",
		},
	});
};

export const removeLogGTMUser = async () => {
	TagManager.dataLayer({
		dataLayer: {
			event: "unsetUserID",
			user_id: null,
			user_name: null,
			user_age: null,
			user_nationality: null,
			user_gender: null,
			user_os_name: osName,
			user_browser_name: browserName,
			user_mobile_vendor: mobileVendor,
			user_mobile_model: mobileModel,
			user_is_browser: isBrowser,
			user_is_mobile: isMobile,
			user_type: "web",
		},
	});
};

const getAge = (dateString) => {
	if (dateString) {
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age.toString();
	} else {
		return null;
	}
};

// CHECKOUT GTM
// Step 1 Account page
export const GTMCheckoutStep1Account = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_step1_account", checkoutAnalyticsData, userData);
};
// Step 1 Account page exit
export const GTMCheckoutStep1AccountExit = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_step1_account_exit", checkoutAnalyticsData, userData);
};
// Step 1 Go Endorse page
export const GTMCheckoutStep1GoEndorse = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_step1_go_endorse", checkoutAnalyticsData, userData);
};
// Step 2 Endorse page
export const GTMCheckoutStep2Endorse = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_step2_endorse", checkoutAnalyticsData, userData);
};
// Step 2 Account page exit
export const GTMCheckoutStep2EndorseExit = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_step2_endorse_exit", checkoutAnalyticsData, userData);
};
// Step 2 Go Success page
export const GTMCheckoutStep2GoSuccess = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_step2_go_success", checkoutAnalyticsData, userData);
};
// Step 2 Go Paynow page
export const GTMCheckoutStep2GoPaynow = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_step2_go_paynow", checkoutAnalyticsData, userData);
};
// Step 2.5 Paynow page
export const GTMCheckoutStep2_5Paynow = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_step2_5_paynow", checkoutAnalyticsData, userData);
};
// Step 2.5 Paynow page exit
export const GTMCheckoutStep2_5PaynowExit = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_step2_5_paynow_exit", checkoutAnalyticsData, userData);
};
// Step 2.5 Go Success page
export const GTMCheckoutStep2_5GoSuccess = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_step2_5_go_success", checkoutAnalyticsData, userData);
};
// Step 3 Success page
export const GTMCheckoutStep3Success = async (userData) => {
	CallToGTM("checkout_step3_success", null, userData);
};
// Step 2 Success page exit
export const GTMCheckoutStep3SuccessExit = async (userData) => {
	CallToGTM("checkout_step3_success_exit", null, userData);
};
// Checkout completed
export const GTMCheckoutCompleted = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_checkout_completed", checkoutAnalyticsData, userData);
};
// Checkout purchased completed
export const GTMCheckoutPurchased = async (checkoutAnalyticsData, userData, transaction_id) => {
	CallToGTMPurchase("purchase", checkoutAnalyticsData, userData, transaction_id);
};
// enquiry now button
export const GTMCheckoutEnquiryButton = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_enquiry_now_button_click", checkoutAnalyticsData, userData);
};
// enquiry individual form
export const GTMCheckoutEnquiryFormIndividualSubmit = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_enquiry_individual_form_submit", checkoutAnalyticsData, userData);
};
// enquiry corporate form
export const GTMCheckoutEnquiryFormCorporateSubmit = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_enquiry_corporate_form_submit", checkoutAnalyticsData, userData);
};

// Checkout completed - Facebook Pixel
export const GTMCheckoutFacebookCheckoutCompleted = async (checkoutAnalyticsData, userData) => {
	setTimeout(() => {
		CallToGTMFacebook("checkout_facebook_checkout_completed", checkoutAnalyticsData);
	}, 500);
};
// enquiry corporate form - Facebook Pixel
export const GTMCheckoutFacebookEnquiryFormCorporateSubmit = async (checkoutAnalyticsData, userData) => {
	setTimeout(() => {
		CallToGTMFacebook("checkout_facebook_enquiry_corporate_form_submit", checkoutAnalyticsData);
	}, 500);
};
// enquiry individual form - Facebook Pixel
export const GTMCheckoutFacebookEnquiryFormIndividualSubmit = async (checkoutAnalyticsData, userData) => {
	setTimeout(() => {
		CallToGTMFacebook("checkout_facebook_enquiry_individual_form_submit", checkoutAnalyticsData);
	}, 500);
};

// Checkout completed - Google ads
export const GTMCheckoutGoogleAdsCheckoutCompleted = async (checkoutAnalyticsData, userData) => {
	setTimeout(() => {
		CallToGTMGoogleAds("checkout_ads_checkout_completed", checkoutAnalyticsData);
	}, 500);
};
// enquiry corporate form - Google ads
export const GTMCheckoutGoogleAdsEnquiryFormCorporateSubmit = async (checkoutAnalyticsData, userData) => {
	setTimeout(() => {
		CallToGTMGoogleAds("checkout_ads_enquiry_corporate_form_submit", checkoutAnalyticsData);
	}, 500);
};
// enquiry individual form - Google ads
export const GTMCheckoutGoogleAdsEnquiryFormIndividualSubmit = async (checkoutAnalyticsData, userData) => {
	setTimeout(() => {
		CallToGTMGoogleAds("checkout_ads_enquiry_individual_form_submit", checkoutAnalyticsData);
	}, 500);
};

// view more courses button
export const GTMCheckoutViewMoreCoursesButton = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_view_more_courses_button_click", checkoutAnalyticsData, userData);
};

// CHECKOUT ACCOUNT GTM
// singpass retrieve info button
export const GTMCheckoutSingpassRetrieveInfoButton = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_singpass_retrieve_button_click", checkoutAnalyticsData, userData);
};
// singpass retrieve info success
export const GTMCheckoutSingpassRetrieveInfoSuccess = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_singpass_retrieve_success", checkoutAnalyticsData, userData);
};
export const GTMCheckoutSingpassRetrieveInfoFailed = async (checkoutAnalyticsData) => {
	CallToGTM("checkout_singpass_retrieve_failed", checkoutAnalyticsData, null);
};
// user login button
export const GTMCheckoutUserLoginButton = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_user_login_button_click", checkoutAnalyticsData, userData);
};
// user login success
export const GTMCheckoutUserLoginSuccess = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_user_login_success", checkoutAnalyticsData, userData);
};
// user forget password button
export const GTMCheckoutUserForgetPasswordButton = async (checkoutAnalyticsData) => {
	CallToGTM("checkout_forget_password_button_click", checkoutAnalyticsData, null);
};
// user forget password success
export const GTMCheckoutUserForgetPasswordSuccess = async (checkoutAnalyticsData) => {
	CallToGTM("checkout_forget_password_success", checkoutAnalyticsData, null);
};
// user login otp verify
export const GTMCheckoutUserLoginOtpVerify = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_login_otp_verify", checkoutAnalyticsData, userData);
};
// user login otp verify success
export const GTMCheckoutUserLoginOtpVerifySuccess = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_otp_verify_success", checkoutAnalyticsData, userData);
};
// user logout button
export const GTMCheckoutUserLogoutButton = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_user_logout_button_click", checkoutAnalyticsData, userData);
};
// user logout success
export const GTMCheckoutUserLogoutSuccess = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_user_logout_success", checkoutAnalyticsData, userData);
};
// user register button
export const GTMCheckoutUserRegisterButton = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_user_register_button_click", checkoutAnalyticsData, userData);
};
// user register success
export const GTMCheckoutUserRegisterSuccess = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_user_register_success", checkoutAnalyticsData, userData);
};

// CHECKOUT REGFORM
// submit regform
export const GTMCheckoutSubmitRegform = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_submit_regform", checkoutAnalyticsData, userData);
};
// submit regform success
export const GTMCheckoutSubmitRegformSuccess = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_submit_regform_success", checkoutAnalyticsData, userData);
};

// CHECKOUT PAYMENT
// sfc payment success
export const GTMCheckoutSFCPaymentSuccess = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_submit_regform_success", checkoutAnalyticsData, userData);
};
// sfc payment failed
export const GTMCheckoutSFCPaymentFailed = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_sfc_payment_failed", checkoutAnalyticsData, userData);
};
// cc payment success
export const GTMCheckoutCCPaymentSuccess = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_cc_payment_success", checkoutAnalyticsData, userData);
};
// cc payment failed
export const GTMCheckoutCCPaymentFailed = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_cc_payment_failed", checkoutAnalyticsData, userData);
};
// paynow payment success
export const GTMCheckoutPaynowPaymentSuccess = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_paynow_payment_success", checkoutAnalyticsData, userData);
};
// cc payment failed
export const GTMCheckoutPaynowPaymentFailed = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_paynow_payment_failed", checkoutAnalyticsData, userData);
};

// CHECKOUT ENDORSE
// no sfc button
export const GTMCheckoutNoSFCButton = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_no_sfc_button_click", checkoutAnalyticsData, userData);
};
// yes sfc button
export const GTMCheckoutYesSFCButton = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_yes_sfc_button_click", checkoutAnalyticsData, userData);
};
// more information courses fee funding button
export const GTMCheckoutMICFFButton = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_mi_cff_button_click", checkoutAnalyticsData, userData);
};
// more information skillsfuture credits button
export const GTMCheckoutMISFCButton = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_mi_sfc_button_click", checkoutAnalyticsData, userData);
};
// update online registration payment signature
export const GTMCheckoutUpdateOnlineRegPaymentSignature = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_update_reg_payment_signature", checkoutAnalyticsData, userData);
};
// update online registration payment signature success
export const GTMCheckoutUpdateOnlineRegPaymentSignatureSuccess = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_reg_payment_signature_success", checkoutAnalyticsData, userData);
};
// update online registration payment signature failed
export const GTMCheckoutUpdateOnlineRegPaymentSignatureFailed = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_reg_payment_signature_failed", checkoutAnalyticsData, userData);
};
// update online registration progress
export const GTMCheckoutUpdateOnlineRegProgress = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_update_reg_progress", checkoutAnalyticsData, userData);
};
// update online registration progress success
export const GTMCheckoutUpdateOnlineRegProgressSuccess = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_reg_progress_success", checkoutAnalyticsData, userData);
};
// update online registration progress failed
export const GTMCheckoutUpdateOnlineRegProgressFailed = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_reg_progress_failed", checkoutAnalyticsData, userData);
};
// course endorse success
export const GTMCheckoutCourseEndorseSuccess = async (checkoutAnalyticsData, userData) => {
	CallToGTM("checkout_course_endorse_success", checkoutAnalyticsData, userData);
};
