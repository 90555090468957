import { Row, Col, Card, CardBody, Button } from "reactstrap";
import moment from "moment";
import { ReactComponent as TimeIcon } from "assets/img/Course/upcoming/date_blue.svg";
import { ReactComponent as CourseSvg } from "assets/img/endorse/icon_course.svg";
import { ReactComponent as MealIcon } from "assets/img/Course/upcoming/meal.svg";
import { ReactComponent as CourseIcon } from "assets/img/Course/upcoming/course_ID_blue.svg";
import { ReactComponent as UserIcon } from "assets/img/Course/upcoming/user_blue.svg";
import { ReactComponent as PhoneIcon } from "assets/img/Course/upcoming/phone_blue.svg";
import { ReactComponent as LocationIcon } from "assets/img/Course/upcoming/location_blue.svg";
import { ReactComponent as AlmsIcon } from "assets/img/Course/upcoming/alms_blue.svg";
import { ReactComponent as ClassIcon } from "assets/img/Course/upcoming/class.svg";
import { ReactComponent as DownloadIcon } from "assets/img/Course/upcoming/download.svg";
import { ReactComponent as CertificateIcon } from "assets/img/Course/registration/success.svg";
import { ReactComponent as CourseInfoIcon } from "assets/img/Course/upcoming/courseinfo.svg";
import { ReactComponent as CourseSessionIcon } from "assets/img/Course/upcoming/session.svg";
import { useWindow } from "hooks/UseWindow";
import { Link } from "react-router-dom";
import ModalCertificates from "views/pages/learner/course/ModalCertificates";
import { useState } from "react";
import defaultCourse from "assets/img/default_course.jpg";

const VERSION = process.env.REACT_APP_APP_VERSION;

function CourseInfo({
	course_title,
	course_logos,
	trainer_name,
	trainer_image_url,
	course_duration,
	course_categories,
	course_ref,
	courserun_modeoftraining,
	venue_address,
	training_support_name,
	training_support_mobile,
	courserun_course_from,
	courserun_course_to,
	csessions,
	course_description,
	course_reschedule_status,
	course_withdrawal_status,
	pending_assessment_date,
	course_attendance_status,
	tobe_rescheduled,
	claim_id,
	sfc_claim_id,
	claim_status,
	course_banner_image,
	course_short_description,
	registered_learners,
	message,
	status,
	course,
	handlePopup,
	handleSFCClick,
	showButtons,
}) {
	const { width } = useWindow();
	const [certModalChange, setCertModalChange] = useState(false);

	const getStatusTag = (course_reschedule_status, tobe_rescheduled, course_withdrawal_status, pending_assessment_date) => {
		if (course_withdrawal_status == "PENDING") {
			return (
				<span className='p-2 ml-2 course-sticker pending-withdrawal'>
					{"Withdrawal Processing"}
				</span>
			);
		}
		if (course_withdrawal_status == "REJECTED") {
			return (
				<span className='p-2 ml-2 course-sticker rejected'>{"Withdrawal Rejected"}</span>
			);
		}
		if (tobe_rescheduled && course_reschedule_status !== "Submitted") {
			return (
				<span className='p-2 ml-2 course-sticker pending-reschedule'>
					{"Reschedule Required"}
				</span>
			);
		}
		if (course_reschedule_status == "Submitted" ) {
			return (
				<span className='p-2 ml-2 course-sticker pending-reschedule'>
					{"Reschedule Processing"}
				</span>
			);
		}
		if (course_reschedule_status == "Pending SFC Cancellation" || course_reschedule_status == "Pending SFC Resubmission" || course_reschedule_status == "Pending SFC Approval") {
			return (
				<span className='p-2 ml-2 course-sticker pending-reschedule'>
					{course_reschedule_status}
				</span>
			);
		}
		if (course_reschedule_status == "Rejected" ) {
			return (
				<span className='p-2 ml-2 course-sticker rejected'>{"Reschedule Rejected"}</span>
			);
		}
		if (course_reschedule_status == "Assessment" ) {
			if (pending_assessment_date) {
				return (
					<>
						<span className='p-2 ml-2 course-sticker pending-assessment'>{"Pending Assessment"}</span>
						<span className='pt-3 d-block course-date'>{pending_assessment_date}</span>
					</>
				);
			} else {
				return (
					<>
						<span className='p-2 ml-2 course-sticker pending-assessment'>{"Pending Assessment"}</span>
					</>
				);
			}
		}
	};

	return (
		<>
			<Card className="mt-2" style={{ boxShadow: "none" }}>
				<CardBody style={{ padding: 0 }}>
					<div className="upcoming_course_card_container">
						<div className="upcoming_course_card_data_container">
							<Row className='justify-content-between mx-0'>
								<Col md='auto' xl='auto' xs='auto' className="px-0">
									{course_logos.map((logo, i) => (
										<img
											key={i}
											className='mr-1 h-30px display_ini'
											alt={course_title}
											src={logo.course_logo_image}
										/>
									))}
								</Col>
								<Col md='auto' xl='auto' xs='auto' className='text-right pr-0'>
									{getStatusTag(
										course_reschedule_status,
										tobe_rescheduled,
										course_withdrawal_status,
										pending_assessment_date,
									)}
								</Col>
							</Row>
							<Row className='pt-2 align-items-center mx-0'>
								<Col className='col-12 px-0'>
									<Row className='align-items-center mx-0'>
										<Col xl='10' md='10' xs='7' className="px-0">
											<Row className="mx-0">
												{course_categories ? course_categories.map((category, i) => {
													return (
														<Col className='col-auto py-1 pr-1 pl-0' key={i}>
															<span
																style={{
																	backgroundColor: category.category_color,
																}}
																className='tag text-white'>
																{category.category_title}
															</span>
														</Col>
													);
											  	})
													:
													""}
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
							{course_duration &&
								<Row className='align-items-center pt-1 mx-0'>
									<Col md='12' xl='12' className="px-0">
										<Row className='align-items-center mx-0'>
											<TimeIcon width={16} height={16} />
											<span className='text-fca-dark days fs15 ml-2'>{course_duration} Days of Training</span>
										</Row>
									</Col>
								</Row>
							}
							<Row className='align-items-center pt-2'>
								<Col md='12' xl='12'>
									<p className="fs22 font-weight-bold text-fca-dark font_2">{course_title}</p>
								</Col>
							</Row>
							{course_short_description && 
								<Row className='align-items-center pt-2'>
									<Col md='12' xl='12'>
										<p className="text-fca-dark">{course_short_description}</p>
									</Col>
								</Row>
							}
							<Row className='align-items-center pt-3'>
								<Col md='auto' xl='auto' className="mb-2">
									<i className='fa fa-users text-fca-blue' aria-hidden='true'></i>{" "}
									<label className='m-0 pl-1 text-dark'>
										{registered_learners} Learners Completed
									</label>
								</Col>
								<Col md='auto' xl='auto' className='mb-2'>
									<i className='fas fa-certificate text-fca-blue' aria-hidden='true'></i>
									<label className='m-0 pl-1 text-dark'>WSQ Accreditation</label>
								</Col>
							</Row>
							<hr className="my-3" />
						</div>
						<div className="upcoming_course_card_banner_container">
							<img
								src={course_banner_image || defaultCourse}
								width='100%'
								className={"upcoming_course_banner_image"}
							/>
						</div>
					</div>
				</CardBody>
			</Card>
			<Card className="mt-2" style={{ boxShadow: "none" }}>
				<CardBody style={{ padding: 0 }}>
					<div className="courseinfo_details_container">
						<Row className='flex align-items-center mb-3'>
							<div>
								<CourseSvg className='ml-2' style={{ width: "60px" }} />
							</div>
							<div className={"fs22 ml-2 text-fca-dark font_2"}>Course Details</div>
						</Row>
						<Row className="mb-4">
							<Col>
								<div
									className='learner-detail-div'
									style={{
										borderTopColor: "rgb(0, 133, 202)",
									}}>
									<Row className='course_data course_detail mx-0'>
										<Col xl='3' md='4' xs='6' className='pr-2 pb-2 pl-0 py-4' style={{ borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#dddddd", }}>
											<Row className="align-items-center mx-0">
												<CourseIcon width={13} height={16} className="" />
												<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
													<span>Course ID</span>
												</label>
											</Row>
											<p className='m-0 text-fca-dark'>{course_ref ? course_ref : "-"}</p>
										</Col>
										<Col xl='3' md='4' xs='6' className='pr-2 pb-2 pl-0 py-4' style={{ borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#dddddd", }}>
											<Row className="align-items-center mx-0">
												<LocationIcon width={13} height={16} className="" />
												<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
													<span>Location</span>
												</label>
											</Row>
											<p className='m-0 text-fca-dark'>{venue_address ? venue_address : "-"}</p>
										</Col>
										<Col xl='3' md='4' xs='6' className='pr-2 pb-2 pl-0 py-4' style={ { borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#dddddd", }}>
								
											<Row className="align-items-center mx-0">
												<TimeIcon width={13} height={16} className="" />
												<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
													<span>Course Dates</span>
												</label>
											</Row>
											<p className='m-0 text-fca-dark'>
												{moment(courserun_course_from).format("DD MMM YYYY, ddd")} -{" "}
												{moment(courserun_course_to).format("DD MMM YYYY, ddd")}
											</p>
										</Col>
										<Col xl='3' md='4' xs='6' className='pr-2 pb-2 pl-0 py-4' style={{ borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#dddddd", }}>
								
											<Row className="align-items-center mx-0">
												<ClassIcon width={13} height={16} className="" />
												<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
													<span>Learning Mode</span>
												</label>
											</Row>
											<p className='m-0 text-fca-dark'>
												{courserun_modeoftraining ? courserun_modeoftraining : "-"}
											</p>
										</Col>
										<Col xl='3' md='4' xs='6' className='pr-2 pb-2 pl-0 py-4' style={width <= 1200 ? { borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#dddddd", } : null}>
											<Row className="align-items-center mx-0">
												<UserIcon width={14} height={16} className="" />
												<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
													<span>Student Services</span>
												</label>
											</Row>
											<p className='m-0 text-fca-dark'>{training_support_name ? training_support_name : "-"}</p>
										</Col>
										<Col xl='3' md='4' xs='6' className='pr-2 pb-2 pl-0 py-4' style={width <= 1200 ? { borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#dddddd", } : null}>
											<Row className="align-items-center mx-0">
												<PhoneIcon width={13} height={16} className="" />
												<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
													<span>Student Services Contact</span>
												</label>
											</Row>
											<p className='m-0 text-fca-dark'>{training_support_mobile ? training_support_mobile : "-"}</p>
										</Col>
										
										<Col xl='3' md='4' xs='6' className='pr-2 pb-2 pl-0 py-4'>
											<div className='flex h-100'>
												<Col md='auto' xl='auto' xs='auto' className='p-0'>
													<img
														className='trainer-img'
														alt={trainer_name}
														src={
															trainer_image_url
																? trainer_image_url
																: require("assets/img/learner/user.png")
														}
														onError={({ currentTarget }) => {
															currentTarget.onerror = null; // prevents looping
															currentTarget.src = require("assets/img/learner/user.png");
														}}
													/>
												</Col>
												<Col md='8' xl='8' xs='8' className='pr-0'>
													<label className='m-0 font-weight-600 text-fca-dark'>
														<span>Trainer</span>
													</label>
													<p className='m-0 text-fca-dark'>{trainer_name ? trainer_name : "-"}</p>
												</Col>
											</div>
										</Col>
										{claim_id ? (
											<Col xl='3' md='4' xs='5' className='pr-2 pb-2 pl-0 py-4'>
												<Row className="align-items-center mx-0">
													<AlmsIcon width={16} height={16} className="" />
													<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
														<span>SkillsFuture Credit Claims</span>
													</label>
												</Row>
												<p className='m-0'>
													{sfc_claim_id}
													{/* {JSON.stringify(course.sfc_claim_id)} */}
												</p>
												<p className='m-0 text-fca-dark'>
													Status: <span style={{ fontStyle: "italic" }}>{claim_status ? claim_status : "-"}</span>
												</p>
											</Col>
										) : null}
									</Row>
								</div>
							</Col>
						</Row>
						<Row className="d-flex " style={ width <= 767 ? { flexDirection: "column"} : { flexDirection: "row" }}>
							<Col xl='6' md='6' xs='12' className="mb-4" style={{
								flex: 1,
								display: "flex",
								alignItems: "stretch",
								flexDirection: "column",
							}}>
								<Row className='flex align-items-center mb-3'>
									<div>
										<CourseSessionIcon className='ml-2' style={{ width: "60px" }} />
									</div>
									<div className={"fs22 ml-2 text-fca-dark font_2"}>Sessions</div>
								</Row>
								<Row className="h-100">
									<Col>
										<div
											className='p-3 py-4 h-100 learner-detail-div col-content'
											style={{
												borderTopColor: "rgb(0, 133, 202)",
											}}>
											<div className='h-100'>
												<div className=''>
													<Row className="px-3">
														<Col xl='8' md='8' xs='8'  className="px-4 py-2" style={{ borderRightWidth: 1, borderRightStyle: "solid", borderRightColor: "white", backgroundColor: "rgb(0, 133, 202)" }}>
															<span className="text-white fs14 font-weight-bold">Date</span>
														</Col>
														<Col xl='4' md='4' xs='4' className="px-4 py-2" style={{ backgroundColor: "rgb(0, 133, 202)" }}>
															<span className="text-white fs14 font-weight-bold">Time</span>
														</Col>
													</Row>
													{csessions.map((session, i) => (
														<Row key={i} className="px-3">
															<Col xl='8' md='8' xs='8' className="px-4 py-3" style={{ borderWidth: 1, borderStyle: "solid", borderColor: "#dddddd", backgroundColor: "rgb(248, 248, 248)" }}>
																<span className="text-fca-dark fs14">{moment(session.csession_start_date).format("DD MMM YYYY, ddd")} -{" "}
																	{moment(session.csession_end_date).format("DD MMM YYYY, ddd")}</span>
															</Col>
															<Col xl='4' md='4' xs='4' className="py-3" style={{ textAlign: "center", borderWidth: 1, borderStyle: "solid", borderColor: "#dddddd", backgroundColor: "rgb(248, 248, 248)" }}>
																<span className="text-fca-dark fs14">{session.csession_start_time + " - " + session.csession_end_time}</span>
															</Col>
														</Row>
													))}
												</div>
											</div>
										</div>
									</Col>
								</Row>
							</Col>
							<Col xl='6' md='6' xs='12' className="mb-4" style={{
								flex: 1,
								display: "flex",
								alignItems: "stretch",
								flexDirection: "column",
							}}>
								<Row className='flex align-items-center mb-3'>
									<div>
										<CourseInfoIcon className='ml-2' style={{ width: "60px" }} />
									</div>
									<div className={"fs22 ml-2 text-fca-dark font_2"}>Course Information</div>
								</Row>
								<Row className="h-100">
									<Col>
										<div
											className='p-3 py-4 h-100 learner-detail-div col-content'
											style={{
												borderTopColor: "rgb(0, 133, 202)",
											}}>
											<span className="text-fca-dark fs14">{course_description}</span>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
					{/* <div className={`flex ${course.has_registered ? "justify-content-center" : "justify-content-end pr-4"} `}>
						{course.has_registered ? (
							<div className='col-auto pt-2 text-primary'>
								<p>{"You already registered this course."}</p>
							</div>
						) : (
							<Button color='primary' type='button'>
                  					{"Register Now"}
							</Button>
						)}
					</div> */}
				
					{showButtons && message && (
						<div className='form-group'>
							<div className='alert alert-danger' role='alert'>
								{message}
							</div>
						</div>
					)}
					{showButtons &&
						<div className="courseinfo_details_container pt-0">
							<Row className='mx-0'>
								<Col xs="12" sm="12" md="2" className="px-0">
									<div className='btn-wrap mb-3'>
										<Link to='/course' className='btn btn-default'>
											{"Back"}
										</Link>
									</div>
								</Col>
								<Col xs="12" sm="12" md="10" className="d-sm-flex justify-content-end px-0" style={width<=767 ? {order: -1} : {}}>
									<Row className='mx-0' style={width<=767 ? {width: "100%"} : {flexDirection:"row-reverse"}}>
										{VERSION != "0.0.0" && (
											<>
												{/* {status == "upcoming" && course.reschedule_status != "Submitted" && course.withdrawal_status != "PENDING" && (
												<>
													<Link
														// to='/course/shuttle-bus'
														// state={{ learner_id: course.learner_id }}
														className='btn btn-outline-green'
													>
														<MealIcon />
														<span>Meal Selection</span>
														<i className='fa fa-angle-right' aria-hidden='true'></i>
													</Link>
												</>
											)} */}
												{status == "completed" && (
													<>
														{/* <Link
														// to='/course/shuttle-bus'
														// state={{ learner_id: course.learner_id }}
														className='btn btn-outline-green'
													>
														<TimeIcon />
														<span>Attendance</span>
														<i className='fa fa-angle-right' aria-hidden='true'></i>
													</Link> */}
														<div className='btn-wrap mb-3' style={width<=767 ? {width: "100%"} : {paddingLeft: "10px"}}>
															<Link
																to='/course/download-resources'
																state={{ course: course }}
																className='btn btn-outline-green'>
																<DownloadIcon />
																<span>Download Resources</span>
																<i className='fa fa-angle-right' aria-hidden='true'></i>
															</Link>
														</div>
													</>
												)}
											</>
										)}
										{status == "upcoming" && course.reschedule_status != "Submitted" && course.withdrawal_status != "PENDING" && (
											<>
												{/* {course.has_bus && (
												<Link
													to='/course/shuttle-bus'
													state={{
														learner_id: course.learner_id,
														course: course,
														status: status,
													}}
													className='btn btn-outline-green'>
													<BusIcon />
													<span>Shuttle Bus</span>
													<i className='fa fa-angle-right' aria-hidden='true'></i>
												</Link>
											)} */}
												{!course.cannot_select_food_reason ? (
													<div className='btn-wrap mb-3' style={width<=767 ? {width: "100%"} : {paddingLeft: "10px"}}>
														<Link
															to='/course/meal-selection'
															state={{
																learner_id: course.learner_id,
																course: course,
																status: status,
															}}
															className='btn btn-outline-green'>
															<MealIcon />
															<span>Meal Selection</span>
															<i className='fa fa-angle-right' aria-hidden='true'></i>
														</Link>
													</div>
												
												) : (
													<div className='btn-wrap mb-3' style={width<=767 ? {width: "100%"} : {paddingLeft: "10px"}}>
														<Button
															className='btn-outline-green opacity-6'
															onClick={(e) => handlePopup(course.cannot_select_food_reason)}>
															<MealIcon />
															<span>Meal Selection</span>
														</Button>
													</div>
												)}
											</>
										)}
										{status == "completed" && course.cert_file_url && (
											<>
												<div className='btn-wrap mb-3' style={width<=767 ? {width: "100%"} : {paddingLeft: "10px"}}>
													<ModalCertificates
														course_title={course.course_title}
														cert_file_url={course.cert_file_url}
														showModal={certModalChange}
														setShowModal={setCertModalChange}
													/>
													<Button
														className='btn btn-outline-green cert-icon'
														onClick={() => setCertModalChange(true)}>
														<CertificateIcon />
														<span>View Certificate</span>
														<i className='fa fa-angle-right' aria-hidden='true'></i>
													</Button>
												</div>
											
												{/* <Link
												to='/course/certificates'
												state={{
													course_title: course.course_title,
													cert_file_url: course.cert_file_url,
													course: course,
												}}
												className='btn btn-outline-green cert-icon'
											>
												<CertificateIcon />
												<span>View Certificate</span>
												<i className='fa fa-angle-right' aria-hidden='true'></i>
											</Link> */}
											</>
										)}
										{(status == "upcoming" || status == "to_be_reschedule") && !course.cannot_withdraw_reason ? (
											<div className='btn-wrap mb-3' style={width<=767 ? {width: "100%"} : {paddingLeft: "10px"}}>
												<Link
													to='/course/withdrawal_description'
													state={{
														data: {
															learner_id: course.learner_id,
															courserun_id: course.courserun_id,
															course_title: course.course.course_title,
															course: course,
															status: status,
														},
													}}
													className='btn btn-link-green h-100'>
													<i className='fa fa-undo' aria-hidden='true'></i>
													<span>Withdrawal Requests</span>
												</Link>
											</div>
										) : (
											<div className='btn-wrap mb-3' style={width<=767 ? {width: "100%"} : {paddingLeft: "10px"}}>
												<Button
													className='btn-link-green h-100 opacity-6 shadow-none'
													onClick={() => handlePopup(course.cannot_withdraw_reason)}>
													<i className='fa fa-undo' aria-hidden='true'></i>
													<span>Withdrawal Request</span>
												</Button>
											</div>
										)}
										{(status == "upcoming" || status == "to_be_reschedule") && !course.cannot_reschedule_reason ? (
											<div className='btn-wrap mb-3' style={width<=767 ? {width: "100%"} : {paddingLeft: "10px"}}>
												<Link
													to='/course/reschedule_description'
													state={{
														data: {
															learner_id: course.learner_id,
															learner_sfc: course.learner_sfc,
															courserun_id: course.courserun_id,
															course_title: course.course.course_title,
															course: course,
															status: status,
														},
													}}
													className='btn btn-link-green h-100'>
													<i className='fa fa-history' aria-hidden='true'></i>
													<span>Reschedule Request</span>
												</Link>
											</div>
										
										): course.reschedule_status == "Pending SFC Resubmission" ? (
											<div className='btn-wrap mb-3' style={width<=767 ? {width: "100%"} : {paddingLeft: "10px"}}>
												<Button
													type='button'
													onClick={(e) => handleSFCClick(e)}
													className='btn btn-outline-green h-100'>
													<i className='fa fa-history' aria-hidden='true'></i>
													<span>Resubmit SFC</span>
													<i className='fa fa-angle-right' aria-hidden='true'></i>
												</Button>
											</div>
										
										) : (
											<div className='btn-wrap mb-3' style={width<=767 ? {width: "100%"} : {paddingLeft: "10px"}}>
												<Button
													type='link-green'
													className='btn-link-green h-100 opacity-6 shadow-none'
													onClick={() => handlePopup(course.cannot_reschedule_reason)}>
													<i className='fa fa-history' aria-hidden='true'></i>
													<span>Reschedule Request</span>
												</Button>
											</div>
										)}
									</Row>
								</Col>
							</Row>
						</div>
					}
					
				
				</CardBody>
			</Card>
		</>
	);
}

export default CourseInfo;
