/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback } from "react";

import { Link, useNavigate } from "react-router-dom";

import Moment from "moment";

import { useDispatch, useSelector } from "react-redux";

import { logout, getUser, getProfileBundle } from "../../../../actions/auth";
// reactstrap components
import { Card, CardBody, Container, Row, Col, InputGroupAddon, InputGroupText } from "reactstrap";

import { ReactComponent as SettingsIcon } from "assets/img/Profile/setting.svg";
import { ReactComponent as UpdateIcon } from "assets/img/Profile/update.svg";
import { ReactComponent as UpdateRedIcon } from "assets/img/Profile/update-red.svg";
import { ReactComponent as LogOutIcon } from "assets/img/Profile/logout.svg";
import { ReactComponent as UserIcon } from "assets/img/Profile/user.svg";
import { ReactComponent as QRIcon } from "assets/img/Events/qr.svg";
import { ReactComponent as Pro1Icon } from "assets/img/Profile/icon_pro01.svg";
import { ReactComponent as Pro2Icon } from "assets/img/Profile/icon_pro02.svg";
import { ReactComponent as Pro3Icon } from "assets/img/Profile/icon_pro03.svg";
import { ReactComponent as EditIcon } from "assets/img/Profile/icon_edit.svg";
import { getErrorMessage } from "utilities/error";
import { toast } from "react-toastify";
import { removeLogGTMUser } from "utilities/googleTagManager";
import AlertDialog from "components/AlertDialog";
import { clearCheckout, clearCheckoutAnalyticsData } from "reducers/courseRegisterSlice";
import ModalProfileQR from "views/pages/learner/profile/components/ModalProfileQR";
import { ReactComponent as CertIcon } from "assets/img/Profile/Bundle/cert.svg";
import styles from "./Profile.module.scss";
import BundleBadge from "views/pages/learner/profile/components/BundleBadge";
import avatarApi from "services/avatar-api";
import profileBackground from "assets/img/Profile/bg_dots_Profile_bar.png";
import { useWindow } from "hooks/UseWindow";

function Profile() {
	const {
		user: userData,
		isHyperAdminLogin,
		profileBundle,
		is_completed_one_course,
		almost_completed_bundle_course_left,
		almost_completed_bundle_title,
		total_badge,
		total_completed_course,
		total_upcoming_course,
	} = useSelector((state) => state.auth);
	const [isDialogOpen, setIsDialogOpen] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [isQRModalOpen, setIsQRModalOpen] = React.useState(false);
	const [getBundleLoading, setGetBundleLoading] = React.useState(false);
	const [getLearnerAvatarLoading, setGetLearnerAvatarLoading] = React.useState(false);
  	const [isAvatar, setIsAvatar] = React.useState(false);
  	const [currentAvatarOccupationImage, setCurrentAvatarOccupationImage] = React.useState(null);
	const [currentAvatarBackgroundImage, setCurrentAvatarBackgroundImage] = React.useState(null);
	const { width } = useWindow();

	const currentUser = userData && userData.user;
	const dispatch = useDispatch();
	let navigate = useNavigate();
	
	const logOut = useCallback(() => {
		removeLogGTMUser();
		dispatch(logout(isHyperAdminLogin, userData));
		dispatch(clearCheckout());
		dispatch(clearCheckoutAnalyticsData());
		navigate("/login");
	}, [dispatch]);

	React.useEffect(() => {
		fetchUser();
		fetchLearnerAvatars();
		fetchProfileBundle();
	}, []);

	const fetchLearnerAvatars =  async () => {
		setGetLearnerAvatarLoading(true);
		avatarApi.getLearnerAvatars().then(
			(response) => {
				if (response.data) {
					setIsAvatar(response.data.isAvatar);
					setCurrentAvatarOccupationImage(response.data.avatar_occupation_full_image ?? null);
					setCurrentAvatarBackgroundImage(response.data.avatar_background_full_image ?? null);
				}
				setGetLearnerAvatarLoading(false);
			},
			(error) => {
				toast.error(getErrorMessage(error));
				setGetLearnerAvatarLoading(false);
			},
		);
	};

	const fetchUser = async () => {
		try {
			let response = await dispatch(getUser());
		} catch (e) {
			let error_message = getErrorMessage(e);

			toast.error(error_message);
		}
	};

	const fetchProfileBundle = async () => {
		setGetBundleLoading(true);
		try {
			let response = await dispatch(getProfileBundle());
			setGetBundleLoading(false);
		} catch (e) {
			let error_message = getErrorMessage(e);
			toast.error(error_message);
			setGetBundleLoading(false);
		}
	};

	const toggleOpen = () => {
		setOpen(!open);
	};
	
	return (
		<div className="fadeInDiv my_course_dashboard_container">
			<span className={`${styles.profile_title}`}>Profile</span>
			{getLearnerAvatarLoading &&
				<div className='justify-content-center mt-4 w-100 flex'>
					<span className='spinner-border spinner-border-sm'></span>
				</div>
			}
			{!getLearnerAvatarLoading &&
				<div className='w-100'>
					<Card className={`${styles.profile_header_card}`}>
						<CardBody className={`${styles.profile_header_cardbody}`}>
							<img
								className={`${styles.profile_background_image}`}
								alt={"profile_background"}
								src={profileBackground}
							/>
							{width < 576 &&
								<div className='mr-0 pl-0 mb-3 d-flex justify-content-center'>
									<Link to='/avatar'>
										{isAvatar && 
											<div className={`${styles.profile_avatar_container}`}>
												<img
													className={`${styles.profile_avatar_background_image}`}
													alt={"profile_background"}
													src={
														currentAvatarBackgroundImage
															? currentAvatarBackgroundImage
															: null
													}
												/>
												<img
													className={`${styles.profile_avatar_occupation_image}`}
													alt={"profile_occupation"}
													src={
														currentAvatarOccupationImage
															? currentAvatarOccupationImage
															: null
													}
												/>
											</div>
										}
										{!isAvatar &&
											<div style={{ position: "relative" }}>
												<UserIcon height={150} width={150} />
												<EditIcon
													height={30}
													width={30}
													style={{
														position: "absolute",
														bottom: 2,
														right: 2,
													}}
												/>
											</div>
										}
									</Link>
									
								</div>
							}
							<div className='d-flex flex-row align-items-center' style={{ zIndex: 10, position: "relative" }}>
								<div>
									<div className='d-flex flex-row px-0 mx-0 align-items-center'>
										{width >= 576 &&
											<Link to='/avatar'>
												<div className='mr-4 pl-0'>
													{isAvatar && 
														<div className={`${styles.profile_avatar_container}`}>
															<img
																className={`${styles.profile_avatar_background_image}`}
																alt={"profile_background"}
																src={
																	currentAvatarBackgroundImage
																		? currentAvatarBackgroundImage
																		: null
																}
															/>
															<img
																className={`${styles.profile_avatar_occupation_image}`}
																alt={"profile_occupation"}
																src={
																	currentAvatarOccupationImage
																		? currentAvatarOccupationImage
																		: null
																}
															/>
														</div>
													}
													{!isAvatar &&
														<div style={{ position: "relative" }}>
															<UserIcon height={150} width={150} />
															<EditIcon
																height={30}
																width={30}
																style={{
																	position: "absolute",
																	bottom: 2,
																	right: 2,
																}}
															/>
														</div>
													}
												</div>
											</Link>
										}
										<div className='p-0'>
											<span className={`${styles.profile_name_text} m-0 text-capitalize`}>
												{currentUser && currentUser.learnermaster_name.toLowerCase()}
											</span>
											<p className={`${styles.profile_email_text} m-0 text-lowercase mb-3`}>
												{currentUser && currentUser.learnermaster_email}
											</p>
											{almost_completed_bundle_course_left != 0 &&
												<p className={`${styles.profile_description_text} m-0`}>
													{`You are ${almost_completed_bundle_course_left} courses away from being a ${almost_completed_bundle_title} Professional`}
												</p>
											}
										</div>
										
									</div>
								</div>
								{width >= 1000 &&
									<div className={`${styles.profile_icon_main_container}`}>
										<div className={`${styles.profile_icon_container}`}>
											<Pro1Icon height={60} width={60} />
											<span className={`${styles.profile_icon_title}`}><span className={`${styles.profile_icon_title_blue}`}>{`${total_completed_course}`}</span>{"Certificates"}</span>
										</div>
										<div className={`${styles.profile_icon_container}`}>
											<Pro2Icon height={60} width={60} />
											<span className={`${styles.profile_icon_title}`}><span className={`${styles.profile_icon_title_blue}`}>{`${total_badge}`}</span>{"Badges"}</span>
										</div>
										<div className={`${styles.profile_icon_container}`}>
											<Pro3Icon height={60} width={60} />
											<span className={`${styles.profile_icon_title}`}><span className={`${styles.profile_icon_title_blue}`}>{`${total_upcoming_course}`}</span>{"Upcoming Courses"}</span>
										</div>
									</div>		
								}
								
								<div className={`${styles.profile_qr_container}`}>
									<div onClick={() => setIsQRModalOpen(true)} className="d-flex flex-column align-items-center cursor-pointer">
										<QRIcon height={60} width={60} />
										<p className='m-0 fs13 mt-2 text-center'>
											{"Boarding Pass"}
										</p>
									</div>
								</div>
							</div>
							{width < 1000 &&
								<div className={`${styles.profile_icon_main_container} mt-3 ml-0`}>
									<div className={`${styles.profile_icon_container}`}>
										<Pro1Icon height={60} width={60} />
										<span className={`${styles.profile_icon_title}`}><span className={`${styles.profile_icon_title_blue}`}>{`${total_completed_course}`}</span>{"Certificates"}</span>
									</div>
									<div className={`${styles.profile_icon_container}`}>
										<Pro2Icon height={60} width={60} />
										<span className={`${styles.profile_icon_title}`}><span className={`${styles.profile_icon_title_blue}`}>{`${total_badge}`}</span>{"Badges"}</span>
									</div>
									<div className={`${styles.profile_icon_container}`}>
										<Pro3Icon height={60} width={60} />
										<span className={`${styles.profile_icon_title}`}><span className={`${styles.profile_icon_title_blue}`}>{`${total_upcoming_course}`}</span>{"Upcoming \nCourses"}</span>
									</div>
								</div>		
							}
						</CardBody>
					</Card>

					<Row className='cta-wrap'>
						<Col className='col-3 mb-3'>
							<Link to='/profile/edit' className='text-center text-body'>
								<Card className='p-3 pb-4 pt-4 d-flex flex-column justify-content-center align-items-center'>
									<div className="mb-2">
										<UpdateIcon  />
									</div>
									<div className='text-container justify-content-center'>
										<span>Update Profile</span>
									</div>
								</Card>
							</Link>
						</Col>
						<Col className='col-3 mb-3'>
							<Link to='/profile/change-password' className='text-center text-body'>
								<Card className='p-3 pb-4 pt-4 d-flex flex-column justify-content-center align-items-center'>
									<div className="mb-2">
										<SettingsIcon />
									</div>
									<div className='text-container justify-content-center'>
										<span>Change Password</span>
									</div>
								</Card>
							</Link>
						</Col>
						<Col className='col-3 mb-3'>
							<Link to='/profile/change-email' className='text-center text-body'>
								<Card className='p-3 pb-4 pt-4 d-flex flex-column justify-content-center align-items-center'>
									<div className="mb-2">
										<SettingsIcon />
									</div>
									<div className='text-container justify-content-center'>
										<span>Change Email</span>
									</div>
								</Card>
							</Link>
						</Col>
						<Col className='col-3 mb-3'>
							<Link to='/profile/delete-account' className='text-center text-body'>
								<Card className='p-3 pb-4 pt-4 d-flex flex-column justify-content-center align-items-center'>
									<div className='mb-2'>
										<UpdateRedIcon />
									</div>
									<div className='text-container justify-content-center'>
										<strong style={{ color: "rgb(216,70,70)" }}>Delete Account</strong>
									</div>
								</Card>
							</Link>
						</Col>
						<Col className='col-3 mb-3'>
							<div
								className='text-center logout-color cursor-pointer'
								onClick={() => setIsDialogOpen(true)}>
								<Card className='p-3 pb-4 pt-4 d-flex flex-column justify-content-center align-items-center'>
									<div className="mb-2">
										<LogOutIcon />
									</div>
									<div className='text-container justify-content-center'>
										<span style={{ color: "#9CA3AC" }}>Log Out</span>
									</div>
								</Card>
							</div>
						</Col>
					</Row>
					
					{is_completed_one_course && 
						<>
							<h2 style={{ marginTop: "10px" }}>Achievements - Certificates</h2>
							<Card className='mb-3'>
								<CardBody className='p-4'>
									<p className={`${styles.profile_bundle_desc_text} mb-4`}>
										{"Complete courses and earn yourself a professional certificate."}
									</p>
									{profileBundle && profileBundle.map(bundle => {
										if (bundle.total_completed > 0) {
											return (<>
												<p className={`${styles.profile_bundle_certificate_bundle_title}`} style={{ color: bundle.bundle_color ? bundle.bundle_color: "#101820" }}>
													{bundle.bundle_name}
												</p>
												<div className={`${styles.profile_bundle_certificate_container}`}>
													{bundle.courses &&  bundle.courses.map(course => {
														if (course.is_completed) {
															return (
																<div className={`${styles.profile_bundle_certificate_image_container}`}>
																	<CertIcon height={80} width={80} />
																	<p className={`${styles.profile_bundle_certificate_course_title}`}>
																		{course.course_title}
																	</p>
																</div>
															);
														} else {
															return null;
														}
													})}
												</div>
											</>);
										}
									})}
								</CardBody>
							</Card>
						</>
					}
					
					<h2 style={{ marginTop: "10px" }}>Achievements - Badges</h2>
					<Card className='mb-3'>
						<CardBody className='p-4'>
							<p className={`${styles.profile_bundle_desc_text} mb-4`}>
								{"Complete courses in the same program to earn badges"}
							</p>
							{getBundleLoading &&
								<div className='justify-content-center mt-4 w-100 flex'>
									<span className='spinner-border spinner-border-sm'></span>
								</div>
							}
							{!getBundleLoading &&
								<div className={`${styles.profile_bundle_certificate_container}`}>
									{profileBundle && profileBundle.map(bundle => {
										return (<>
											<div className={`${styles.profile_bundle_certificate_image_container}`}>
												<BundleBadge
													totalSteps={bundle.total_courses || 0}
													currentSteps={bundle.total_completed || 0}
													color={bundle.bundle_color || "#0085ca"}
													badge_active_image={bundle.bundle_badge_active_image}
													badge_inactive_image={bundle.bundle_badge_inactive_image}
												/>
												<p className={`${styles.profile_bundle_certificate_course_title}`}>
													{bundle.bundle_name}
												</p>
											</div>
										</>);
									})}
								</div>
							}
						</CardBody>
					</Card>

					<AlertDialog
						isOpen={isDialogOpen}
						title={"Logout"}
						description={"Are you sure to logout!"}
						onCancel={() => setIsDialogOpen(false)}
						onAgree={() => logOut()}
					/>
				</div>
			}
			
			<ModalProfileQR currentUser={currentUser} isOpen={isQRModalOpen} onClose={() => setIsQRModalOpen(false)} />
		</div>
	);
}

export default Profile;
